import React from "react";

import "./ReviewTheWork.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ChatContext } from "../../Context/ChatContext";

import { MainPage } from "../templates/MainPage";
import { DesktopTable, MobileTable } from "./Tables";

import { isMobile } from "../../hooks/utils";
import {
	createProject,
	getProjectById,
	getSurveys,
	saveReview,
} from "../../hooks/projects";
import {
	setActiveProjectId,
	setActiveStep,
	setProject,
	setProjects,
	updateProject,
} from "../../features/project/projectSlice";
import AddIcon from "../../Sources/Icons/AddIcon.svg";

import ReusableModal from "../reusableComponents/modal";
import ModalPlatforms from "./createNewProject";
import { BackdropContext } from "../../Context/BackdropContext";
import { updateClientDb } from "../../hooks/auth";
import { setUser } from "../../features/auth/authSlice";
import { Avatar } from "@mui/material";
// import { persistor } from "../../Store";
// import { purgeRequests } from "../../features/requests/requestsSlice";

function ReviewTheWork() {
	const [surveyQuestions, setSurveyQuestions] = React.useState([]);
	const [sended, setSended] = React.useState(false);
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);

	const navigate = useNavigate();

	const { project, projects } = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);

	const { dispatchChat } = React.useContext(ChatContext);

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	const { user, token } = useSelector((state) => state.auth);

	React.useEffect(() => {
		changeLoadingBackdrop(true);

		dispatchChat({ type: "HIDE_CHAT" });
		getSurveyQuestionsFromFirebase();
		getProject();

		changeLoadingBackdrop(false);
	}, []);

	async function getSurveyQuestionsFromFirebase() {
		const questions = await getSurveys(auth.token);
		setSurveyQuestions(questions);
	}

	async function getProject() {
		try {
			const projectRev = await getProjectById(project.uid, auth.token);
			if (projectRev.review.length > 0) {
				setSended(true);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const questions = [];

		surveyQuestions.forEach((question, index) => {
			questions.push({
				question: question,
				answer: e.target["que" + index].value,
				index: index,
			});
		});

		try {
			await saveReview(project.uid, questions, auth.token);
		} catch (error) {
			console.error(error);
		}
		setSended(true);
	}

	async function CreateNewProject() {
		const newProject = {
			clientUid: user.uid,
			clientName: user.displayName,
			activeStep: 0,
			projectName: "My new project",
			email: user.email,
			requireNda: false,
			completedSteps: [
				false, // <- this step is exclusive to google clients, so we set it to true
				true,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			],
			created: true,
			lastCompletedStep: 0,
		};
		const createdProject = await createProject(newProject, token);
		const updatedUser = {
			...user,
			activeProject: createdProject.uid,
		};
		await updateClientDb(updatedUser, token);
		dispatch(setUser(updatedUser));
		// then set project in redux store
		dispatch(setProject(createdProject));
		dispatch(setActiveProjectId(createdProject.uid));
		dispatch(setProjects([...projects, createdProject]));
		/*
		persistor.pause();
		persistor.purge();
		dispatch(purgeRequests());
		persistor.persist();
		*/
		changeLoadingBackdrop(false);
		navigate("/newProject");
	}

	return (
		<MainPage
			header={true}
			title="Review the work"
			width="55vw"
			backFunction={9}
		>
			<Stack
				component="form"
				onSubmit={handleSubmit}
				className="review-container"
			>
				{!sended && (
					<div className="box-survey">
						<Typography
							variant="taskDescription"
							sx={{ color: "#285D69", fontSize: "20px" }}
						>
							*Please indicate your level of agreement with the following
							statements regarding your customer service representative:
						</Typography>
						{/* <p className="subtitle-survey">*Please indicate your level of agreement with the following statements regarding your customer service representative:</p> */}
						<EvaluationTable surveyQuestions={surveyQuestions} />
					</div>
				)}

				<Stack
					gap={3}
					sx={{
						alignItems: "center",
					}}
				>
					{sended ? (
						<Stack spacing={2}>
							<p className="thank-you-text">Thank you!</p>
							<Stack
								sx={{
									background: "rgba(255, 255, 255, 0.8)",
									boxShadow: "8px 8px 20px rgb(192 192 192 / 25%)",
									backdropFilter: "blur(20px)",
									borderRadius: "15px",
									padding: 6,
									margin: "0px 0px 46px 0px",
									alignItems: "center",
								}}
								spacing={2}
							>
								{/* 								<Button
									sx={{
										fontWeight: "400",
										width: "fit-content",
									}}
									variant="primaryButton"
									onClick={() => {
										dispatch(setActiveStep(3));
										dispatch(updateProject());
									}}
								>
									Add new tasks to your project
								</Button> */}
								<Button
									disableElevation
									id="add-task"
									variant="taskButton"
									onClick={() => {
										dispatch(setActiveStep(3));
										dispatch(updateProject());
									}}
									startIcon={<Avatar variant="iconTaskSmaller" src={AddIcon} />}
								>
									Add new tasks to your project
								</Button>
								<Button
									sx={{
										width: "fit-content",
									}}
									variant="primaryButton"
									//TODO[] create new project directly from here, not navigate to /newProject
									onClick={() => CreateNewProject()}
								>
									Start new project
								</Button>
							</Stack>
						</Stack>
					) : (
						<Button
							sx={{
								width: "60%",
								marginBottom: "100px",
							}}
							variant="primaryButton"
							type="submit"
						>
							Submit
						</Button>
					)}
				</Stack>
			</Stack>
			<ReusableModal
				dialogProps={{
					open: open,
					onClose: () => setOpen(false),
					maxWidth: "md",
					fullScreen: true,
					scroll: "paper",
					sx: {
						"& .MuiDialog-paper": {
							backgroundColor: "#ffffffcc",
							backdropFilter: "blur(10px)",
						},
					},
				}}
			>
				<ModalPlatforms handleOpen={setOpen} />
			</ReusableModal>
		</MainPage>
	);
}
export { ReviewTheWork };

function EvaluationTable({ surveyQuestions }) {
	//todo refactor this to use useMediaQuery <- done!
	const check = isMobile();

	return (
		<>
			{check ? (
				<MobileTable surveyQuestions={surveyQuestions} />
			) : (
				<DesktopTable surveyQuestions={surveyQuestions} />
			)}
		</>
	);
}
