import React from "react";

import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import "./Quote.css";
import { addQuote, delQuote } from "../../features/project/projectSlice";
import TaskDescriptionIcon from "../../Sources/Icons/TaskDescriptionIcon.svg";

import checkBoxIcon from "../../Sources/Icons/CheckboxEmpty.svg";
import checkIcon from "../../Sources/Icons/CheckBoxCheck.svg";
import quoteBottom from "../../Sources/images/quoteBottom.svg";
import { isMobile } from "../../hooks/utils";
import { ReusableAccordion } from "../templates/Accordions";

export default function QuoteItem({ ticket, index }) {
	const theme = useTheme();
	const dispatch = useDispatch();

	const [check, setCheck] = React.useState(ticket.selectedToPay);

	React.useEffect(() => {
		if (ticket.selectedToPay) {
			dispatch(addQuote({ ticket, seeHowWeDidIt: false }));
		}
	}, []);

	const checked = isMobile();

	function handleChange(e) {
		if (e.target.checked) {
			// ADD QUOTE TO THE SELECTED QUOTES
			setCheck(true);
			dispatch(addQuote({ ticket, seeHowWeDidIt: false }));
		} else {
			setCheck(false);
			dispatch(delQuote({ ticket }));
		}
	}

	function QuoteMobile() {
		const theme = useTheme();

		function QuoteItemMobile() {
			const tratedPrice = (ticket.price / 100).toLocaleString("en-us", {
				style: "currency",
				currency: "USD",
			});

			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: theme.spacing(0, 4, 5, 4),
						width: "100%",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "bottom",
						backgroundColor: "#ffffffcc",
						backdropFilter: "blur(20px)",
						position: "relative",
						minHeight: "fit-content",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography sx={{ textAlign: "start" }} variant="taskDescription">
							{ticket["description"]}
						</Typography>
						<Box marginBottom={1} marginTop={1}>
							{ticket?.platforms?.map((item) => {
								return (
									<img
										key={item.id}
										style={{
											height: "24px",
											filter: "grayscale(100%)",
											opacity: "50%",
											marginRight: "10px",
										}}
										src={item.icons.inactive}
										className="image-platform"
										alt="platform"
									/>
								);
							})}
						</Box>
					</Box>
					<Box
						sx={{
							position: "relative",
							minheight: "150px",
							maxHeight: "150px",
							background: theme.palette.electricBlue.secondary,
							borderRadius: "15px",
							padding: "15px",
							justifyContent: "space-between",
							height: "fit-content",
						}}
					>
						<Stack>
							<Typography variant="quoteCartTitle">
								Estimated time:&nbsp;
								<Typography variant="quoteCartContent">
									{ticket["estimatedTime"]}&nbsp;hour(s)
								</Typography>
							</Typography>
							<Typography variant="quoteCartTitle">
								Price:&nbsp;
								<Typography variant="quoteCartContent">
									{tratedPrice}
								</Typography>
							</Typography>
						</Stack>
						{ticket.payment && ticket.payment.status === "completed" ? (
							<Stack sx={{ alignSelf: "flex-end" }}>
								<Typography variant="quoteCartPrice">Paid</Typography>
							</Stack>
						) : (
							<Stack sx={{ alignSelf: "flex-end" }}>
								<FormGroup>
									<FormControlLabel
										sx={{ width: "100%", margin: "0px" }}
										variant="quoteCheckboxLabel"
										control={
											<Checkbox
												checked={check}
												onChange={handleChange}
												icon={<Avatar variant="iconCheck" src={checkBoxIcon} />}
												checkedIcon={
													<Avatar variant="iconCheck" src={checkIcon} />
												}
											/>
										}
										label="Add to cart"
										labelPlacement="start"
									/>
								</FormGroup>
							</Stack>
						)}
					</Box>

					<img
						draggable={false}
						src={quoteBottom}
						className="img-quote-bottom"
						alt="circles"
					/>
				</Box>
			);
		}

		function QuoteHeaderColapsed() {
			return (
				<Stack
					spacing={2}
					direction={"row"}
					sx={{
						paddingY: 2,
					}}
				>
					<Avatar
						sx={{ width: "2.1rem", height: "2.1rem" }}
						variant="iconTask"
						src={TaskDescriptionIcon}
					/>
					<Typography variant="calendarMonth">Task &#35;{index + 1}</Typography>
				</Stack>
			);
		}

		function QuoteHeaderExpanded() {
			return (
				<Stack
					spacing={2}
					direction={"row"}
					sx={{ paddingY: 2, borderRadius: "15px" }}
				>
					<Avatar
						sx={{ width: "2.1rem", height: "2.1rem" }}
						variant="iconTask"
						src={TaskDescriptionIcon}
					/>
					<Stack justifyContent="flex-start" height="100%" marginBottom="auto">
						<Typography variant="calendarMonth">
							Task &#35;{index + 1}&nbsp;{ticket.title}
						</Typography>
						{/* 
						<Typography variant="taskDescription" fontWeight="400">
							{ticket.title}
						</Typography> 
						*/}
					</Stack>
				</Stack>
			);
		}

		return (
			<ReusableAccordion
				accordionProps={{
					defaultExpanded: true,
				}}
				hideIcon={true}
				expandedheader={QuoteHeaderExpanded()}
				colapsedHeader={QuoteHeaderColapsed()}
				details={QuoteItemMobile()}
			/>
		);
	}
	return (
		<>
			{checked ? (
				<QuoteMobile />
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "30px 30px 45px 30px",
						width: "100%",
						[theme.breakpoints.up("lg")]: {
							width: "100%",
						},
						[theme.breakpoints.up("xl")]: {
							width: "100%",
						},
						backgroundRepeat: "no-repeat",
						backgroundPosition: "bottom",
						backgroundColor: "#ffffffcc",
						backdropFilter: "blur(20px)",
						boxShadow: "8px 8px 20px #c0c0c040",
						position: "relative",
						borderRadius: "15px 15px 0px 0px",
						minHeight: "fit-content",
						marginBottom: "40px",
					}}
				>
					<Stack direction={"row"}>
						<Avatar
							sx={{ width: "2.1rem", height: "2.1rem" }}
							variant="iconTask"
							src={TaskDescriptionIcon}
						/>
						<Stack
							direction="row"
							sx={{ width: "100%", justifyContent: "space-between" }}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									paddingLeft: 2,
									width: "75%",
								}}
							>
								<Stack direction={"row"} sx={{ paddingBottom: 2 }}>
									<Typography variant="calendarMonth">
										Task &#35;{index + 1}&nbsp;{ticket.title}
									</Typography>
								</Stack>
								<Typography
									sx={{ paddingBottom: 2, textAlign: "start" }}
									variant="taskDescription"
								>
									{ticket.description}
								</Typography>
								<Typography variant="taskDescription">
									<img
										style={{
											height: "24px",
											opacity: "50%",
											marginRight: "10px",
										}}
										src={ticket.platform.icons?.inactive}
										className="image-platform"
										alt="platform"
									/>
								</Typography>
							</Box>
							<Box
								sx={{
									position: "relative",
									left: 0,
									width: "25%",
									minheight: "150px",
									maxHeight: "150px",
									background: theme.palette.electricBlue.secondary,
									borderRadius: "15px",
									padding: "15px",
									marginTop: "0px",
									marginLeft: "3rem",
									justifyContent: "space-between",
									height: "fit-content",
								}}
							>
								<Stack>
									<Typography variant="quoteCartTitle">
										Estimated time:&nbsp;
										<Typography variant="quoteCartContent">
											{ticket.estimatedTime}&nbsp;hour(s)
										</Typography>
									</Typography>
									<Typography variant="quoteCartTitle">
										Price:&nbsp;
										<Typography variant="quoteCartContent">
											{(ticket.price / 100)?.toLocaleString("en-us", {
												style: "currency",
												currency: "USD",
											})}
										</Typography>
									</Typography>
								</Stack>
								<Stack sx={{ alignSelf: "flex-end" }}>
									<FormGroup>
										<FormControlLabel
											sx={{ width: "100%", margin: "0px" }}
											variant="quoteCheckboxLabel"
											control={
												<Checkbox
													checked={check}
													onChange={handleChange}
													icon={
														<Avatar variant="iconCheck" src={checkBoxIcon} />
													}
													checkedIcon={
														<Avatar variant="iconCheck" src={checkIcon} />
													}
												/>
											}
											label="Add to cart"
											labelPlacement="start"
										/>
									</FormGroup>
								</Stack>
							</Box>
						</Stack>
					</Stack>
					<img
						draggable={false}
						src={quoteBottom}
						className="img-quote-bottom"
						alt="circles"
					/>
				</Box>
			)}
		</>
	);
}
