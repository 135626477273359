import React from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";

import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import LocationOnIcon from "@mui/icons-material/LocationOn";

// ICONS
import "./BillingPayments.css";
import { getPayments } from "../../../hooks/auth";
import { MyAccountTabs } from "../../templates/MyAcountTabs";
import PaymentsTable from "./table";
import person from "../../../Sources/Icons/Person.svg";
import Email from "../../../Sources/Icons/Email.svg";
import Company from "../../../Sources/Icons/Company.svg";

export function BillingPayments() {
	const [address, setAddress] = React.useState("");
	const [payments, setPayments] = React.useState(null);
	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);
	const { user } = auth;
	React.useEffect(() => {
		loadPaymentsFromApi();
		setAddress(user?.billingAddress?.address);
	}, []);

	function LoadingState() {
		if (payments === null) {
			return <div>Loading...</div>;
		} else if (payments.length === 0) {
			return <div>No payments found</div>;
		} else if (payments.length > 0) {
			return <PaymentsTable payments={payments} />;
		} else {
			return <div>Something went wrong</div>;
		}
	}

	async function handleSelect(address) {
		try {
			const results = await geocodeByAddress(address);

			const state = results[0].address_components.filter((component) =>
				component.types.includes("administrative_area_level_1")
			);
			const city = results[0].address_components.filter((component) =>
				component.types.includes("locality")
			);
			const zip = results[0].address_components.filter((component) =>
				component.types.includes("postal_code")
			);

			document.getElementById("inputCity").value = city[0].long_name;
			document.getElementById("inputZip").value = zip[0].long_name;
			document.getElementById("inputState").value = state[0].long_name;

			setAddress(address);
		} catch (e) {
			console.error(e);
		}
	}

	async function loadPaymentsFromApi() {
		try {
			// really are all tickets from all projects, we need to filter only payed tickets
			const P = await getPayments(auth.user.uid, projects.project.uid, auth.token);
			const PF = P.filter((p) => p.payment.status === "completed");
			setPayments(PF);
		} catch (e) {
			console.error(e);
		}
	}

	return (
		<MyAccountTabs className="billing-container">
			<form>
				<TextField
					name="firstName"
					id="firstName"
					placeholder="First Name"
					variant="outlined"
					helperText=" "
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={person} />
							</InputAdornment>
						),
					}}
					value={user.firstName}
					disabled
				/>
				<TextField
					name="lastName"
					id="lastName"
					placeholder="Last name"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={person} />
							</InputAdornment>
						),
					}}
					value={user.lastName}
					disabled
				/>
				<TextField
					id="email"
					placeholder="Email"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Email} />
							</InputAdornment>
						),
					}}
					value={user.email}
					disabled
				/>
				<TextField
					name="company"
					id="company"
					placeholder="Website (optional)"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					value={user.company}
					disabled
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Company} />
							</InputAdornment>
						),
					}}
				/>
				<PlacesAutocomplete
					value={address}
					onChange={setAddress}
					onSelect={handleSelect}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<>
							{/* <input
									{...getInputProps({
										placeholder: "Address ...",
										className: "location-search-input",
									})}
								/> */}

							<TextField
								variant="outlined"
								fullWidth
								size="small"
								helperText=" "
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="iconAvatar" src={Company} />
										</InputAdornment>
									),
								}}
								disabled
								value={user?.billingAddress?.office}
								{...getInputProps({
									placeholder: "Address ...",
									className: "location-search-input",
									disabled: true,
								})}
							/>
							<div className="autocomplete-relative-container">
								<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion, index) => {
										const className = suggestion.active
											? "suggestion-item--active"
											: "suggestion-item";
										// inline style for demonstration purpose
										const style = suggestion.active
											? { backgroundColor: "#fafafa", cursor: "pointer" }
											: { backgroundColor: "#ffffff", cursor: "pointer" };
										return (
											<div
												key={index}
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						</>
					)}
				</PlacesAutocomplete>
				<TextField
					name="reference"
					id="reference"
					placeholder="Office, Apt or Suite number"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Company} />
							</InputAdornment>
						),
					}}
					disabled={true}
					value={user?.billingAddress?.office}
				/>
				<TextField
					name="inputCity"
					id="inputCity"
					placeholder="City"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon></LocationOnIcon>
							</InputAdornment>
						),
					}}
					value={user?.billingAddress?.city}
					disabled={true}
				/>
				<TextField
					name="inputState"
					id="inputState"
					placeholder="State"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					value={user?.billingAddress?.state}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon></LocationOnIcon>
							</InputAdornment>
						),
					}}
					disabled={true}
				/>
				<TextField
					name="inputZip"
					id="inputZip"
					placeholder="ZIP"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					value={user?.billingAddress?.zip}
					disabled={true}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon></LocationOnIcon>
							</InputAdornment>
						),
					}}
				/>
			</form>

			<LoadingState />
		</MyAccountTabs>
	);
}
