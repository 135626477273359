import React from "react";
import { getAuth, updatePassword } from "firebase/auth";

import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

/* Icons */
import "./MyProfile.css";
// import api from "../../../config/apiConfig";
import { ModalResign } from "./ModalResign";
import person from "../../../Sources/Icons/Person.svg";
import Email from "../../../Sources/Icons/Email.svg";
import Company from "../../../Sources/Icons/Company.svg";
import Key from "../../../Sources/Icons/Key.svg";
import Phone from "../../../Sources/Icons/Phone.svg";
import Question from "../../../Sources/Icons/Question.svg";
import { getByIdAuthed } from "../../../backend/services";
import { MyAccountTabs } from "../../templates/MyAcountTabs";
import { constructLongDate } from "../../../hooks/localeString";
import { enqueueSnackbar } from "notistack";

function MyProfile() {
	const auth = useSelector((state) => state.auth);
	const { user } = auth;
	const [client, setClient] = React.useState({});

	const [agreement, setAgreement] = React.useState({
		content: "",
		sign: "",
		fullName: "",
		hasAgreement: Boolean,
	});

	const [loadingAgreement, setLoadingAgreement] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState("paper");
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfPassword, setShowConfPassword] = React.useState(false);

	const theme = useTheme();

	const getAgreement = async () => {
		try {
			await getByIdAuthed({
				route: "agreement/getSignedAgreement",
				id: auth.user.uid,
				accessToken: auth.token,
			}).then((response) => {
				setAgreement({
					content: response.agreement.contentAgreement,
					sign: response.agreement.imgUrlSigned,
					fullName: response.agreement.fullName,
					hasAgreement: response.agreement.hasAgreement,
				});
				setLoadingAgreement(true);
			});
		} catch (error) {
			console.error(error);
			setLoadingAgreement(true);
		}
	};

	React.useEffect(() => {
		getAgreement();
	}, [auth.user]);

	function handleClickOpen(scrollType) {
		setOpen(true);
		setScroll(scrollType);
	}

	async function handelSubmit(e) {
		e.preventDefault();

		if (e.target.password.value) {
			const auth = getAuth();

			const user = auth.currentUser;
			const newPassword = e.target.password.value;

			await updatePassword(user, newPassword)
				.then(() => {
					enqueueSnackbar("Pasword changed", { variant: "success" });
				})
				.catch((error) => {
					enqueueSnackbar(
						"Your password could not be changed, log out and start again",
						{ variant: "error" }
					);
					console.error(error);
				});
		}
	}

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfPassword = () => {
		setShowConfPassword(!showConfPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<MyAccountTabs className="my-profile-container">
			<form onSubmit={handelSubmit}>
				<div className="inputs-container">
					<TextField
						name="firstName"
						id="firstName"
						placeholder="First name"
						disabled={true}
						variant="outlined"
						helperText=" "
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={person} />
								</InputAdornment>
							),
						}}
						defaultValue={user.firstName}
						value={user.firstName}
						onChange={(e) =>
							setClient({ ...client, firstName: e.target.value })
						}
					/>

					<TextField
						name="lastName"
						id="lastName"
						placeholder="Last name"
						variant="outlined"
						fullWidth
						disabled={true}
						size="small"
						helperText=" "
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={person} />
								</InputAdornment>
							),
						}}
						value={user.lastName}
						onChange={(e) => setClient({ ...client, lastName: e.target.value })}
					/>

					<TextField
						name="company"
						id="company"
						placeholder="Website (optional)"
						variant="outlined"
						fullWidth
						disabled={true}
						size="small"
						helperText=" "
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Company} />
								</InputAdornment>
							),
						}}
						value={user.company}
						onChange={(e) => setClient({ ...client, company: e.target.value })}
					/>

					<TextField
						id="email"
						placeholder="Email"
						variant="outlined"
						fullWidth
						disabled={true}
						size="small"
						helperText=" "
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Email} />
								</InputAdornment>
							),
						}}
						defaultValue={user.email}
						value={user.email}
					/>

					<TextField
						type={showPassword ? "text" : "password"}
						id="password"
						placeholder="Password"
						variant="outlined"
						fullWidth
						size="small"
						helperText=" "
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										variant="iconVisibility"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										// onMouseDown={handleMouseDownPassword}
										edge="end"
										style={{ borderLeftColor: "#C0C0C0" }}
									>
										{showPassword ? (
											<VisibilityOffIcon sx={{ color: "#C0C0C0" }} />
										) : (
											<VisibilityIcon sx={{ color: "#C0C0C0" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Key} />
								</InputAdornment>
							),
						}}
					/>
					<TextField
						type={showConfPassword ? "text" : "password"}
						id="passwordConfirmation"
						placeholder="Confirm password"
						variant="outlined"
						fullWidth
						size="small"
						helperText=" "
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfPassword}
										onMouseDown={handleMouseDownPassword}
										variant="iconVisibility"
										edge="end"
									>
										{showConfPassword ? (
											<VisibilityOffIcon sx={{ color: "#C0C0C0" }} />
										) : (
											<VisibilityIcon sx={{ color: "#C0C0C0" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Key} />
								</InputAdornment>
							),
						}}
					/>

					<TextField
						name="phone"
						disabled={true}
						id="phoneNumber"
						placeholder="Phone Number (optional)"
						fullWidth
						variant="outlined"
						size="small"
						helperText=" "
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Phone} />
								</InputAdornment>
							),
						}}
						value={user.phone !== "" ? user.phone : ""}
						onChange={(e) => setClient({ ...client, phone: e.target.value })}
					/>

					<TextField
						name="feedback"
						disabled={true}
						id="feedback"
						placeholder="How did you hear about us?"
						fullWidth
						variant="outlined"
						helperText=" "
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Avatar variant="iconAvatar" src={Question} />
								</InputAdornment>
							),
						}}
						value={user.feedback !== "" ? user.feedback : ""}
						onChange={(e) => setClient({ ...client, feedback: e.target.value })}
					/>
					{/* {client.authProvider === "Google" ||
          client.authProvider === "google" ? (
            <>
              <input
                name="password"
                type="hidden"
                placeholder="New password"
                value=""
                autocomplete="off"
              />
              <p>Logeado con google (example text)</p>
            </>
          ) : (
            <>
              <input
                name="password"
                type="password"
                placeholder="New password"
                autocomplete="off"
              />
            </>
          )} */}
					<div className="nds-options-container">
						{/* <p>NDA: signed</p> */}
						<Typography
							variant="descriptiveText"
							sx={{ color: "#112031", fontWeight: "500" }}
						>
							NDA: {loadingAgreement ? "Signed" : "Not signed"}
						</Typography>
						<Button
							variant="primaryButton"
							sx={{
								my: "1.2rem",
								minWidth: "40%",
							}}
							onClick={() => handleClickOpen("paper")}
							disabled={!loadingAgreement}
						>
							Open
						</Button>
					</div>
				</div>
				<p
					style={{
						marginTop: "30px",
						marginBottom: "0px",
						fontSize: "15px",
						color: "#112031",
					}}
				>
					Signed up on {constructLongDate(auth.user.createdAt)}
				</p>
				<p
					style={{
						marginTop: "0px",
						marginBottom: "0px",
						fontSize: "15px",
						color: "#112031",
					}}
				>
					Last login {constructLongDate(auth.user.lastLoginAt)}
				</p>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
				>
					{/* <button className="btn">Save changes</button> */}
					<Button
						type="submit"
						variant="primaryButton"
						sx={{
							[theme.breakpoints.down("lg")]: { width: "100%" },
							my: "1.2rem",
							width: "20%",
						}}
					>
						Save changes
					</Button>
				</Stack>
			</form>
			<ModalResign
				open={open}
				setOpen={setOpen}
				scroll={scroll}
				agreement={agreement}
				loadingAgreement={loadingAgreement}
			/>
		</MyAccountTabs>
	);
}
export { MyProfile };
