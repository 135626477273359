// import React from "react";
import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
// import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { timeSlots } from "./info";
import { ListItemButton } from "@mui/material";
export default function HourSelector() {
	// {
	// scheduledHour,
	// setScheduledHour,
	// scheduledDate,
	// ocupatedDates,
	// tasks,
	// isMobile,
	// }
	function hasHourPassed(timeSlots) {
		const currentHour = new Date().getHours();
		const currentMinute = new Date().getMinutes();

		// Convert current time to "HH:mm" format
		const currentTime = `${currentHour
			.toString()
			.padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

		const index = timeSlots.findIndex((timeSlot) => timeSlot >= currentTime);

		if (index !== -1) {
			// Time slot found
			return index;
		} else {
			// Time slot not found
			return -1;
		}
	}

	const [passedHour, setPassedHour] = React.useState(null);

	React.useEffect(() => {
		const index = hasHourPassed(timeSlots);

		if (index !== -1) {
			setPassedHour(index);
		} else {
			setPassedHour(-1);
		}
	}, []);

	return (
		<Box
			sx={{
				padding: "30px",
				borderRadius: "20px",
				maxHeight: "450px",
				// width: isMobile ? "100%" : "587px",
				width: "100%",
				backgroundColor: "rgba(255, 255, 255, 0.2)",
				backdropFilter: "blur(20px)",
				boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
				overflow: "auto",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography
					variant="calendarMonth"
					sx={{
						width: "100%",
						textAlign: "center",
					}}
				>
					{/* {new Date(
						scheduledDate.year,
						scheduledDate.month,
						scheduledDate.day
					).toLocaleDateString("en-US", {
						year: "numeric",
						month: "long",
						day: "numeric",
						weekday: "long",
					})} */}
				</Typography>
			</Box>
			<Box
				sx={{
					// maxHeight: isMobile ? "calc(100% - 20px)" : "45vh",
					overflowY: "auto",
					paddingRight: "20px",
					paddingBottom: "20px",
				}}
			>
				<List variant="hourList" spacing={0}>
					{timeSlots.map((hour, index) => (
						<ListItemButton
							key={index}
							variant="hourItem"
							// onClick={() => handleSelectHour(hour.textHour, false)}
							id={hour.textHour + "hour"}
							// selected={scheduledHour === hour.textHour}
							disabled={index < passedHour ? true : false}
							className="hour-container"
						>
							<Typography variant="calendarDay">{hour}</Typography>
						</ListItemButton>
					))}
				</List>
			</Box>
		</Box>
	);
}
