import { postMethodAuthed } from "../backend/services";

export async function setPaymentIntent(clientUid, projectUid, accessToken) {
	const res = await postMethodAuthed({
		route: "quote/paymentIntent",
		body: {
			clientUid,
			projectUid,
		},
		accessToken,
	});
	if (res.success) return res.data;
	else throw new Error(res.message);
}

export async function updateBillingAddress(clientUid, data, accessToken) {
	const res = await postMethodAuthed({
		route: "payments/saveBillingAddress",
		body: {
			clientUid,
			data,
		},
		accessToken,
	});
	if (res.success) return res.data;
	else throw new Error(res.message);
}
