import React from "react";

import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { StyledBadge } from "../muiCustomComponents/styleBadge";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import ReactConfetti from "react-confetti";

import { isMobile } from "../../hooks/utils";
import deployed from "../../Sources/images/deployed.svg";
import testing from "../../Sources/images/testing.svg";
import approve from "../../Sources/images/approved.svg";
import completed from "../../Sources/images/completed.svg";
import ReusableModal from "../reusableComponents/modal";
import { DialogActions, Tooltip } from "@mui/material";
import { PrintEvidence } from "./printEvidence";
import { useSelector } from "react-redux";
import {
	approveTask,
	rejectTask,
	setReopenModalValueToFalse,
} from "../../hooks/projects";
import { enqueueSnackbar } from "notistack";
import { constructShortDate, constructTime } from "../../hooks/localeString";

const CustomToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		padding: theme.spacing(1),
		borderRadius: 15,
		backgroundColor: theme.palette.common.white,
		color: "#94a3ca",
		boxShadow: "8px 8px 20px rgb(192 192 192 / 25%)",
		fontFamily: "Ulagadi Sans",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "1.4rem",
	},
}));

export default function TaskItem({ task }) {
	const theme = useTheme();
	const check = isMobile();
	const [openModal, setOpenModal] = React.useState(false);

	const projects = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);
	const { project } = projects;

	const [openTask, setOpenTask] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [image, setImage] = React.useState(deployed);

	React.useEffect(() => {
		switch (task.status) {
		case 0:
			setTitle(
				`Congratulations! The task "${task.title}" is now being developed.`
			);
			setImage(deployed);
			!task.discardedModal && setOpenTask(true);
			break;
		case 1:
			setTitle(`Congratulations! "${task.title}" task is now being tested.`);
			setImage(testing);
			!task.discardedModal && setOpenTask(true);
			break;
		case 2:
			setTitle(`The task "${task.title}" needs your aproval.`);
			setImage(approve);
			!task.discardedModal && setOpenTask(true);
			break;
		case 3:
			setTitle(`Congratulations! "${task.title}" task is being deployed.`);
			setImage(deployed);
			!task.discardedModal && setOpenTask(true);
			break;
		case 4:
			setTitle(`Congratulations! "${task.title}" task is now completed.`);
			setImage(completed);
			!task.discardedModal && setOpenTask(true);
			break;
		default:
			break;
		}
	}, [task.status]);

	function ModalContent() {
		return (
			<>
				<DialogTitle sx={{ marginBottom: "25px" }}>{title}</DialogTitle>
				<DialogContent
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
						padding: "20px 32px",
					}}
				>
					<Avatar
						sx={{ placeSelf: "center" }}
						variant="modalImage"
						src={image}
					/>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
					}}
				>
					<Button
						variant="primaryButton"
						type="submit"
						sx={{
							width: "120px",
							placeSelf: "center",
							alignSelf: "flex-start",
							marginTop: 3,
							marginBottom: 2,
						}}
						onClick={() => {
							setReopenModalValueToFalse(project.uid, task.uuid, auth.token);
							setOpenTask(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</>
		);
	}

	//todo[x] refactor printEvidencia,  to use a switch case

	async function handleApproveTicket() {
		try {
			await approveTask(project.uid, task.uuid, auth.token);
		} catch (e) {
			enqueueSnackbar("Error rejecting task", { variant: "error" });
		}
	}
	async function handleRejectTicket() {
		try {
			await rejectTask(project.uid, task.uuid, auth.token);
		} catch (e) {
			enqueueSnackbar("Error rejecting task", { variant: "error" });
		}
	}

	return (
		<Box
			sx={{
				width: "100%",
				display: task.payment.status !== "completed" && "none",
			}}
		>
			{/* 
				task confetti animation
			*/}
			<Stack
				position="absolute"
				sx={{
					top: 0,
					left: 0,
				}}
			>
				{task.status === 4 && openTask && (
					<ReactConfetti tweenDuration={"20000"} />
				)}
			</Stack>
			{/* 
				see the updated task
			*/}
			<ReusableModal
				dialogProps={{
					open: openTask,
					onClose: () => setOpenTask(false),
				}}
			>
				<ModalContent />
			</ReusableModal>
			{/* 
				see the task details (description, mostly)
			*/}
			<ReusableModal
				dialogProps={{
					open: openModal,
					onClose: () => setOpenModal(false),
				}}
			>
				<DialogTitle>Details</DialogTitle>
				<DialogContent>{task.description}</DialogContent>
			</ReusableModal>
			<Box
				sx={{
					opacity: task.status >= 4 ? 0.5 : "inherit",
					border: task.status >= 4 ? "1px dashed #1976d2" : "1px dashed #333",
					position: "relative",
					display: "flex",
					flexWrap: "wrap",
					borderRadius: "20px",
					backgroundColor: task.status >= 4 ? "#1976d233" : "transparent",
					width: "70%",
					marginTop: 5,
					[theme.breakpoints.down("md")]: {
						marginTop: 2,
					},
				}}
				className={
					task.status > 5 ? "task-container completed" : "task-container"
				}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
					}}
				>
					<Stack
						sx={{
							paddingLeft: 2,
							width: "100%",
						}}
					>
						<Stack
							direction="row"
							sx={{
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography
								variant="calendarSchedule"
								sx={{
									color: theme.palette.electricBlue.secondary,
								}}
							>
								{task.title}
							</Typography>
							{check && (
								<CustomToolTip
									placement="top"
									title={task.assignee.displayName?.split(" ")[0] ?? ""}
								>
									<StyledBadge
										overlap="circular"
										anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
										variant="dot"
									>
										<Avatar
											alt={task.assignee.displayName?.split(" ")[0]}
											src={task.assignee.photoURL}
										/>
									</StyledBadge>
								</CustomToolTip>
							)}
						</Stack>
						<MuiLink
							onClick={() => setOpenModal(true)}
							style={{
								textDecoration: "underline",
								border: "none",
								background: "transparent",
								cursor: "pointer",
								marginBottom: "50px",
								fontFamily: "Ulagadi Sans",
								fontStyle: "normal",
								fontWeight: "200",
								fontSize: "15px",
								lineHeight: "20px",
								color: theme.palette.primary.main,
							}}
							variant={"caption"}
							sx={{
								cursor: "pointer",
								[theme.breakpoints.down("md")]: {
									marginBottom: "20px",
								},
							}}
						>
							See details
						</MuiLink>
					</Stack>
					{task?.eta.seconds && (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								position: "absolute",
								top: "26px",
								right: "50px",
							}}
						>
							<EventAvailableIcon sx={{ fontSize: 40, color: "#94A3CA" }} />
							<Stack spacing={-0.5}>
								<Typography variant="calendarDay">ETA:</Typography>
								<Stack direction={"row"} spacing={1}>
									<Typography variant="calendarDay">
										{constructShortDate(task?.eta?.seconds)}&nbsp;
										{constructTime(task?.eta?.seconds)}
									</Typography>
								</Stack>
							</Stack>
						</Box>
					)}
				</Box>
				<Stack direction="row" sx={{ width: "100%" }}>
					<ul className={check ? "stepper-work-mobile" : "stepper-work"}>
						<li
							className={
								task.status === 0
									? "active"
									: task.status > 0
										? "approve"
										: "inactive"
							}
						>
							<div className="circle"></div>
							<p>Development</p>
							<div className="line-steper-right"></div>
						</li>
						<li
							className={
								task.status === 1
									? "active"
									: task.status > 1
										? "approve"
										: "inactive"
							}
						>
							<div className="line-steper-left"></div>
							<div className="circle"></div>
							<p>Testing</p>
							<div className="line-steper-right"></div>
						</li>
						<li
							className={
								task.status === 2
									? "active"
									: task.status > 2
										? "approve"
										: "inactive"
							}
						>
							<div className="line-steper-left"></div>
							<div className="circle"></div>
							<p>Approval</p>
							<div className="line-steper-right"></div>
						</li>
						<li
							className={
								task.status === 3
									? "active"
									: task.status > 3
										? "approve"
										: "inactive"
							}
						>
							<div className="line-steper-left" />
							<div className="circle" />
							<div className="line-steper-right" />
							<p>Deployment</p>
						</li>
						<li className={task.status === 4 && "approve"}>
							<div className="line-steper-left"></div>
							<div className="circle"></div>
							<p>Finished</p>
						</li>
					</ul>
					{!check && (
						<CustomToolTip
							placement="top"
							title={task.assignee.displayName?.split(" ")[0] ?? ""}
						>
							<StyledBadge
								overlap="circular"
								anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								variant="dot"
							>
								<Avatar
									alt={task.assignee.displayName?.split(" ")[0]}
									src={task.assignee.photoURL}
								/>
							</StyledBadge>
						</CustomToolTip>
					)}
				</Stack>

				{task.status === 2 && (
					<Stack sx={{ width: "100%" }} className="work-inprogress-container">
						<div className="progress-video-container">
							{task.evidence.length > 0 &&
								task.evidence.map((ref) => (
									<PrintEvidence key={ref} storageRef={ref} />
								))}
							<div className="col-50 approve-btn-container">
								<Button onClick={handleApproveTicket} variant="primaryButton">
									Approve the work
								</Button>
								<button
									onClick={handleRejectTicket}
									className="btn reject approval"
								>
									Reject
								</button>
							</div>
						</div>
					</Stack>
				)}
			</Box>
		</Box>
	);
}
