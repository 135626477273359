import { Typography } from "@mui/material";
import { constructPrice } from "../../hooks/localeString";

export default function BottomValues({ quote }) {
	return (
		<>
			<Typography variant="quoteTotal">
				Total:
				<Typography variant="quoteTotalValue">
					&nbsp;
					{constructPrice(quote?.totalPrice / 100)}
				</Typography>
			</Typography>
			<Typography variant="quoteTotal">
				Time:
				<Typography variant="quoteTotalValue">
					&nbsp;{quote?.estimatedTime} hours
				</Typography>
			</Typography>
		</>
	);
}
