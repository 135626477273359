import React from "react";

import { RequierementsContext } from "../../Context/RequierementsContext";
import { TransitionGroup } from "react-transition-group";

/* import {
	// getDownloadURL,
	ref,
	// uploadBytesResumable,
	deleteObject,
	// getMetadata,
} from "firebase/storage";
import { storage } from "../../firebase"; */

//! MUI IMPORTS
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import CircularProgress from "@mui/material/CircularProgress";
// import styled from "@mui/material/styles/styled";
import { v4 } from "uuid";
import Collapse from "@mui/material/Collapse";

// import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from "@mui/material/AccordionSummary";
// import MuiAccordionDetails from "@mui/material/AccordionDetails";

//! MUI ICONS

//! ICONS
import WorkDescriptionAttachment from "../../Sources/Icons/WorkDescriptionAttachment.svg";
import DeleteAttachment from "../../Sources/Icons/DeleteAttachment.svg";
// import ExpandTaskIcon from "../../Sources/Icons/ExpandTaskIcon.svg";
import { TaskHeaderDesktop } from "./taskHeaderDesktop";
import { TaskHeaderMobile } from "./taskHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { isMobile /* , toBase64 */ } from "../../hooks/utils";
import {
	addFileRef,
	descriptionChange,
	removeFileRef,
	removeTask,
} from "../../features/requests/requestsSlice";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { BackdropContext } from "../../Context/BackdropContext";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../templates/taskAccordion";
import { OutlinedInput } from "../templates/inputs/textFields";

export function Task(props) {
	const { task, index, disabled, taskIndex } = props;
	const { uuid } = task;
	const { tasks } = useSelector((state) => state.requestsSlice);

	const [findedTask, setFindedTask] = React.useState(task);

	React.useEffect(() => {
		tasks.find((task) => {
			if (task.uuid === uuid) {
				setFindedTask(task);
			}
		});
	}, [tasks]);
	const { removeAllFilesFromTask } = React.useContext(RequierementsContext);

	const check = isMobile();
	const storage = getStorage();
	// const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const { addFile, removeFile } = React.useContext(RequierementsContext);
	const fileRef = React.useRef();
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	const [expanded /* setExpanded */] = React.useState(true);

	function handleDescriptionChange(e) {
		dispatch(descriptionChange({ description: e.target.value, uuid }));
	}

	function FilePreview({ obj, props }) {
		return (
			<Stack
				direction="row"
				alignItems="center"
				spacing={2}
				sx={{
					marginLeft: "26px",
				}}
			>
				<Typography variant="myTeamRole">
					File name:&nbsp;{obj.fileName}
				</Typography>

				<IconButton
					onClick={() => {
						removeImage(obj);
					}}
					disableRipple
					sx={{ padding: 0, marginLeft: 1 }}
					disabled={props.disabled}
				>
					<Avatar variant="stepperCompletedIcon" src={DeleteAttachment} />
				</IconButton>
			</Stack>
		);
	}

	function StoredFilePreview({ obj, props }) {
		return (
			<Stack
				sx={{
					marginTop: 0.5,
					marginBottom: 0.5,
				}}
				direction={"row"}
				alignItems={"center"}
				spacing={1}
			>
				<Avatar
					sx={{
						borderRadius: 0,
					}}
					src={obj.fileUrl}
				/>
				<Typography variant="myTeamRole">
					File name:&nbsp;{obj.fileName}
				</Typography>
				<IconButton
					onClick={() => {
						removeImage(obj);
					}}
					disableRipple
					sx={{ padding: 0, marginLeft: 1 }}
					disabled={props.disabled}
				>
					<Avatar variant="stepperCompletedIcon" src={DeleteAttachment} />
				</IconButton>
			</Stack>
		);
	}

	function handleFileChange(e) {
		const newFiles = [...e.target.files].map((file) => {
			return {
				file: file,
				id: v4(),
				taskUuid: uuid,
				progress: 0,
			};
		});

		const fileRefs = newFiles.map((file) => {
			return {
				id: file.id,
				taskUuid: uuid,
				fileName: file.file.name,
				fileType: file.file.type,
				fileUrl: "",
				fileRef: "",
			};
		});
		addFile(newFiles);
		dispatch(addFileRef({ object: fileRefs, uuid }));
	}

	async function removeImage(file) {
		if (file.fileUrl !== "") {
			changeLoadingBackdrop(true);
			// Delete the file
			await deleteObject(ref(storage, file.fileRef))
				.then(() => {
					// File deleted successfully
				})
				.catch((error) => {
					// Uh-oh, an error occurred!
					console.error(error);
				});
		}

		removeFile(file);
		dispatch(removeFileRef({ file, uuid }));
		changeLoadingBackdrop(false);
	}

	function deleteTask(uuid, attachments) {
		changeLoadingBackdrop(true);
		attachments.forEach((attachment) => {
			if (attachment.fileUrl !== "") {
				// Delete the file
				deleteObject(ref(storage, attachment.fileRef))
					.then(() => {
						// File deleted successfully
					})
					.catch((error) => {
						// Uh-oh, an error occurred!
						console.error(error);
					});
			}
		});
		changeLoadingBackdrop(false);
		removeAllFilesFromTask(uuid);
		dispatch(removeTask(uuid));
	}
	return (
		<Accordion expanded={expanded}>
			<AccordionSummary
				sx={{
					"& .MuiAccordionSummary-expandIconWrapper": {
						alignSelf: check ? "flex-start" : "center",
						paddingTop: check ? 1 : 0,
					},
				}}
				expandIcon={
					<Stack spacing={1} direction="row" sx={{}}>
						{/*
						<IconButton
							onClick={() => {
								setExpanded(!expanded);
							}}
							disableRipple
						>
							<Avatar
								sx={{
									transform: expanded ? "rotate(0deg)" : "rotate(-180deg)",
									transition:
										"transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
									cursor: "pointer",
								}}
								variant="iconArrows"
								src={ExpandTaskIcon}
							/>
						</IconButton> */}
						<IconButton
							sx={{
								display: index === 0 ? "none" : "initial",
							}}
							disableRipple
							onClick={() => deleteTask(uuid, task.attachments)}
							disabled={disabled}
						>
							<Avatar
								sx={{
									cursor: "pointer",
								}}
								variant="iconArrows"
								src={DeleteAttachment}
								value={index}
							/>
						</IconButton>
					</Stack>
				}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{check ? (
					<TaskHeaderMobile
						index={index}
						expanded={expanded}
						taskIndex={taskIndex}
					/>
				) : (
					<TaskHeaderDesktop
						index={index}
						expanded={expanded}
						taskIndex={taskIndex}
					/>
				)}
			</AccordionSummary>
			<AccordionDetails>
				<FormControl sx={{ paddingBottom: 1 }} fullWidth>
					<OutlinedInput
						placeholder="Describe your task..."
						multiline
						minRows={8}
						maxRows={25}
						disabled={disabled}
						value={findedTask.description}
						onChange={(e) => {
							handleDescriptionChange(e);
						}}
					/>
				</FormControl>
				<Box sx={{ marginLeft: "4px" }}>
					<Button
						disableRipple
						variant="attachmentTaskButton"
						component="label"
						sx={{
							"& .MuiButton-startIcon": {
								marginLeft: 0,
							},
						}}
						startIcon={
							<Avatar variant="iconArrows" src={WorkDescriptionAttachment} />
						}
						// sx={{ marginBottom: 1 }}
					>
						<input
							type="file"
							hidden
							accept="image/*"
							id={"file"}
							multiple={true}
							onChange={handleFileChange}
							ref={fileRef}
							disabled={disabled}
						/>
						Add attachment &nbsp;
						<Typography
							sx={{
								fontSize: "inherit",
								fontWeight: "200",
								color: "inherit",
							}}
						>
							(optional)
						</Typography>
					</Button>
					<Stack
						sx={{
							display: findedTask.attachments.length > 0 ? "initial" : "none",
							width: "100%",
							marginBottom: "12px",
						}}
					>
						<TransitionGroup>
							{findedTask.attachments.map((obj) => (
								<Collapse key={obj.id}>
									{obj.fileUrl !== "" ? (
										<StoredFilePreview obj={obj} props={props} />
									) : (
										<FilePreview obj={obj} props={props} />
									)}
								</Collapse>
							))}
						</TransitionGroup>
					</Stack>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
