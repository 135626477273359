import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Avatar from "@mui/material/Avatar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import StepLabel from "@mui/material/StepLabel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

// import { PlatformsStep } from "../../components/StepsContent/platforms";
import { setActiveStep } from "../../features/project/projectSlice";

import ActiveStepIcon from "../../Sources/Icons/ActiveStepIcon.svg";
import CompletedStepIcon from "../../Sources/Icons/CompletedStepIcon.svg";
import NextStepIcon from "../../Sources/Icons/NextStepIcon.svg";
import ProjectIcon from "../../Sources/Icons/ProjectIcon.svg";
// import ExpandSidebarIcon from "../../Sources/Icons/ExpandSidebarIcon.svg";
import ThreeDotsIcon from "../../Sources/Icons/ThreeDotsIcon.svg";
import { steps } from "../../hooks/utils";
import {
	updateProject,
	updateProjectNameRedux,
} from "../../features/project/projectSlice";
import ReusableModal from "../reusableComponents/modal";
import { updateProjectName } from "../../hooks/projects";

export function StepperComponent({ toggleSidebarMenu, name, project }) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const ref = React.useRef();

	const [openNameChangeModal, setOpenNameChangeModal] = React.useState(false);

	const navigate = useNavigate();
	const validationSchema = yup.object({
		projectName: yup
			.string("Enter your project name")
			.min(1, "project name should be of minimum 1 character length")
			.required("project name is required"),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: "onTouched",
	});

	const [contextMenu, setContextMenu] = React.useState(null);
	const [expanded, setExpanded] = React.useState(true);
	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);
	const location = useLocation();

	const [tempSteps, setTempSteps] = React.useState([]);

	function TempSteps() {
		const stepsCopy = steps.slice(0, project?.lastCompletedStep + 2);
		setTempSteps(stepsCopy);
	}
	React.useEffect(() => {
		function setExpandedLocal() {
			if (projects?.activeProjectUid === project?.uid) {
				setExpanded(true);
			}
		}

		setExpandedLocal();
	}, []);

	React.useEffect(() => {
		TempSteps();

		if (
			location.pathname === "/MyAccount" ||
			location.pathname === "/chat" ||
			location.pathname === "/newProject"
		) {
			return;
		} else {
			navigate(steps[projects?.project?.activeStep]?.url);
		}
	}, [projects.project?.activeStep]);

	function handleContextMenu(event) {
		// event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
					mouseX: event.clientX + 2,
					mouseY: event.clientY - 6,
				  }
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
				  // Other native context menus might behave different.
				  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
				  null
		);
	}

	const handleClose = () => {
		setContextMenu(null);
	};

	async function handleNameChange(data) {
		try {
			await updateProjectName(project.uid, data.projectName, auth.token);
			dispatch(updateProjectNameRedux(data.projectName));
			setOpenNameChangeModal(false);
			handleClose();
		} catch (e) {
			enqueueSnackbar("Something went wrong updating project name", {
				variant: "error",
			});
		}
	}

	return (
		<Accordion
			expanded={expanded}
			elevation={0}
			sx={{
				backgroundColor: "transparent",
				"& .MuiAccordionSummary-expandIconWrapper": {
					color: "white",
				},
				"& .MuiStepLabel-labelContainer": {
					color: theme.palette.lightBlue.secondary,
				},

				"& .MuiStepLabel-label.Mui-completed": {
					color: theme.palette.lightBlue.main,
				},
				"& .MuiSvgIcon-root": {
					color: theme.palette.lightBlue.secondary,
				},
				"& .Mui-active .MuiSvgIcon-root": {
					color: theme.palette.teal.main,
				},
				"& 	.MuiStepConnector-line": {
					borderColor: "transparent",
				},
				"&	.MuiStepIcon-root": {
					color: theme.palette.lightBlue.secondary,
				},
				"& .MuiButtonBase-root": {
					padding: "1.5rem 0",
				},
				"& .MuiButtonBase-root.MuiAccordionSummary-root:hover:not(.Mui-disabled)":
					{
						cursor: "default",
					},
				"& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
					minHeight: "1.2rem",
				},
				"& .MuiAccordionDetails-root": {
					// height: project.completedSteps[2] ? "100%" : "0px",
					// padding: project.completedSteps[2] ? "0px" : "0px",
				},
				"& .MuiAccordionSummary-root": {
					minHeight: "1.2rem",
				},
				"& .MuiAccordionSummary-content": {
					margin: "0px",
				},

				"& .MuiAccordionSummary-content.Mui-expanded": {
					margin: "0px",
				},
			}}
		>
			<AccordionSummary
				aria-controls="panel1a-content"
				id="panel1a-header"
				elevation={0}
				sx={{
					position: "relative",
					overflow: "visible",
					// boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.10)",
					filter: "drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.10))",
					borderRadius: "0px 50px 50px 0px",
					backgroundColor:
						projects?.activeProjectUid === project?.uid
							? theme.palette.electricBlue.secondary
							: theme.palette.electricBlue.secondary,
					// color: "white",
				}}
			>
				<Stack
					sx={{
						width: "100%",
						marginLeft: "2.9rem",
						maxHeight: "1.2rem",
					}}
					direction="row"
					justifyContent="space-between"
				>
					<Stack
						direction="row"
						alignItems="center"
						sx={{
							cursor: "pointer",
						}}
						onClick={() => setExpanded(!expanded)}
					>
						<Avatar
							sx={{ "&.MuiAvatar-root": { marginRight: 1 } }}
							variant="sidebarProjectIcon"
							src={ProjectIcon}
						/>
						<Typography variant="sideBarTextActive">{name}</Typography>
					</Stack>
					<Stack
						spacing={1}
						direction="row"
						sx={{
							marginRight: 2,
							alignItems: "center",
							/*
							display: projects?.project?.completedSteps[6]
								? "inherit"
								: "none",
							*/
						}}
					>
						<IconButton onClick={handleContextMenu}>
							<Avatar src={ThreeDotsIcon} variant="threeDotsIcon" />
						</IconButton>
						{/*
						<IconButton
							onClick={() => {
								setExpanded(!expanded);
							}}
						>
							<Avatar
								variant="iconArrows"
								src={ExpandSidebarIcon}
								sx={{
									transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
									":hover": {
										cursor: "pointer",
									},
									display: projects?.project?.completedSteps[6]
										? "inherit"
										: "none",
									transition:
										"transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
								}}
							/>
						</IconButton> */}
					</Stack>
				</Stack>
			</AccordionSummary>
			<AccordionDetails
				elevation={0}
				sx={{
					display: projects?.project?.completedSteps[6] ? "inherit" : "none",
					// maxHeight: "calc(100vh - 230px)",
					padding: "0px",
					// overflowY: "auto",
					// backgroundColor: theme.palette.electricBlue.secondary,
					backgroundColor: "transparent",
					"::-webkit-scrollbar": {
						width: "5px",
						height: "5px",
					},
					"::-webkit-scrollbar-track": {
						background: theme.palette.teal.main,
					},
					"::-webkit-scrollbar-thumb": {
						background: theme.palette.darkBlue.main,
					},
				}}
			>
				<Stepper
					nonLinear
					sx={{ marginLeft: "2.9rem" }}
					orientation="vertical"
					activeStep={project}
					connector={null}
				>
					{tempSteps.map((props, index) => {
						const stepProps = { props };
						return (
							<>
								<Step
									expanded={true}
									key={stepProps.props.id}
									completed={project?.completedSteps[index]}
									{...stepProps}
									sx={{
										display:
											props.hidden || (index === 2 && !project.requireNda)
												? "none"
												: "",
										height: "fit-content",
										"& .MuiStepLabel-vertical": {
											width: "100%",
											height: "fit-content",
										},
										"& .MuiStepConnector-root": {
											display: "none",
										},
										"& .MuiStepLabel-label": {
											color:
												project?.activeStep === index
													? theme.palette.electricBlue.secondary
													: theme.palette.lightBlue.main,
											fontWeight: project?.activeStep === index ? 500 : 400,
											fontSize: "1.2rem",
											"&.Mui-active": {
												color: theme.palette.lightBlue.main,
											},
											"&.Mui-completed": {
												color:
													project?.activeStep === index
														? theme.palette.lightBlue.main
														: "",
											},
										},
										"& .MuiStepLabel-root": {
											padding: 0,
										},

										"& .MuiStepLabel-iconContainer": {
											paddingRight: "1rem",
										},
										"& .MuiStep-root .MuiButtonBase": {
											"& :nth-first-of-type": {
												paddingBottom: 0,
											},
										},
										// paddingBottom: 1,
									}}
									ref={ref}
									// onClick={() => {toggleSidebarMenu()}}
								>
									<StepButton
										disabled={
											index <= project.lastCompletedStep + 1
												? props.disabled
													? props.disabled
													: false
												: true
										}
										onClick={() => {
											setContextMenu(null);
											if (
												(project?.activeStep === index &&
													location.pathname !== "/MyAccount") ||
												props.disabled
											) {
												return;
											}
											if (toggleSidebarMenu) {
												toggleSidebarMenu();
											}
											dispatch(setActiveStep(index));
											dispatch(updateProject(index));
											if (
												location.pathname === "/MyAccount" ||
												location.pathname === "/newProject"
											) {
												navigate(steps[index].url);
											}
										}}
										sx={{
											width: "100%",
											height: "fit-content",
											margin: 0,
										}}
										icon={
											(
												project?.completedSteps
													? project?.completedSteps[index]
													: false
											) ? (
													<Avatar
														src={CompletedStepIcon}
														variant="stepperCompletedIcon"
													/>
												) : project?.activeStep === index ? (
													<Avatar
														src={ActiveStepIcon}
														variant="stepperCompletedIcon"
													/>
												) : (
													<Avatar
														src={NextStepIcon}
														variant="stepperCompletedIcon"
													/>
												)
										}
									>
										<StepLabel>{stepProps.props.text}</StepLabel>
									</StepButton>
									{
										// Unused bc platform selection discarded
										//props.step === 0 && <PlatformsStep />
									}
								</Step>
								{
									// Nda Step vvv
								}
								{index === 3 && project.requireNda && (
									<Step
										expanded={true}
										key={stepProps.props.id}
										completed={project?.completedSteps[2]}
										{...stepProps}
										sx={{
											height: "fit-content",
											"& .MuiStepLabel-vertical": {
												width: "100%",
												height: "fit-content",
											},
											"& .MuiStepConnector-root": {
												display: "none",
											},
											"& .MuiStepLabel-label": {
												color:
													project?.activeStep === 2
														? theme.palette.electricBlue.secondary
														: theme.palette.lightBlue.main,
												fontWeight: project?.activeStep === 2 ? 500 : 400,
												fontSize: "1.2rem",
												"&.Mui-active": {
													color:
														project?.activeStep === 2
															? theme.palette.electricBlue.secondary
															: theme.palette.lightBlue.main,
												},
												"&.Mui-completed": {
													color: theme.palette.lightBlue.main,
												},
											},
											"& .MuiStepLabel-root": {
												padding: 0,
											},

											"& .MuiStepLabel-iconContainer": {
												paddingRight: "1rem",
											},
											"& .MuiStep-root .MuiButtonBase": {
												"& :nth-first-of-type": {
													paddingBottom: 0,
												},
											},
											// paddingBottom: 1,
										}}
										ref={ref}
										// onClick={() => {toggleSidebarMenu()}}
									>
										<StepButton
											disabled={!project.completedSteps[2]}
											onClick={() => {
												setContextMenu(null);
												if (
													(project?.activeStep === 2 &&
														location.pathname !== "/MyAccount") ||
													props.disabled
												) {
													return;
												}
												if (toggleSidebarMenu) {
													toggleSidebarMenu();
												}
												dispatch(setActiveStep(2));
												dispatch(updateProject());
												if (
													location.pathname === "/MyAccount" ||
													location.pathname === "/newProject"
												) {
													navigate(steps[2].url);
												}
											}}
											sx={{
												width: "100%",
												height: "fit-content",
												margin: 0,
											}}
											icon={
												(
													project?.completedSteps
														? project?.completedSteps[2]
														: false
												) ? (
														<Avatar
															src={CompletedStepIcon}
															variant="stepperCompletedIcon"
														/>
													) : project?.activeStep === 2 ? (
														<Avatar src={ActiveStepIcon} variant="stepperIcon" />
													) : (
														<Avatar src={NextStepIcon} variant="stepperIcon" />
													)
											}
										>
											<StepLabel
												sx={{
													maxWidth: "100%",
												}}
											>
												{/* <Typography
													noWrap
													sx={{
														fontSize: "1.2rem",
													}}
												> */}
												{steps[2].text}
												{/* </Typography> */}
											</StepLabel>
										</StepButton>
									</Step>
								)}
							</>
						);
					})}
					{project?.lastCompletedStep < steps.length - 2 && (
						<Step
							sx={{
								"& .MuiStepLabel-root": {
									padding: 0,
								},
							}}
						>
							<Typography
								variant="quoteCartTitle"
								sx={{
									fontSize: "1.3rem",
									color: theme.palette.electricBlue.secondary,
									// paddingTop: 1,
								}}
							>
								Next step
							</Typography>
							<StepButton
								disabled={true}
								sx={{
									width: "100%",
									height: "fit-content",
									margin: 0,
								}}
								icon={<Avatar src={NextStepIcon} variant="stepperIcon" />}
							>
								<StepLabel
									sx={{
										"& .MuiStepLabel-label": {
											fontWeight: 400,
											fontSize: "1.2rem",
										},
									}}
								>
									{/* {steps[project.lastCompletedStep + 1].text} */}
									{steps[project?.lastCompletedStep + 2].hidden
										? steps[project?.lastCompletedStep + 3].text
										: steps[project?.lastCompletedStep + 2].text}
								</StepLabel>
							</StepButton>
						</Step>
					)}
				</Stepper>
			</AccordionDetails>
			<ReusableModal
				dialogProps={{
					open: openNameChangeModal,
					onClose: () => {
						setOpenNameChangeModal(false);
					},
				}}
			>
				<DialogTitle>Change Name?</DialogTitle>
				<DialogContent>
					<Stack component="form" onSubmit={handleSubmit(handleNameChange)}>
						<Typography>New name</Typography>
						<TextField
							{...register("projectName")}
							name="projectName"
							error={errors.projectName}
							helperText={errors.projectName ? errors.projectName.message : " "}
						/>
						<Button type="submit">Save</Button>
					</Stack>
				</DialogContent>
			</ReusableModal>
			<Menu
				open={contextMenu !== null}
				sx={{
					"& .MuiPaper-root": {
						top: 234,
						left: 129,
						borderRadius: 3,
						background: "rgba(17, 32, 49, 0.32)",
						backdropFilter: "blur(2px)",
						padding: "12px 19px 15px 19px",
						"& .MuiList-root": {
							padding: "0px",
							color: "#fff",
							"& .MuiMenuItem-root": {
								paddingLeft: "0px",
								paddingRight: "0px",
								// paddingTop: "10px",
								paddingTop: "0px",
								// paddingBottom: "10px",
								paddingBottom: "0px",
								":hover": {
									backgroundColor: "inherit",
								},
								/*
								":nth-of-type(1)": {
									paddingTop: "0px",
									paddingBottom: "10px",
								},
								":nth-last-of-type(1)": {
									paddingTop: "10px",
									paddingBottom: "0px",
								}, */
							},
						},
						boxShadow: "none",
					},
				}}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
						? { top: contextMenu.mouseY, left: contextMenu.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => setOpenNameChangeModal(true)}>Rename</MenuItem>
				{/* <MenuItem onClick={handleClose}>Archive project</MenuItem> */}
				{/* <MenuItem onClick={handleClose}>Delete project</MenuItem> */}
			</Menu>
		</Accordion>
	);
}
