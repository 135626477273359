import { Avatar } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ProjectIconAlt from "../../Sources/Icons/ProjectIconAlt.svg";
// import ThreeDotsIcon from "../../Sources/Icons/ThreeDotsIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "@mui/material/styles/useTheme";
import {
	/* setActiveProjectById, */ updateSelectedProject,
} from "../../features/project/projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { steps } from "../../hooks/utils";

export default function InactiveProject({ project }) {
	const projects = useSelector((state) => state.projectsRedux);
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();

	function handleActiveProject() {
		//todo[x] update database
		dispatch(updateSelectedProject(project));
		if (location.pathname === "/MyAccount") {
			navigate(steps[projects?.project?.activeStep].url);
		}
	}

	return (
		<Stack
			sx={{
				width: "100%",
				paddingLeft: "2.9rem",
				display: project.uid === projects.activeProjectUid ? "none" : "flex",
				paddingY: "1.5rem",
			}}
			direction="row"
			justifyContent="space-between"
			spacing={1}
		>
			<Stack
				direction="row"
				alignItems="center"
				//todo[x] also update database
				onClick={() => {
					handleActiveProject();
				}}
				sx={{
					cursor: "pointer",
				}}
			>
				<Avatar
					sx={{ "&.MuiAvatar-root": { marginRight: 1 } }}
					variant="sidebarProjectIcon"
					src={ProjectIconAlt}
				/>
				<Typography
					variant="sideBarTextActive"
					sx={{
						color: useTheme().palette.electricBlue.secondary,
					}}
				>
					{project.projectName}
				</Typography>
			</Stack>
			{/* 			
			<Stack
				spacing={2}
				direction="row"
				sx={{
					marginRight: 4,
				}}
			>
				<Avatar src={ThreeDotsIcon} variant="iconAvatar" />
				<Avatar
					variant="iconArrows"
					src={ThreeDotsIcon}
					sx={{
						visibility: "hidden",
					}}
				/>
			</Stack> */}
		</Stack>
	);
}
