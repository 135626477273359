import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
	constructPrice,
	constructShortDate,
	constructTime,
} from "../../../hooks/localeString";

export default function PaymentsTable({ payments }) {
	function convertPaymentStatus(payment) {
		const { status } = payment;
		switch (status) {
		case -1:
			return "Not Started";
		case 0:
			return "developing";
		case 1:
			return "Testing";
		case 2:
			return "In aproval";
		case 3:
			return "Completed";
		default:
			return "Unknown";
		}
	}
	return (
		<TableContainer>
			<Table
				aria-label="collapsible table"
				sx={{
					borderCollapse: "separate",
					paddingBottom: "20px",
					"&.MuiTable-root": {
						backgroundColor: "#ffffffcc",
						backdropFilter: "blur(10px)",
					},
				}}
				stickyHeader
			>
				<TableHead>
					<TableRow
						sx={{
							"&.MuiTableRow-root": {
								th: {
									":first-of-type": {
										borderTopLeftRadius: "10px",
									},
									":last-of-type": {
										borderTopRightRadius: "10px",
									},
								},
							},
						}}
					>
						<TableCell className="table-header-text">Project name</TableCell>
						<TableCell className="table-header-text" align="left">
							Ticket name
						</TableCell>
						<TableCell className="table-header-text" align="left">
							Amount
						</TableCell>
						<TableCell className="table-header-text" align="left">
							Payment date
						</TableCell>
						<TableCell className="table-header-text" align="left">
							Project status
						</TableCell>
						<TableCell className="table-header-text" align="left">
							Invoice (paid)
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{payments.map((payment) => (
						<>
							<TableRow
								key={payment.uuid}
								sx={{
									"&.MuiTableRow-root": {
										":last-of-type": {
											td: {
												borderBottom: "1px solid #94a3ca",
												":first-of-type": {
													borderBottomLeftRadius: "10px",
												},
												":last-of-type": {
													borderBottomRightRadius: "10px",
												},
											},
										},
									},
								}}
							>
								<TableCell className="table-cell-text" align="left">
									{payment.project.projectName}
								</TableCell>
								{/* 					<TableCell className="table-cell-text" align="left">
										{row.tasks ? row.tasks.length + " tasks" : "0 tasks"}{" "}
									</TableCell> */}

								<TableCell className="table-cell-text" align="left">
									{payment.title ?? "undefined"}
								</TableCell>
								<TableCell className="table-cell-text" align="left">
									{constructPrice(payment.price / 100) ?? "undefined"}
								</TableCell>
								<TableCell className="table-cell-text" align="left">
									{`${constructShortDate(
										payment?.payment.payedAt?._seconds
									)} at ${constructTime(payment?.payment.payedAt?._seconds)}` ??
										"undefined"}
								</TableCell>
								<TableCell className="table-cell-text" align="left">
									{convertPaymentStatus(payment)}
								</TableCell>
								<TableCell className="table-cell-text" align="left">
									<img
										src="https://cdn-icons-png.flaticon.com/512/955/955979.png"
										alt=""
									/>
								</TableCell>
							</TableRow>
						</>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
