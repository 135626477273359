import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { ReusableAccordion } from "../templates/Accordions";
import taskIcon from "../../Sources/Icons/TaskIcon.svg";
import quoteBottom from "../../Sources/images/quoteBottom.svg";

export default function PayedItemMobile({ ticket, index }) {
	const theme = useTheme();

	function QuoteItemMobile() {
		const tratedPrice = (ticket.price / 100).toLocaleString("en-us", {
			style: "currency",
			currency: "USD",
		});

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: theme.spacing(0, 4, 5, 4),
					width: "100%",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "bottom",
					backgroundColor: "#ffffffcc",
					backdropFilter: "blur(20px)",
					position: "relative",
					minHeight: "fit-content",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography sx={{ textAlign: "start" }} variant="taskDescription">
						{ticket["description"]}
					</Typography>
					<Box marginBottom={1} marginTop={1}>
						<img
							style={{
								height: "24px",
								opacity: "50%",
								marginRight: "10px",
							}}
							src={ticket.platform.icons?.inactive}
							className="image-platform"
							alt="platform"
						/>
					</Box>
				</Box>
				<Box
					sx={{
						position: "relative",
						minheight: "150px",
						maxHeight: "150px",
						background: theme.palette.electricBlue.secondary,
						borderRadius: "15px",
						padding: "15px",
						justifyContent: "space-between",
						height: "fit-content",
					}}
				>
					<Stack>
						<Typography variant="quoteCartTitle">
							Estimated time:&nbsp;
							<Typography variant="quoteCartContent">
								{ticket["estimatedTime"]}
							</Typography>
						</Typography>
						<Typography variant="quoteCartTitle">
							Price:&nbsp;
							<Typography variant="quoteCartContent">{tratedPrice}</Typography>
						</Typography>
					</Stack>
					<Stack sx={{ alignSelf: "flex-end" }}>
						<Typography variant="quoteCartPrice">Paid</Typography>
					</Stack>
				</Box>

				<img
					draggable={false}
					src={quoteBottom}
					className="img-quote-bottom-mobile"
					alt="circles"
				/>
			</Box>
		);
	}

	function QuoteHeaderColapsed() {
		return (
			<Stack
				spacing={2}
				direction={"row"}
				sx={{
					paddingY: 2,
				}}
			>
				<Avatar variant="iconTask" src={taskIcon} />
				<Typography variant="calendarMonth">Task &#35;{index + 1}</Typography>
			</Stack>
		);
	}

	function QuoteHeaderExpanded() {
		return (
			<Stack
				spacing={2}
				direction={"row"}
				sx={{ paddingY: 2, borderRadius: "15px" }}
			>
				<Avatar variant="iconTask" src={taskIcon} />
				<Stack
					justifyContent={"flex-start"}
					height={"100%"}
					marginBottom={"auto"}
				>
					<Typography variant="calendarMonth">Task &#35;{index + 1}</Typography>
					<Typography variant="taskDescription" fontWeight="400">
						{ticket.title}
					</Typography>
				</Stack>
			</Stack>
		);
	}

	return (
		<ReusableAccordion
			hideIcon={true}
			expandedheader={QuoteHeaderExpanded()}
			colapsedHeader={QuoteHeaderColapsed()}
			details={QuoteItemMobile()}
		/>
	);
}
