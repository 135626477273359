import { OutlinedInput as MuiOutlinedInput, styled } from "@mui/material";

export const OutlinedInput = styled((props) => <MuiOutlinedInput {...props} />)(
	() => ({
		fontSize: "1.2rem",
		backgroundColor: "#FDFDFD",
		color: "#657A9A",
		"& .MuiOutlinedInput-input::placeholder": {
			color: "#657A9A",
			fontWeight: "200",
		},
		"& .MuiOutlinedInput-input": {
			alignSelf: "flex-start",
		},
		"&.MuiInputBase-root.MuiOutlinedInput-root": {
			"& .Mui-disabled": {
				backgroundColor: "#FDFDFD",
				color: "#657A9A",
				"-webkit-text-fill-color": "#657A9A",
			},
			paddingTop: "5px",
			borderRadius: 0,
			"& fieldset": {
				borderColor: "#F1F1F1",
				":hover": {
					borderColor: "#F1F1F1",
				},
			},
		},
		paddingBottom: 0,
		minHeight: "12rem",
	})
);
