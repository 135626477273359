import { useSelector } from "react-redux";
import { Invoice } from "../../components/Payment/Invoice";
import LoadingGeneric from "../../components/BackdropLoading";

export default function InvoicePage() {
	const projects = useSelector((state) => state.projectsRedux);

	if (projects?.project?.uid === undefined) {
		return <LoadingGeneric />;
	} else {
		return <>{Object.keys(projects?.project).length > 0 && <Invoice />}</>;
	}
}
