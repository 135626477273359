/**
 * Constructs a sort date string from a Unix timestamp.
 * @param {number} timestamp - The Unix timestamp to construct the short date string from.
 * @returns {string} The long date string in the format "MM/DD/YY".
 */
export function constructShortDate(timestamp) {
	const date = new Date(timestamp * 1000);
	return date.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "2-digit",
	});
}

/**
 * Constructs a long date string from a Unix timestamp.
 * @param {number} timestamp - The Unix timestamp to construct the long date string from.
 * @returns {string} The long date string in the format "Month Day, Year".
 */
export function constructLongDate(timestamp) {
	const date = new Date(timestamp * 1000);
	return date.toLocaleDateString("en-US", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
}

/**
 * Constructs a time string from a Unix timestamp.
 * @param {number} timestamp - The Unix timestamp to construct the time string from.
 * @returns {string} The time string in the format "HH:MM".
 */
export function constructTime(timestamp) {
	const date = new Date(timestamp * 1000);
	return date.toLocaleTimeString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	});
}
/**
 * Constructs a formatted price string from a numeric value, example: 1000 -> $1,000.00.
 * @param {number} price - The price to format.
 * @returns {string} The formatted price string.
 */
export function constructPrice(price) {
	if (!price) return "$0.00";
	return `${price.toLocaleString("en-us", {
		style: "currency",
		currency: "USD",
	})}`;
}
