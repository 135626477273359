import React from "react";
import { quotes } from "./datosPrueba";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import "./cardsApprove.css";

// const ApproveButton = (props) => {
//   const {loadingButton, onclick} = props;
//   return (
//     <Button variant="contained" color="primary" onClick={onclick} disabled={loadingButton}>
//       { loadingButton && <CircularProgress size={14} />}
//       {!loadingButton && 'Approve'}
//     </Button>
//   )
// }

export const CardsApprove = () => {
	const [filterQuotes, setFilterQuotes] = React.useState([]);
	// const [loadingButton, setloadingButton] = useState(false);

	const filterArray = () => {
		const disapprove = false;
		const newQuoteArray = quotes.filter((obj) => {
			return Object.values(obj).indexOf(disapprove) > -1;
		});
		setFilterQuotes(newQuoteArray);
	};

	// const handleClick = () => {
	//   setloadingButton(true);
	//   setTimeout(() => setloadingButton(false), 1500)
	// }

	React.useEffect(() => {
		filterArray();
	}, []);

	return (
		<div>
			<Grid
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				container
				spacing={2}
				style={{ paddingLeft: "13%", paddingRight: "13%", paddingTop: "1%" }}
			>
				<Grid item xs={3}>
					<Typography variant="h5" color="initial">
						<b>Ready to approve</b>
					</Typography>
				</Grid>
			</Grid>
			<Grid
				direction="row"
				justifyContent="center"
				alignItems="center"
				container
				spacing={1}
				className="containerApprove"
			>
				{filterQuotes.map((quote, index) => {
					return (
						<Grid
							style={{ paddingBottom: "1%" }}
							key={quote.id}
							item
							xs={10}
							sm={10}
							md={10}
							lg={9}
							xl={9}
						>
							<Card>
								<CardContent>
									<section>
										<Typography variant="subtitle1" color="initial">
											<b>Requirement</b> {index + 1})
										</Typography>
										<Typography variant="subtitle1" color="initial">
											<b>Title</b> {quote.title}
										</Typography>
										<Typography variant="subtitle1" color="initial">
											<b>Description</b>
										</Typography>
										<Grid style={{ paddingTop: "1%" }} container spacing={3}>
											<Grid item xs={12} sm={12} md={12} lg={3}>
												<div>
													<Typography variant="subtitle1" color="initial">
														{quote.description}
													</Typography>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={9}>
												<div>
													<div>
														<Typography variant="subtitle1" color="initial">
															Platform(s):
															{quote.platforms.map((platform, index) => {
																return (
																	<Typography
																		key={index}
																		variant="subtitle1"
																		color="initial"
																	>
																		{platform}
																	</Typography>
																);
															})}
														</Typography>
													</div>
													<Typography variant="subtitle1" color="initial">
														Type: {quote.type}
													</Typography>
													<Typography variant="subtitle1" color="initial">
														Intgration(s): {quote.integrations}
													</Typography>
												</div>
											</Grid>
										</Grid>
										<div style={{ paddingTop: "1%" }}>
											{}
											<Typography variant="subtitle1" color="initial">
												Aditional Work:
											</Typography>
											<Typography variant="subtitle1" color="initial">
												{quote.aditionaWork}
											</Typography>
										</div>
										<Grid
											direction="row"
											justifyContent="center"
											alignItems="center"
											container
											spacing={1}
											style={{ paddingTop: "1%" }}
										>
											<Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
												<Typography variant="subtitle1" color="initial">
													Review <ins>Here</ins>
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
												<Button variant="contained" color="primary">
													{/* onClick={() => quote.isApprove = true}  */}
													Approve
												</Button>
												{/* <ApproveButton onclick={handleClick} loadingButton={loadingButton} /> */}
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
												<Typography variant="subtitle1" color="initial">
													Migrating to production...
												</Typography>
											</Grid>
										</Grid>
									</section>
								</CardContent>
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};
