import React from "react";

// import { TransitionGroup } from "react-transition-group";

// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
// import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { TaskHeaderMobile } from "./taskHeaderMobile";
import { TaskHeaderDesktop } from "./taskHeaderDesktop";
import { useMediaQuery, useTheme } from "@mui/material";

// import WorkDescriptionAttachment from "../../Sources/Icons/WorkDescriptionAttachment.svg";
// import DeleteAttachment from "../../Sources/Icons/DeleteAttachment.svg";
// import ExpandTaskIcon from "../../Sources/Icons/ExpandTaskIcon.svg";

import PreviewAttachements from "./previewAttachments";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../templates/taskAccordion";
import { OutlinedInput } from "../templates/inputs/textFields";

/* const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderRadius: "15px",
	backgroundColor: "#ffffffcc",
	overflow: "hidden",
	border: `1px solid ${theme.palette.electricBlue.main}`,
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(0deg)",
	},
	"& .MuiAccordionSummary-root.Mui-focusVisible": {
		background: "transparent",
	},

	"&:before": {
		display: "none",
	},
	"& .MuiButtonBase-root.MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
		cursor: "default",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		borderRadius: "15px",
		background: "transparent",
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(0),
		},
		"& .MuiAccordionSummary-root:hover": {
			pointer: "cursor",
		},
		boxSizing: "border-box",
	})
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	background: "transparent",

	borderRadius: "0 0 15px 15px",
	boxSizing: "border-box",
})); */
export default function SendedTask({ task, index }) {
	// const auth = useSelector((state) => state.auth);
	// const projects = useSelector((state) => state.projectsRedux);

	// const fileRef = React.useRef();

	const theme = useTheme();

	const [expanded /* setExpanded */] = React.useState(true);

	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Accordion expanded={expanded}>
			<AccordionSummary
				sx={{
					"& .MuiAccordionSummary-expandIconWrapper": {
						alignSelf: isMobile ? "flex-start" : "center",
						paddingTop: isMobile ? 1 : 0,
					},
				}}
				/* 
				expandIcon={
					<Stack spacing={1} direction="row" sx={{}}>
						<IconButton
							onClick={() => {
								setExpanded(!expanded);
							}}
							disableRipple
						>
							<Avatar
								sx={{
									transform: expanded ? "rotate(0deg)" : "rotate(-180deg)",
									transition:
										"transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
									":hover": {
										cursor: "pointer",
									},
								}}
								variant="iconArrows"
								src={ExpandTaskIcon}
							/>
						</IconButton>
												
						<IconButton disableRipple disabled={true}>
							<Avatar
								sx={{
									cursor: "pointer",
								}}
								variant="iconArrows"
								src={DeleteAttachment}
								value={index}
							/>
						</IconButton>
						
					</Stack>
				}
				 */
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{isMobile ? (
					<TaskHeaderMobile
						index={index + 1}
						expanded={expanded}
						taskName={task.title}
						disabled={true}
					/>
				) : (
					<TaskHeaderDesktop
						index={index + 1}
						expanded={expanded}
						taskName={task.title}
						disabled={true}
					/>
				)}
			</AccordionSummary>
			<AccordionDetails>
				<FormControl
					sx={{ paddingBottom: task?.attachments?.length > 0 ? 1 : 0 }}
					fullWidth
				>
					<OutlinedInput
						id={"inputDescription-" + index}
						name={task.description}
						placeholder="Describe your task..."
						multiline
						minRows={8}
						maxRows={25}
						disabled={true}
						value={task.description}
					/>
				</FormControl>
				{/* Change to preview */}
				{task?.attachments?.length > 0 && (
					<Stack sx={{ marginLeft: "4px" }}>
						<Typography variant="descriptiveText">Attachments</Typography>
						<Stack
							direction="row"
							sx={{
								maxWidth: "100%",
								overflowX: "auto",
								overflowY: "hidden",
								height: "200px",
							}}
							spacing={1}
						>
							{task?.attachments?.map((attachment, index) => (
								<PreviewAttachements key={index} media={attachment.fileName} />
							))}
						</Stack>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
}
