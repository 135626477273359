//! React imports
import React from "react";

//! Firebase imports
// import { db } from "../../../firebase";
// import { onSnapshot, query, collection } from "firebase/firestore";

//! MUI imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
	DataGridPremium,
	useGridApiRef,
	useKeepGroupedColumnsHidden,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { Typography, Avatar, Chip } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FilterIcon from "@mui/icons-material/Filter";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";

//! Custom imports
import { toTicketTableModel } from "./TicketsTableModel";

const columns = [
	{
		field: "projectName",
		headerName: "Project",
		width: 200,
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "taskTitle",
		headerName: "Task Name",
		width: 150,
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "deadLine",
		headerName: "Deadline",
		width: 175,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;

			//TODO[] Variar el color segun la fecha
			return (
				<Chip
					label={
						<Box>
							{new Date(params.value * 1000).toLocaleDateString() +
								" - " +
								new Date(params.value * 1000).toTimeString().split(" ")[0]}
						</Box>
					}
					variant="outlined"
					sx={{ borderColor: "#05bf13", borderWidth: 2, width: "100%" }}
				/>
			);
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "messages",
		headerName: "Messages",
		width: 150,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;
			return (
				<>
					<ChatBubbleOutlineIcon htmlColor="#285D69" />
					<Box sx={{ width: "5px" }} />
					<Typography variant="body2">{params.value}</Typography>
				</>
			);
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "clientName",
		headerName: "Client name",
		width: 150,
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "platforms",
		headerName: "Platform(s)",
		width: 150,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;
			if (params.value.length === 0)
				return <Typography variant="body2">No platforms</Typography>;
			const icons = params.value.map((p) => {
				return (
					<Box key={p.key}>
						{/* <Typography sx={{display: {xl: "none"}}}>{p.name}</Typography> */}
						<img
							src={p.icon}
							style={{ width: "20px", height: "20px" }}
							alt="icon"
						/>
					</Box>
				);
			});
			return icons;
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "taskWriter",
		headerName: "Task Writer",
		width: 150,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;
			return (
				<>
					<Avatar
						alt={params.value.name}
						src={params.value.avatarUrl}
						key={params.value.key}
						sx={{ width: 24, height: 24 }}
					/>
					<Box sx={{ width: "5px" }} />
					<Typography variant="body2">{params.value.name}</Typography>
				</>
			);
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "taskDescription",
		headerName: "Description",
		width: 200,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;
			// return <Box sx={{width: '100%', backgroundColor: "white",border: 1, padding: "10px 10px", borderRadius: "5px" ,overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
			//   <Typography variant="body2">{params.value}</Typography>
			// </Box>
			return <DescriptionPopover description={params.value} />;
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "media",
		headerName: "Media",
		width: 150,
		renderCell: (params) => {
			if (params.value === undefined) return <></>;
			return (
				<ImagesPopoverButton
					label={params.value.length}
					images={params.value}
				/>
			);
		},
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 150,
		renderHeader: (params) => {
			return (
				<Typography variant="gridHeaders">
					{params.colDef.headerName}
				</Typography>
			);
		},
	},
];

export default function DataGridComponent({ tickets }) {
	const [rows, setRows] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		loadData(tickets);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(tickets)]);

	const loadData = async (tickets) => {
		const ts = tickets.map((ticket) => {
			// El modelo se llena asi, ya como lo van a llenar es lo que puede cambiar

			const t = JSON.stringify({
				id: ticket.id,
				projectName: ticket.project?.name ?? "No project",
				taskTitle: ticket.title ?? "No task title",
				deadLine: ticket.deadLine?.seconds ?? 0,
				messages: ticket.messages ?? 0,
				clientName: ticket.project?.clientName ?? "No client",
				taskWriter: ticket.taskWriter ?? {
					key: crypto.randomUUID(),
					avatarUrl: "",
					name: "No task writer",
				},
				platforms:
					ticket.platforms === undefined
						? []
						: ticket.platforms.map((p) => {
							return {
								key: crypto.randomUUID(), //key, para dibujar el icono sin que react se vuelva loco xd
								icon: p.icons?.activeIcon ?? "No icon", //icon url
								name: p.name ?? "Unknown platform", //icon label
							};
						  }),
				taskDescription: ticket.taskDescription ?? "",
				media:
					ticket.media === undefined
						? [
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/300/200",
							},
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/500/500",
							},
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/100/400",
							},
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/300/200",
							},
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/500/500",
							},
							{
								key: crypto.randomUUID(),
								media: "https://picsum.photos/100/400",
							},
						  ]
						: ticket.media.map((p) => {
							return {
								key: crypto.randomUUID(), //key, para dibujar el icono sin que react se vuelva loco xd
								media: p.media, //media url
							};
						  }), //* cambiar
				// status: ticket.status ?? 'No status',
				status: "PENDING", //TODO[] Inferir el estado del ticket segun el estado de los nodos ????
			});
			return toTicketTableModel(t);
		});

		if (ts.length > 0) setLoading(false);

		setRows(ts);
	};

	const apiRef = useGridApiRef();
	const initialState = useKeepGroupedColumnsHidden({
		apiRef,
		initialState: {
			columns: {
				columnVisibilityModel: {
					// Hide the column used for leaves
					projectName: false,
					status: false,
				},
			},
			_rowGrouping: {
				model: ["status"],
			},
			get rowGrouping() {
				return this._rowGrouping;
			},
			set rowGrouping(value) {
				this._rowGrouping = value;
			},
			pagination: {
				page: 1,
			},
		},
	});

	return (
		<Box
			sx={{
				height: 800,
				marginTop: "7.5%",
				width: "100%",
			}}
		>
			{/* <StyledDataGrid */}
			<StyledDataGrid
				rows={rows}
				columns={columns}
				loading={loading}
				apiRef={apiRef}
				hideFooter={true}
				// rowGroupingColumnMode="multiple"
				groupingColDef={{
					renderHeader: (params) => {
						return (
							<Typography variant="gridHeaders">
								{params.colDef.headerName}
							</Typography>
						);
					},
					hideDescendantCount: true,
					headerName: "Status",
					valueFormatter: (valueFormatterParams) => {
						const rowNode = apiRef.current.getRowNode(valueFormatterParams.id);

						if (rowNode?.groupingField === "Group") {
							return `by ${rowNode.groupingKey ?? ""}`;
						}
						return undefined;
					},
				}}
				defaultGroupingExpansionDepth={-1}
				components={{
					Toolbar: GridToolbarQuickFilter,
				}}
				componentsProps={{
					toolbar: {
						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
					},
				}}
				// rowReordering={true}
				initialState={initialState}
			/>
		</Box>
	);
}

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
	border: 0,
	color:
		theme.palette.mode === "light"
			? "rgba(0,0,0,.85)"
			: "rgba(255,255,255,0.85)",
	WebkitFontSmoothing: "auto",
	letterSpacing: "normal",
	"& .MuiDataGrid-columnsContainer": {
		backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
	},
	"& .MuiDataGrid-iconSeparator": {
		display: "block",
	},
	"& .MuiDataGrid-columnHeaders": {
		borderBottom: `0px solid ${
			theme.palette.mode === "light" ? "#c0c0c0" : "#303030"
		}`,
	},
	"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
		borderRight: `0px solid ${
			theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
		}`,
		borderBottom: `0px solid ${
			theme.palette.mode === "light" ? "#c0c0c0" : "#303030"
		}`,
		color:
			theme.palette.mode === "light"
				? "rgba(0,0,0,.85)"
				: "rgba(255,255,255,0.65)",
	},
	"& .MuiPaginationItem-root": {
		borderRadius: 0,
	},
	// ...customCheckbox(theme),
}));

function ImagesPopoverButton({ label, images, buttons }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	// const id = open ? 'simple-popover' : undefined;

	return (
		<Box>
			<Button variant="text" onClick={handleClick}>
				<FilterIcon htmlColor="#285D69" />
				<Box sx={{ width: "5px" }} />
				<Typography variant="body2" sx={{ color: "#000" }}>
					{label ?? 0}
				</Typography>
			</Button>
			<Popover
				// id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				variant="modalPopoverPaper"
			>
				<Box
					sx={{
						overflowX: "auto",
						overflowY: "hidden",
						display: "flex",
						maxWidth: "50vw",
					}}
				>
					{images.map((image) => {
						return (
							<Box key={image.key}>
								<img
									src={image.media}
									height={300}
									loading={"lazy"}
									style={{ marginRight: 10, contain: "" }}
									alt={image.key}
								/>
							</Box>
						);
					})}
					{/* <ImageList sx={{ height: '300px' }} cols={images.length} variant="" gap={10}>
          {images.map((image) => (
            <ImageListItem key={image.key} >
              <img src={`${image.media}?h=300&fit=contain`} />
            </ImageListItem>
          ))}
        </ImageList> */}
				</Box>
				<Typography
					variant="descriptiveText"
					sx={{ placeSelf: "center", color: "#112031", fontWeight: "200" }}
				></Typography>
				{buttons}
			</Popover>
		</Box>
	);
}

function DescriptionPopover({ description }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		if (description === undefined || description === null || description === "")
			return;
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Box
			sx={{
				width: "100%",
				backgroundColor: "white",
				border: 1,
				padding: "10px 10px",
				borderRadius: "5px",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}}
			onMouseEnter={handlePopoverOpen}
		>
			<Typography
				aria-owns={open ? "mouse-over-popover" : undefined}
				aria-haspopup="true"
			>
				{description === undefined || description === null || description === ""
					? "No task description..."
					: description}
			</Typography>
			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onBackdropClick={handlePopoverClose} //para movil xd
			>
				<Box
					sx={{ p: 1, maxWidth: "30vw", maxHeight: "50vh" }}
					onMouseLeave={handlePopoverClose}
				>
					<Typography>
						{description === undefined ||
						description === null ||
						description === ""
							? "No task description..."
							: description}
						{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
					</Typography>
				</Box>
			</Popover>
		</Box>
	);
}
