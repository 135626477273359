import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../backend/services";
import { updateBillingAddress } from "../../hooks/payment";
/* 
	import { persistor } from "../../Store";
	persistor.purge();

*/

const initialState = {
	user: {},
	authed: false,
	isLoading: false,
	error: null,
	token: null,
};

export const fetchContent = createAsyncThunk(
	"content/fetchContent",
	async () => {
		const res = await get({
			route: "platforms/getPlatforms",
		});
		const data = res.platforms;
		return data;
	}
);

export const UpdateBillingAddress = createAsyncThunk(
	"content/UpdateBillingAddress",
	async (data, { getState }) => {
		const state = getState();
		await updateBillingAddress(state.auth.user.uid, data, state.auth.token);
		return data;
	}
);

export const authSlice = createSlice({
	name: "Auth",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.authed = true;
		},
		updateUser: (state, action) => {
			state.user = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setActiveProject: (state, action) => {
			state.user.activeProject = action.payload;
		},
		logout: (state) => {
			state.user = {};
			state.authed = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchContent.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchContent.fulfilled, (state, action) => {
			state.isLoading = false;
			state.contents = action.payload;
		});
		builder.addCase(fetchContent.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
		builder.addCase(UpdateBillingAddress.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(UpdateBillingAddress.fulfilled, (state, action) => {
			state.isLoading = false;
			state.user.billingAddress = action.payload;
		});
		builder.addCase(UpdateBillingAddress.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
	},
});

export const { setUser, logout, updateUser, setActiveProject, setToken } =
	authSlice.actions;

export default authSlice.reducer;
