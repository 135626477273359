import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import { TransitionGroup } from "react-transition-group";
import { v4 as uuid } from "uuid";

import { previewComponent } from "./previewComponent";
import { InputContainer } from "../../templates/chatComponents";
import { FileContext } from "../../../Context/FileContext";

import AttachmentIcon from "../../../Sources/Icons/AttachmentIcon.svg";
import EmojiIcon from "../../../Sources/Icons/EmojiIcon.svg";
import SendIcon from "../../../Sources/Icons/SendIcon.svg";

import React from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../../firebase";
import { sendMediaMessageUtil, sendMessageUtil } from "../../../hooks/chat";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { ChatContext } from "../../../Context/ChatContext";
import EmojiPicker from "emoji-picker-react";

export default function ChatInput(props) {
	const { fileData, dispatchFile } = React.useContext(FileContext);
	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);
	const { chatData } = React.useContext(ChatContext);
	console.log("🚀 ~ file: chatInput.js:34 ~ ChatInput ~ chatData:", chatData);

	const [text, setText] = React.useState("");
	const [chosenEmojiAnchor, setAncorChosenEmoji] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const emojiDialog = Boolean(chosenEmojiAnchor);

	async function sendMessage(e) {
		e.preventDefault();
		setLoading(true);
		if (text.length === 0 && fileData.files.length === 0) {
			alert("please enter a valid message");
			return;
		}
		if (fileData.files.length > 0) {
			fileData.files.forEach((obj) => {
				const imgDate = new Date();
				const imgDateStr =
					imgDate.getFullYear().toString().slice(2) +
					"-" +
					(imgDate.getMonth() + 1) +
					"-" +
					imgDate.getDate() +
					"_" +
					imgDate.getHours() +
					"-" +
					imgDate.getMinutes() +
					"-";
				const storageRef = ref(
					storage,
					`projects/${projects.project?.uid}/chat/${imgDateStr}_${obj.id}_${obj.file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, obj.file);
				uploadTask.on(
					"state_changed",
					(snapshot) => {
						const progressObj =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

						const findObj = fileData.prevFiles.findIndex((file) => {
							return file.id === obj.id;
						});
						const newFiles = { ...fileData };
						newFiles.prevFiles[findObj].progress = progressObj;

						dispatchFile({
							type: "UPDATE_PROGRESS",
							newFiles,
						});
						switch (snapshot.state) {
						case "paused":
							break;
						case "running":
							break;
						default:
						}
					},
					(error) => {
						console.error(error);
						//TODO[] Handle Error
					},
					async () => {
						const imgRef = `projects/${projects.project?.uid}/chat/${imgDateStr}_${obj.id}_${obj.file.name}`;
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						await sendMediaMessages(downloadURL, imgRef);
					}
				);
			});
			document.getElementById("file").value = "";
		} else {
			try {
				await sendMessageUtil(
					text,
					projects.project.uid,
					auth.user.uid,
					chatData.ChatID,
					auth.user.displayName,
					projects.project.projectName,
					auth.token,
					auth.user?.email,
					auth.user?.photoURL,
					chatData.ClientActiveChat
				);
			} catch (e) {
				console.error(e);
			}
		}
		try {
			await updateDoc(doc(db, "userChats", projects.project.uid), {
				[chatData.ChatID + ".lastMessage"]: {
					text,
				},
				[chatData.ChatID + ".date"]: serverTimestamp(),
			});
		} catch (e) {
			console.error("error");
			console.error(e);
		}
		try {
			await updateDoc(doc(db, "userChats", chatData.ClientActiveChat), {
				[chatData.ChatID + ".lastMessage"]: {
					text,
				},
				[chatData.ChatID + ".date"]: serverTimestamp(),
			});
		} catch (e) {
			console.error("error");
			console.error(e);
		}
		setLoading(false);
		setText("");
	}
	async function sendMediaMessages(downloadURL, imgRef) {
		await sendMediaMessageUtil(
			text,
			downloadURL,
			projects.project.uid,
			auth.user?.uid,
			chatData.ChatID,
			imgRef,
			auth.user.displayName,
			projects.project?.projectName,
			auth.token,
			auth.user?.email,
			auth.user?.photoURL,
			chatData.ClientActiveChat
		);
		dispatchFile({ type: "REMOVE_ALL_FILES" });
	}

	const fileRef = React.useRef();
	function previewImg(e) {
		const fileID = { file: e.target.files[0], id: uuid() };

		dispatchFile({
			type: "ADD_FILES",
			payload: {
				fle: fileID,
				pfle: {
					preview: URL.createObjectURL(fileID.file),
					id: fileID.id,
					progress: 0,
				},
			},
		});
	}
	return (
		<Stack>
			<Menu
				open={emojiDialog}
				onClose={() => setAncorChosenEmoji(null)}
				id="emoji-menu"
				anchorEl={chosenEmojiAnchor}
			>
				<EmojiPicker
					onEmojiClick={(emoji) => {
						setText(...text, ` ${emoji.emoji}`);
					}}
				/>
			</Menu>
			<TransitionGroup>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						backgroundColor: "whiteSmoke",
						marginX: "8px",
						height: fileData.prevFiles.length > 0 ? "150px" : "0px",
						transition: "height 0.2s",
						zIndex: 0,
						overflowX: "auto",
					}}
				>
					{fileData?.prevFiles?.map((file) => (
						<Grow key={file?.id} in={true} out={true}>
							{previewComponent({ file })}
						</Grow>
					))}
				</Box>
			</TransitionGroup>
			{!props?.questionnarie ? (
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					component={"form"}
					onSubmit={sendMessage}
					sx={{
						bottom: 0,
						borderRadius: "0 0 15px 15px",
						height: "100%",
						marginX: "8px",
						backgroundColor: "white",
						marginBottom: "8px",
						padding: "5px 8px 8px 8px",
						zIndex: 1,
					}}
					overflow={"hidden"}
				>
					<InputContainer>
						<InputBase
							sx={{
								paddingLeft: 1,
								boxShadow: "none",
								"& .MuiInputBase-input": {
									fontSize: "14px",
								},
							}}
							disabled={loading}
							multiline
							maxRows={2}
							fullWidth
							value={text}
							placeholder={"Your message..."}
							onChange={(e) => {
								setText(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									sendMessage(e);
								}
							}}
						/>
						<IconButton
							id="emoji-button"
							aria-controls={emojiDialog ? "emoji-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={emojiDialog ? "true" : undefined}
							disableRipple
							disableFocusRipple
							display="none"
							onClick={(e) => {
								setAncorChosenEmoji(e.currentTarget);
							}}
							sx={{
								alignSelf: "flex-end",
								display: "none",
							}}
						>
							<Avatar variant="chatIcons" src={EmojiIcon} />
						</IconButton>
						<IconButton
							disableRipple
							disableFocusRipple
							sx={{
								alignSelf: "flex-end",
							}}
							component="label"
						>
							<input
								type="file"
								multiple
								hidden
								accept="image/*"
								id="file"
								onChange={previewImg}
								ref={fileRef}
							/>
							<Avatar variant="chatIcons" src={AttachmentIcon} />
						</IconButton>
					</InputContainer>
					<IconButton
						sx={{
							paddingLeft: 2,
						}}
						disableRipple
						type="submit"
					>
						<Avatar variant="sendMessage" src={SendIcon} />
					</IconButton>
				</Stack>
			) : null}
		</Stack>
	);
}
