import React from "react";

// Third party libraries and imports
import { Routes, Route, Outlet } from "react-router-dom";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import useTheme from "@mui/material/styles/useTheme";

// internal imports
//? COMPONENTS IMPORT
//TODO[] separate components from pages, import pages and in pages import components
// import { MainAppMobile } from "../components/templates/MainAppMobile";
import { NotFound } from "../components/NotFound";
import { Login } from "../components/Login";
import { SignUpGoogle } from "../components/signUpGoogle";
import SignUp from "../components/SignUp";
import { Nda } from "../components/Nda";
import { Questionnarie } from "../components/Questionnaire";
import { Payment } from "../components/Payment";
import { ScheduleOld } from "../components/Schedule.Old";
import Schedule from "../components/Schedule";
// import { CredentialsSharing } from "../components/CredentialsSharing";
import { ConfirmQuote } from "../components/ConfirmQuote";
import { CardsApprove } from "../components/ReadyApprove";
import { ReviewTheWork } from "../components/ReviewTheWork";
import MyAccount from "../components/MyAccount";
import CustomClient from "../components/CustomClient/";

import { WorkDescription } from "../components/WorkDescription";

// context
import { RequierementsContextProvider } from "../Context/RequierementsContext";
import { MainApp } from "../components/templates/MainApp";
import { FileContextProvider } from "../Context/FileContext";
import { PlatformsContextProvider } from "../Context/platformsContext";
// misc
import { PrivateRoutes } from "./privateRoutes";
import LoadingScreen from "../components/persistorLoadingScreen";
import { Chat } from "../components/Chat";
import MeetTheTeamPage from "../Pages/meetTheTeam";
import WorkInProgressPage from "../Pages/workInProgress";
import QuotePage from "../Pages/quote";
import RequierementsDescriptionPage from "../Pages/requierementsDescription";
import { PseudoPrivateRoutes } from "./pseudoPrivateRoutes";
import NewProjectPage from "../Pages/newProject";
import InvoicePage from "../Pages/invoice";

function Router() {
	return (
		<FileContextProvider>
			<RequierementsContextProvider>
				<PlatformsContextProvider>
					<React.Suspense fallback={<LoadingScreen />}>
						<Routes>
							<Route path="/CustomClient/Start" element={<CustomClient />} />
							<Route element={<AppMain />}>
								<Route path="/login" element={<Login />} />
								{/* Pseudo private routes */}
								<Route path="/start" element={<WorkDescription />} />
								<Route path="/" element={<WorkDescription />} />
								<Route element={<PseudoPrivateRoutes />}>
									<Route path="/signup" element={<SignUp />} />
									<Route path="*" element={<NotFound />} />
								</Route>
								{/* Private routes */}
								<Route element={<PrivateRoutes />}>
									<Route path="/signupGoogle" element={<SignUpGoogle />} />
									<Route path="/nda" element={<Nda />} />
									<Route
										path="/RequirementsDescription"
										element={<RequierementsDescriptionPage />}
									/>
									<Route
										path="/WorkDescription"
										element={<WorkDescription />}
									/>
									<Route path="/quote" element={<QuotePage />} />
									<Route path="/checkout" element={<Payment />} />
									<Route path="/invoice" element={<InvoicePage />} />
									{/* <Route
										path="/CredentialsSharing"
										element={<CredentialsSharing />}
									/> */}
									<Route path="/chat" element={<Chat />} />
									<Route path="/scheduleOld" element={<ScheduleOld />} />
									<Route path="/schedule" element={<Schedule />} />
									<Route path="/meetTheTeam" element={<MeetTheTeamPage />} />
									<Route
										path="/workInProgress"
										element={<WorkInProgressPage />}
									/>
									<Route path="/reviewTheWork" element={<ReviewTheWork />} />
									<Route path="/myAccount" element={<MyAccount />} />
									<Route path="/confirmQuote" element={<ConfirmQuote />} />
									<Route path="/questionnare" element={<Questionnarie />} />
									<Route path="/approve" element={<CardsApprove />} />
									<Route path="/newProject" element={<NewProjectPage />} />
								</Route>
							</Route>
						</Routes>
					</React.Suspense>
				</PlatformsContextProvider>
			</RequierementsContextProvider>
		</FileContextProvider>
	);
}

function AppMain() {
	return (
		<MainApp>
			<Outlet />
		</MainApp>
	);
}
export default Router;
