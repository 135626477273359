export const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const timeSlots = [
	"09:00", // 0 - 1
	"09:30",
	"10:00",
	"10:30",
	"11:00",
	"11:30", // 5 - 6
	"12:00",
	"12:30",
	"13:00",
	"13:30",
	"14:00", // 10 - 11
	"14:30",
	"15:00",
	"15:30",
	"16:00",
	"16:30", // 15 - 16
	"17:00",
	"17:30",
];
