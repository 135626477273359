import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function PreviewAttachements({ media }) {
	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={2}
			sx={{
				marginLeft: "26px",
			}}
		>
			<Typography variant="myTeamRole">File name:&nbsp;{media}</Typography>
		</Stack>
	);
}
