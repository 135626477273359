import React from "react";

// import { useNavigate } from "react-router-dom";
import { getByIdAuthed, postMethod } from "../backend/services";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	// onAuthStateChanged,
	signInWithPopup,
	// getIdToken,
	updatePassword,
	reauthenticateWithPopup,
	getAuth,
	GoogleAuthProvider,
	sendPasswordResetEmail,
	updateProfile,
} from "firebase/auth";

import { auth, googleProvider } from "../firebase";
import { getById } from "../backend/services";
import { PlatformsStep } from "../components/StepsContent/platforms";
import { redirect } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const UserContext = React.createContext();

export function AuthContextProvider({ children }) {
	const INITIAL_STATE = {
		isLoading: true,
		isAuthenticated: false,
		user: null, // <==|de aqui viene mi user|==<
		error: null,
		projects: [],
		isSigned: false,
		projectId: "", //! This is the "Active Project"
		hidden: false,
		hiddenAccordions: false,
		platforms: [],
		authLoading: true,
		steps: [
			{
				text: "Platforms",
				url: "/start",
				component: <PlatformsStep />,
				step: 0,
				id: "pla0",
			},
			{
				text: "Registration",
				url: "/signup",
				step: 1,
				id: "reg1",
			},
			{
				text: "Non Disclousure Agreement",
				url: "/nda",
				// component: <NdaStep />,
				step: 2,
				id: "non2",
			},
			{
				text: "Work description",
				url: "/RequirementsDescription",
				step: 3,
				id: "tas3",
			},
			{
				text: "Connecting you with an associate",
				url: "/RequirementsDescription",
				step: 4,
				id: "con4",
			},
			{
				text: "Quote",
				url: "/quote",
				step: 5,
				id: "quo5",
			},
			{
				text: "Start the work",
				url: "/schedule",
				step: 6,
				id: "sta7",
			},
			{
				text: "Meet the team",
				url: "/meetTheTeam",
				step: 7,
				id: "mee8",
			},
			{
				text: "Work in progress",
				url: "/workInProgress",
				step: 8,
				id: "wor9",
			},
			{
				text: "Review the work",
				url: "/reviewTheWork",
				step: 9,
				id: "rev10",
			},
		],
		openPlatforms: false,
	};
	// state only to use inside context, not to export

	function reducer(state, action) {
		switch (action.type) {
		case "CHANGE_LOADING":
			return { ...state, isLoading: action.payload };
		case "CHANGE_AUTH":
			return { ...state, isAuthenticated: action.payload };
		case "CHANGE_USER":
			return { ...state, user: action.payload };
		case "CHANGE_ERROR":
			return { ...state, error: action.payload };
		case "CHANGE_PROJECTS":
			return { ...state, projects: action.payload };
		case "CHANGE_PROJECT_ID":
			return {
				...state,
				projectId: action.payload,
			};
		case "CHANGE_PLATFORMS":
			return {
				...state,
				platforms: action.payload,
			};
		case "CHANGE_HIDDEN":
			return {
				...state,
				hidden: action.payload,
			};
		case "CHANGE_HIDDEN_ACCORDIONS":
			return {
				...state,
				hiddenAccordions: action.payload,
			};
		case "CHANGE_IS_SIGNED":
			return {
				...state,
				isSigned: action.payload,
			};
		case "CHANGE_OPEN_PLATFORMS":
			return {
				...state,
				openPlatforms: action.payload,
			};
		case "CHANGE_AUTH_LOADING":
			return {
				...state,
				authLoading: action.payload,
			};
		case "RESTART":
			return INITIAL_STATE;
		default:
			return state;
		}
	}

	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
	// async function AuthState() {
	// 	onAuthStateChanged(auth, async (currentUser) => {
	// 		dispatch({ type: "CHANGE_USER", payload: currentUser });
	// 	});
	// 	dispatch({ type: "CHANGE_AUTH_LOADING", payload: false });
	// }
	// React.useEffect(() => {
	// 	AuthState();
	// }, [state.user]);

	// AUTH / user FUNCTIONS
	async function createUser(email, pass, data) {
		return createUserWithEmailAndPassword(auth, email, pass)
			.then(async (firebaseUser) => {
				return await updateProfile(firebaseUser.user, {
					displayName: data.firstName + " " + data.lastName,
					photoURL:
						"https://firebasestorage.googleapis.com/v0/b/tuneup-8267c.appspot.com/o/DefaultPfp.png?alt=media",
				})
					.then(async () => {
						let user = firebaseUser.user;
						user = { ...user, clientId: user.uid };
						localStorage.setItem("user", JSON.stringify(user));
						return await postMethod({
							route: "client/createClient",
							body: {
								...user,
								firstName: data.firstName,
								lastName: data.lastName,
								phone: data.phone ?? "",
								feedback: data.feedBack ?? "",
								company: data.company ?? "",
								photoURL:
									"https://firebasestorage.googleapis.com/v0/b/tuneup-8267c.appspot.com/o/noProfilePicture.svg?alt=media&token=bd3f4acb-6e7e-44fc-9810-c1ac03aa6635",
							},
						}).then((res) => {
							if (res.success) {
								return res.data;
							}
						});
					})
					.catch((error) => {
						console.error("updated unsuccessfully", error);
						// An error occurred
						// ...
					});
			})
			.catch((e) => console.error("something went wrong", e));
	}

	// TODO[x] make different functions for login and signup
	// ^^^ this is done in backend

	async function SignInWithGoogle() {
		try {
			const res = await signInWithPopup(auth, googleProvider);
			const user = res.user;
			dispatch({ type: "CHANGE_USER", payload: user });
			localStorage.setItem("user", JSON.stringify(user));

			await postMethod({
				route: "client/createClientGoogle",
				body: {
					email: user.email,
					displayName: user.displayName,
					uid: user.uid,
					firstName: user.displayName.split(" ")[0],
					lastName: user.displayName.split(" ")[1],
					photoURL: user.photoURL,
				},
			});
			// await postMethod({
			// 	route: "project/savePlatformsProjects",
			// 	body: {
			// 		platforms: JSON.parse(localStorage.getItem("platforms")),
			// 		clientId: user.uid,
			// 		clientName: user.displayName,
			// 		email: user.email,
			// 	},
			// }).then((res) => {
			// 	console.info(res);
			// 	localStorage.setItem(
			// 		"localStorageProjects",
			// 		JSON.stringify(res.projects)
			// 	);
			// 	localStorage.setItem("localStorageProject", res.projects[0]);
			// 	if (
			// 		Array.isArray(projectsData.projects) &&
			// 		!projectsData.projects.length
			// 	) {
			// 		const projectsMutable = projectsData;

			// 		projectsMutable.projects = res.projects;
			// 		projectsMutable.projectId = res.projectId;
			// 		console.log(res.projects);
			// 		if (res.projectId === undefined) {
			// 			console.log(res.projects[0]);
			// 			projectsMutable.projectId = res.projects[0].projectId;
			// 		}
			// 		projectsMutable.clientId = user.uid;
			// 		console.log("array is array and empty");
			// 		console.log(projectsMutable);
			// 		setProjects(projectsMutable.projects);
			// 		setSelectedProject(projectsMutable.projects[0]);
			// 	}
			// });
			// localStorage.setItem("payment", false);
		} catch (err) {
			console.error(err);
			alert("unexpected error");
		}
	}
	async function login(email, password) {
		const user = await signInWithEmailAndPassword(auth, email, password);
		dispatch({ type: "CHANGE_USER", payload: user.user });
		const projects = await getClientProjects(user.user.uid);
		// setProjects(projects);
		// setSelectedProject(projects[0]);

		const agreement = await getClientAgreement(user.user.uid, user.user.accessToken);
		setAgreement(agreement);

		// if (projects.length > 0) {
		// 	handleComplete(projects[0]);
		// } else {
		// 	changeOpenPlatforms(true);
		// }

		return projects;
	}
	async function getClientProjects(uid) {
		return await getByIdAuthed({
			route: "project/getClientProjects",
			id: uid,
		})
			.then((res) => {
				if (res.success) {
					return res.data;
				} else throw new Error("error getting projects");
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar(e.message, { variant: "error" });
			});
	}

	async function getClientAgreement(uid, accessToken) {
		return await getById({
			route: "agreement/isSigned",
			id: uid,
			accessToken
		})
			.then((res) => {
				if (res.success) {
					return res.isSigned;
				} else throw new Error("error getting projects");
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar(e.message, { variant: "error" });
			});
	}

	function logout() {
		localStorage.removeItem("user");
		localStorage.removeItem("isSigned");
		localStorage.removeItem("CompletedSteps");
		localStorage.removeItem("localStorageProjects");
		localStorage.removeItem("platforms");
		localStorage.removeItem("ActiveStep");
		localStorage.removeItem("tasks");
		localStorage.removeItem("payment");
		localStorage.removeItem("TicketsID");
		localStorage.removeItem("localStorageProject");
		localStorage.removeItem("localStorageProjectName");
		dispatch({ type: "RESTART" });
		redirect("/start");
		return signOut(auth);
	}

	async function recoverPassword(email) {
		sendPasswordResetEmail(auth, email)
			.then(() => {
				enqueueSnackbar("Email sent", { variant: "success" });
			})
			.catch((error) => {
				enqueueSnackbar("Error to send email", { variant: "error" });
				console.error(error);
			});
	}

	function setAgreement(agreement) {
		localStorage.setItem("isSigned", agreement);
		dispatch({ type: "CHANGE_AGREEMENT", payload: agreement });
	}

	// PLATFORMS (Select platforms) FUNCTIONS
	function AddPlatform(platform) {
		const platforms = [...state.platforms];
		platforms.push(platform);
		localStorage.setItem("platforms", JSON.stringify(platforms));
		dispatch({ type: "CHANGE_PLATFORMS", payload: platforms });
	}

	function removePlatform(platform) {
		const platforms = [...state.platforms];
		const platformsSubs = platforms?.filter(
			(platformFilter) => platformFilter.id !== platform.id
		);
		localStorage.setItem("platforms", JSON.stringify(platformsSubs));
		dispatch({ type: "CHANGE_PLATFORMS", payload: platformsSubs });
	}

	// STEPPER/PROJECT FUNCTIONS

	// function getProjectById(projectId) {
	// 	console.log("projectId", projectId);
	// 	const projects = [...state.projects];
	// 	const project = projects?.find(
	// 		(projectFilter) => projectFilter.projectId === projectId
	// 	);
	// 	return project;
	// }

	// async function projects() {
	// 	const tempProjects = [];
	// 	if (localStorage.getItem(`localStorageProject`)) {
	// 		const projectId = localStorage.getItem(`localStorageProject`);
	// 		setSelectedProject(projectId);
	// 	}
	// 	const projects = localStorage.getItem(`localStorageProjects`);
	// 	if (localStorage.getItem(`localStorageProjects`) && projects.length > 0) {
	// 		JSON.parse(localStorage.getItem(`localStorageProjects`)).forEach(
	// 			(project) => {
	// 				tempProjects.push(project);
	// 			}
	// 		);
	// 		dispatch({ type: "CHANGE_PROJECTS", payload: tempProjects });
	// 	} else {
	// 		try {
	// 			await getProjects({
	// 				route: "project/getClientProjects",
	// 				id: state.user.uid,
	// 			}).then((response) => {
	// 				if (
	// 					response.success !== false &&
	// 					response?.data?.projects?.length > 0
	// 				) {
	// 					const res = response.data;
	// 					res.forEach((project) => {
	// 						tempProjects.push(project);
	// 					});
	// 					localStorage.setItem(
	// 						"localStorageProjects",
	// 						JSON.stringify(tempProjects)
	// 					);
	// 					setProjects(tempProjects);
	// 					setSelectedProject(tempProjects[0]);
	// 				} else
	// 					console.log(
	// 						`client: ${state.user.displayName} exist but don't have projects`
	// 					);
	// 			});
	// 		} catch (e) {
	// 			console.error("Error in getProjects", e);
	// 		}
	// 	}
	// }

	// function setSelectedProject(selectedProject) {
	// 	dispatch({ type: "CHANGE_PROJECT_ID", payload: selectedProject.projectId });
	// }

	// function setProjects(projects) {
	// 	const newProjects = [];
	// 	const treatedProjects = projects;
	// 	treatedProjects.forEach((project) => {
	// 		const tempArray = [];
	// 		for (let i = 0; i <= project.activeStep + 1; i++) {
	// 			tempArray.push(projectsData.steps[i]);
	// 		}
	// 		if (projectsData.steps.length < project.activeStep + 1) {
	// 			tempArray.push(projectsData.steps[project.activeStep + 1]);
	// 		}
	// 		project.tempSteps = tempArray;
	// 		newProjects.push(project);
	// 	});
	// 	console.log("newProjects", newProjects);

	// 	dispatch({
	// 		type: "CHANGE_PROJECTS",
	// 		payload: newProjects,
	// 	});
	// }

	// function setActiveStep(index) {
	// 	const project = getProjectById(state.projectId);
	// 	console.log("project setActiveStep", project);
	// 	if (project.projectId === undefined || projectsData.projectId === "") {
	// 		return;
	// 	}
	// 	project.activeStep = index;

	// 	const tempArray = [];
	// 	try {
	// 		for (let i = 0; i <= index + 1; i++) {
	// 			if (projectsData.steps.length === i) {
	// 				break;
	// 			}
	// 			tempArray.push(projectsData.steps[i]);
	// 		}

	// 		project.tempSteps = tempArray;
	// 	} catch (e) {
	// 		console.error(e, "error in set active step");
	// 	}

	// 	const projects = [...state.projects];

	// 	const mutableProject = projects.find(
	// 		(projectFilter) => projectFilter.projectId === project.projectId
	// 	);

	// 	mutableProject.activeStep = index;

	// 	dispatch({
	// 		type: "CHANGE_PROJECTS",
	// 		payload: project,
	// 	});
	// }

	// function handleStep(index) {
	// 	const project = { ...state.project };
	// 	project.activeStep = index;
	// 	dispatchProjects({ type: "CHANGE_PROJECT", payload: project });
	// 	navigate(project.steps[index].url);
	// }

	// function handleNext(functionProject, functionProjects) {
	// 	const project = functionProject;
	// 	project.activeStep = project.activeStep + 1;
	// 	localStorage.setItem("ActiveStep", project.activeStep);

	// 	const projects = functionProjects;

	// 	projects.find(
	// 		(projectFilter) => projectFilter.projectId === project.projectId
	// 	).activeStep = project.activeStep;
	// 	console.log("HandleNext", project);
	// 	navigate(state.steps[project.activeStep].url);
	// 	dispatch({ type: "CHANGE_PROJECTS", payload: projects });
	// }

	// function handleComplete(functionProject, functionProjects) {
	// 	const project = functionProject;
	// 	console.log("handleComplete project", { ...project });

	// 	project.completedSteps[project.activeStep] = true;

	// 	const projects = functionProjects;

	// 	console.log("handleComplete projects before", [...projects]);

	// 	projects.find(
	// 		(projectFilter) => projectFilter.projectId === project.projectId
	// 	).completedSteps = project.completedSteps;
	// 	console.log("handleComplete projects after", [...projects]);
	// 	dispatch({
	// 		type: "CHANGE_PROJECTS",
	// 		payload: projects,
	// 	});
	// 	handleNext(project, projects);
	// }

	// function handleNextEmptyProject() {
	// 	console.log("handle next empty", projectsData);
	// 	navigate("/signUp");
	// }

	// function changeOpenPlatforms(open) {
	// 	dispatch({ type: "CHANGE_OPEN_PLATFORMS", payload: open });
	// }

	// function changeHideStepper(state) {
	// 	dispatch({ type: "CHANGE_HIDE_STEPPER", payload: state });
	// }

	// // misc

	// function sendPlatforms() {
	// 	dispatch({ type: "CHANGE_LOADING", payload: true });
	// 	if (state.platforms.length === 0) {
	// 		alert("Ninguna plataforma seleccionada");
	// 		dispatch({ type: "CHANGE_LOADING", payload: false });
	// 		return;
	// 	}
	// 	const platforms = state.platforms;
	// 	// SET_PLATFORMS AND SET_LOCAL_PLATFORMS DO THE SAME THING, BUT THE FIRST ONE IS FOR THE CONTEXT AND THE SECOND ONE IS FOR THE LOCALSTORAGE
	// 	// if (!localStorage.getItem("platforms")) {
	// 	localStorage.setItem("platforms", JSON.stringify(platforms));
	// 	// }
	// 	// dispatchStepper({ type: "SET_PLATFORMS", payload: platformsStepper });
	// 	console.log(state?.project);
	// 	if (state.projectId === "") {
	// 		dispatch({ type: "CHANGE_LOADING", payload: false });
	// 		handleNextEmptyProject();
	// 		return;
	// 	}
	// 	if (state?.user) {
	// 		dispatch({ type: "CHANGE_LOADING", payload: false });
	// 		handleComplete();
	// 	} else {
	// 		dispatch({ type: "CHANGE_LOADING", payload: false });
	// 		//fixme to complete step i need a projectID, if user is not logged in
	// 		//fixme i need to create a projectID, so i think saving the step complete in localStorage is a good idea
	// 		//fixme[]
	// 		handleComplete();
	// 	}
	// }

	return (
		<UserContext.Provider
			value={{
				AuthData: state,
				createUser,
				logout,
				login,
				SignInWithGoogle,
				updatePassword,
				reauthenticateWithPopup,
				getAuth,
				GoogleAuthProvider,
				recoverPassword,
				AddPlatform,
				removePlatform,
				// setSelectedProject,
				// setActiveStep,
				// handleStep,
				// // handleComplete,
				// projects,
				// setProjects,
				// handleNextEmptyProject,
				// changeOpenPlatforms,
				// changeHideStepper,
				// sendPlatforms,
				// AuthState,
				// getProjectById,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
/**
 * do not use
 * @deprecated
*/
export const UserAuth = () => {
	return React.useContext(UserContext);
};
