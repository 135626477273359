import React from "react";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AccordionSummary } from "../../templates/chatComponents";

import ExpandChatIcon from "../../../Sources/Icons/ExpandChatIcon.svg";
import { ChatContext } from "../../../Context/ChatContext";


export default function ChatHeader({ messages }) {
	const { chatData } = React.useContext(ChatContext);

	return (
		<AccordionSummary
			sx={{
				flexDirection: "row",
				height: "65px",
			}}
			expandIcon={
				<Avatar
					sx={{
						marginX: "8px",
						width: "10px",
						height: "10px",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					}}
					src={ExpandChatIcon}
				/>
			}
			aria-controls="panel1a-content"
			id="panel1a-header"
		>
			<Stack flexDirection={"row"} sx={{ alignItems: "center" }}>
				{chatData?.ClientActiveChatUsername !== "" ? (
					<Avatar
						sx={{
							width: "35px",
							height: "35px",
							aspectRatio: "1/1",
							justifyItems: "flex-start",
							filter: "drop-shadow(4px 4px 10px rgba(84, 84, 84, 0.25))",
						}}
						src={chatData?.photoURL}
					/>
				) : null}
				<Typography marginLeft={2} variant="chatHeader">
					{chatData?.ClientActiveChatUsername !== ""
						? messages
							? "Connected with: " +
							  chatData?.ClientActiveChatUsername?.split(" ")[0]
							: "await while we connect you with: " +
							  chatData?.ClientActiveChatUsername?.split(" ")[0]
						: ""}
				</Typography>
			</Stack>
		</AccordionSummary>
	);
}
