import React from "react";
const Context = React.createContext();
export const PlatformsContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		platforms: [],
	};
	const platformsReducer = (state, action) => {
		switch (action.type) {
		case "CHANGE_PLATFORMS":
			return {
				...state,
				platforms: action.payload,
			};
		case "RESTART_CONTEXT":
			return { ...INITIAL_STATE };
		default:
			return state;
		}
	};
	const [state, dispatch] = React.useReducer(platformsReducer, INITIAL_STATE);

	function AddPlatform(platform) {
		const platforms = [...state.platforms];
		platforms.push(platform);
		localStorage.setItem("platforms", JSON.stringify(platforms));
		dispatch({ type: "CHANGE_PLATFORMS", payload: platforms });
	}

	function removePlatform(platform) {
		const platforms = [...state.platforms];
		const platformsSubs = platforms?.filter(
			(platformFilter) => platformFilter.id !== platform.id
		);

		localStorage.setItem("platforms", JSON.stringify(platformsSubs));
		dispatch({ type: "CHANGE_PLATFORMS", payload: platformsSubs });
	}

	return (
		<Context.Provider
			value={{
				platformsData: state,
				AddPlatform,
				removePlatform,
			}}
		>
			{children}
		</Context.Provider>
	);
};
export function PlatformsContext() {
	return React.useContext(Context);
}
