// import React from "react";

import Avatar from "@mui/material/Avatar";
// import FormControl from "@mui/material/FormControl";
// import FormHelperText from "@mui/material/FormHelperText";
// import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TaskDescriptionIcon from "../../Sources/Icons/TaskDescriptionIcon.svg";

// import { RequierementsContext } from "../../Context/RequierementsContext";

export function TaskHeaderDesktop({
	index,
	// expanded,
	// taskName,
	// disabled,
	// handleTaskName,
	// setName,
	// taskIndex,
}) {
	// const { requierementsData } = React.useContext(RequierementsContext);
	return (
		<Stack
			direction={"row"}
			sx={{
				justifyContent: "flex-start",
				width: "100%",
				alignItems: "center",
			}}
			spacing={1}
		>
			<Stack
				direction={"row"}
				alignItems="center"
				spacing={1}
				minWidth="fit-content"
			>
				<Avatar
					sx={{ width: "25px", height: "25px" }}
					variant="iconTask"
					src={TaskDescriptionIcon}
				/>
				<Typography
					sx={{
						minWidt: "fit-content",
					}}
					variant="calendarMonth"
				>
					Task &#35;{index + 1}
				</Typography>
			</Stack>
			<Stack
				sx={{ width: "100%", alignItems: "center", gap: "15px" }}
				direction={"row"}
				spacing={2}
			>
				<Stack sx={{ width: "100%" }}>
					{/*
					{expanded ? (
						""
					) : (
						<Typography variant="taskCollapsedTitle">
							{taskName.length > 0 ? taskName : " "}
						</Typography>
					)} 
					*/}
					{/* 					{expanded ? (
						<FormControl sx={{ width: "60%", top: 10 }} fullWidth>
							<OutlinedInput
								size="small"
								fullWidth
								name="taskName"
								value={taskName}
								placeholder="Add a title..."
								onChange={(e) => {
									handleTaskName(e);
								}}
								sx={{
									fontSize: "14px",
									"& .MuiOutlinedInput-input::placeholder": {
										color: "#C0C0C0",
										fontWeight: "200",
									},
								}}
								onBlur={(e) => setName(e)}
								disabled={disabled}
							/>

							<FormHelperText> </FormHelperText>
						</FormControl>
					) : (
						<Stack paddingTop={1} direction={"row"}>
							{requierementsData.tasks[index]?.platforms?.map((platform) => (
								<Avatar
									key={platform.id}
									variant="iconTasks"
									src={platform.checked && platform.icons.inactive}
									sx={{
										"&:nth-of-type(1)": {
											marginLeft: 0,
										},
										marginLeft: 1,
									}}
								/>
							))}
						</Stack>
					)} */}
				</Stack>
			</Stack>
		</Stack>
	);
}
