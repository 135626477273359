import React from "react";

import {
	Avatar,
	Box,
	Button,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import GppGoodIcon from "@mui/icons-material/GppGood";

import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";

import closeIcon from "../../Sources/Icons/CloseModal.svg";
import { CustomModal } from "../templates/modals";
import uploadIcon from "../../Sources/Icons/uploadIcon.svg";
import completedUpdate from "../../Sources/Icons/completedUpdate.svg";
import { useDispatch, useSelector } from "react-redux";
import { postMethodAuthed } from "../../backend/services";

import { StyledBadge } from "../muiCustomComponents/styleBadge";
import { updatePlatform } from "../../features/project/projectSlice";

import { isMobile } from "../../hooks/utils";

export default function PlatformCredentials({ platform }) {
	const [open, setOpen] = React.useState(false);
	const check = isMobile();
	const projects = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	return (
		<Stack
			direction="row"
			sx={{
				alignItems: "center",
				justifyContent: "space-between",
				display: !platform.requested && "none",
				width: "100%",
			}}
		>
			<CustomModal
				props={{
					open: open,
					onClose: () => setOpen(false),
					sx: {
						"& .MuiDialog-container": {
							marginLeft: "203px",
							"& .MuiDialog-paper": {
								width: platform.sharedCredentials ? "80vw" : "50vw",
								maxWidth: "100vw",
							},
						},
					},
					hideBackdrop: false,
				}}
			>
				{platform.sharedCredentials ? <Detailed /> : <Simple />}
			</CustomModal>
			<Grid
				sx={{
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
				}}
				item
				xs={6}
				xl={3}
			>
				<Tooltip arrow title={platform.text || platform.name} placement="left">
					<Typography variant="quoteTotal">
						{platform.text || platform.name}&nbsp;
					</Typography>
				</Tooltip>
			</Grid>
			<Grid item xs={6} xl={9}>
				<Stack direction="row" sx={{ alignItems: "center" }}>
					<Button
						disableRipple
						variant="shareCreadentialsButton"
						// onClick={() => openModal(platform.uid)}
						sx={{
							paddingX: 0,
						}}
						onClick={() => setOpen(true)}
						startIcon={
							platform.sharedCredentials ? (
								<Avatar src={completedUpdate} variant="cloudIcon" />
							) : (
								<Avatar src={uploadIcon} variant="cloudIcon" />
							)
						}
					>
						{platform.sharedCredentials ? (
							<Typography variant="buttonShareCredentials">
								Completed. Your credentials will be deleted&nbsp;
								<Typography variant="buttonShareCredentialsBold">
									24 hours after project completion.
								</Typography>
							</Typography>
						) : (
							<Typography variant="buttonShareCredentials">Upload</Typography>
						)}
					</Button>
				</Stack>
			</Grid>
		</Stack>
	);

	function Detailed() {
		return (
			<>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "2rem",
						color: "#112031",
					}}
				>
					Share your credentials
					<IconButton
						disableRipple
						sx={{ width: "fit-content" }}
						onClick={() => setOpen(false)}
					>
						<Avatar src={closeIcon} variant="closeIcon" />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box
						sx={{ display: "flex", justifyContent: "center", paddingTop: 2 }}
					>
						<Box
							sx={{
								borderRadius: "15px",
								paddingX: "40px",
								paddingTop: "20px",
								paddingBottom: "40px",
								boxShadow: "5px 5px 15px 5px rgb(0 0 0 / 10%)",
								marginBottom: "60px",
								width: check ? "100%" : "40%",
							}}
						>
							<Stack spacing={4} justifyContent="center">
								<Typography variant="titles" sx={{ alignSelf: "center" }}>
									Share your {platform != null ? platform.name : ""} credentials
								</Typography>
								<FormControl
									sx={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										gap: 1,
										paddingTop: 4,
									}}
								>
									<Typography
										sx={{
											paddingBottom: "0",
										}}
										variant="label"
									>
										Username
									</Typography>
									<Stack>
										<TextField
											variant="outlined"
											name="username"
											id="usernameCredentials"
											type="password"
											value={platform.credentials.username}
											disabled
										/>
									</Stack>
								</FormControl>
								<FormControl
									sx={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										gap: 1,
									}}
								>
									<Typography
										sx={{
											paddingBottom: "0",
										}}
										variant="label"
									>
										Password
									</Typography>
									<Stack>
										<TextField
											variant="outlined"
											name="password"
											id="passwordCredentials"
											type="password"
											value={platform.credentials.password}
											disabled
										/>
									</Stack>
								</FormControl>
							</Stack>
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<section style={{ width: "80%" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginBottom: "5%",
								}}
							>
								{/* <Typography variant="descriptiveText" sx={{ fontSize: "20px" }}>
									Encrypted credentials:
								</Typography> */}
								<Typography
									variant="descriptiveText"
									sx={{ fontWeight: "500", padding: "0" }}
								>
									{/* Username: {encryptedCrendetials.username ?? "Nothing to show"} */}
								</Typography>
								<Typography
									variant="descriptiveText"
									sx={{ fontWeight: "500", padding: "0" }}
								>
									{/* Password: {encryptedCrendetials.password ?? "Nothing to show"} */}
								</Typography>
							</div>
							<Typography
								variant="titles"
								sx={{
									fontSize: "20px",
									color: useTheme().palette.electricBlue.secondary,
								}}
							>
								Sharing the encrypted credentials:
							</Typography>
							<div style={{ marginTop: "4%" }} className="computers-box">
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
									sx={{ marginBottom: "2%" }}
								>
									<Stack
										direction="row"
										spacing={2}
										sx={{ alignItems: "center" }}
									>
										<StyledBadge
											overlap="circular"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											variant="dot"
										>
											<Avatar
												alt="Remy Sharp"
												src={projects.project.projectLeader?.photoURL}
											/>
										</StyledBadge>

										<div style={{ maxWidth: "12%", minWidth: "12%" }}>
											<Typography
												variant="descriptiveText"
												sx={{ color: "#94A3CA", fontWeight: "500" }}
											>
												{
													projects.project.projectLeader.displayName?.split(
														" "
													)[0]
												}
											</Typography>
										</div>
									</Stack>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "15px",
										}}
									>
										<DesktopWindowsRoundedIcon sx={{ color: "#94A3CA" }} />
									</Box>
								</Stack>

								{projects.project.members
									.filter((m) => m.uid !== projects.project.projectLeader.uid)
									.map((teamMember, index) => (
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
											key={index}
											sx={{
												marginBottom: "2%",
											}}
										>
											<Stack
												direction="row"
												spacing={2}
												sx={{ alignItems: "center" }}
											>
												<StyledBadge
													overlap="circular"
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													variant="dot"
												>
													<Avatar
														alt={teamMember.displayName}
														src={teamMember.photoURL}
													/>
												</StyledBadge>
												<div style={{ maxWidth: "12%", minWidth: "12%" }}>
													<Typography
														variant="descriptiveText"
														sx={{ color: "#94A3CA", fontWeight: "500" }}
													>
														{teamMember?.displayName?.split(" ")[0]}
													</Typography>
												</div>
											</Stack>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													gap: "15px",
												}}
											>
												<DesktopWindowsRoundedIcon sx={{ color: "#94A3CA" }} />
												<Typography
													variant="descriptiveText"
													sx={{ color: "#112031" }}
												>
													{teamMember.macDirection}
												</Typography>
											</Box>
										</Stack>
									))}
							</div>
							<p className="info-smll">
								Your password is stored securely and encrypted, so it can’t be
								hacked, and it will never leave our infrastructure. Each time
								your password is accessed you will receive a text message from
								us alerting you that one of our developers is accessing your
								account. They will be deleted once the project ends. We
								recommend you to enable the 2-step verification method so we
								will ask you for a verification code.
							</p>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Button
									sx={{
										marginTop: "40px",
										alignSelf: "flex-end",
										paddingRight: "5px 25px",
									}}
									variant="primaryButton"
									onClick={() => setOpen(false)}
								>
									Done
								</Button>
							</Box>
						</section>
					</Box>
				</DialogContent>
			</>
		);
	}

	function Simple() {
		const [usernameEncryped, setUsernameEncryped] = React.useState("");

		const validationSchema = yup.object({
			username: yup
				.string("Enter your username")
				.required("username is required"),
			password: yup.string().required("Password is required"),
		});

		const formik = useFormik({
			initialValues: {
				username: "",
				password: "",
			},
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const username = values.username;
				// const password = values.password;
				if (usernameEncryped === "") {
					setUsernameEncryped(username);
					// setPasswordEncryped(password);
				}
				try {
					await postMethodAuthed({
						route: "meetTheTeam/saveCredentials",
						body: {
							uid: platform.uid,
							name: platform.text,
							username: values.username,
							password: values.password,
							projectUid: projects.project.uid,
						},
						accessToken: auth.token,
					});
					dispatch(updatePlatform(platform));
				} catch (error) {
					console.error(error);
				}
			},
		});

		return (
			<>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "25px",
						color: "#112031",
					}}
				>
					Share your credentials
					<IconButton
						disableRipple
						sx={{ width: "fit-content" }}
						onClick={() => setOpen(false)}
					>
						<Avatar src={closeIcon} variant="closeIcon" />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<Stack
						component={"form"}
						onSubmit={formik.handleSubmit}
						alignItems={"center"}
					>
						<Box
							sx={{
								borderRadius: "15px",
								paddingX: "30px",
								paddingTop: "20px",
								paddingBottom: "20px",
								border: "1px solid #C8C8C8",
								marginBottom: "20px",
								width: "100%",
								maxWidth: "800px",
							}}
						>
							<Stack spacing={4}>
								<Typography variant="titles" sx={{ alignSelf: "center" }}>
									Share your {platform != null ? platform.name : ""} credentials
								</Typography>
								<FormControl
									sx={{
										paddingTop: 4,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										gap: 1,
									}}
								>
									<Typography
										sx={{
											paddingBottom: "1.3rem",
										}}
										variant="label"
									>
										Username
									</Typography>
									<Stack>
										<TextField
											variant="outlined"
											name="username"
											id="usernameCredentials"
											value={formik.values.username}
											onChange={formik.handleChange}
											error={
												formik.touched.username &&
												Boolean(formik.errors.username)
											}
										/>
										<FormHelperText>
											{formik.touched.username &&
											Boolean(formik.errors.username)
												? formik.touched.username && formik.errors.username
												: " "}
										</FormHelperText>
									</Stack>
								</FormControl>
								<FormControl
									sx={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										gap: 1,
									}}
								>
									<Typography
										sx={{
											paddingBottom: "1.3rem",
										}}
										variant="label"
									>
										Password
									</Typography>
									<Stack>
										<TextField
											variant="outlined"
											name="password"
											id="passwordCredentials"
											type="password"
											value={formik.values.password}
											onChange={formik.handleChange}
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
										/>
										<FormHelperText>
											{formik.touched.password &&
											Boolean(formik.errors.password)
												? formik.touched.password && formik.errors.password
												: " "}
										</FormHelperText>
									</Stack>
								</FormControl>
							</Stack>
						</Box>
						<Stack justifyContent="center" spacing={1}>
							<Button
								variant="primaryButton"
								type="submit"
								justifySelf="center"
								disabled={
									formik.values.username === "" || formik.values.password === ""
								}
							>
								Encrypt my credentials
							</Button>
							<Stack alignItems="center">
								<GppGoodIcon sx={{ color: "#0056D8" }} fontSize="large" />
								<Typography
									textAlign="center"
									variant="h6"
									sx={{ color: "#0056D8" }}
								>
									ENCRYPTION AT REST
								</Typography>
								<Typography
									textAlign="center"
									variant="subtitle2"
									sx={{ color: "#5F5F5F" }}
								>
									Your data is encrypted at rest using <br />
									industry standard encryption.
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</>
		);
	}
}
