import { useTheme } from "@emotion/react";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { MyAccountContext } from "../../Context/MyAccountContext";
import { MyProfile } from "./MyProfile";
// import { MySettings } from "./MySettings";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { BillingPayments } from "./BillingPayments";
import PropTypes from "prop-types";

export function DesktopTabs() {
	const { MyAccountData, dispatchMyAccount } =
		React.useContext(MyAccountContext);

	const handleChange = (event, newValue) => {
		dispatchMyAccount({ type: "GO_TAB", payload: newValue });
	};
	const theme = useTheme();

	function a11yProps(index) {
		return {
			id: `My-account-tab-${index}`,
			"aria-controls": `my-account-tabpanel-${index}`,
		};
	}
	function TabPanel(props) {
		const { children, index, ...other } = props;
		const { MyAccountData } = React.useContext(MyAccountContext);

		return (
			<Box
				role="tabpanel"
				hidden={MyAccountData.value !== index}
				id={`my-account-tabpanel-${index}`}
				aria-labelledby={`My-account-tab-${index}`}
				{...other}
			>
				{MyAccountData.value === index && <Box>{children}</Box>}
			</Box>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
	};
	return (
		<>
			<Tabs
				value={MyAccountData.value}
				onChange={handleChange}
				aria-label="My account tabs"
				sx={{
					[theme.breakpoints.up("lg")]: {
						marginLeft: "-3%",
						padding: "0% 3% 0% 3%",
					},
					maxWidth: "100%",
					"& .MuiTabs-flexContainer": {
						justifyContent: "space-between",
					},
					[theme.breakpoints.down("lg")]: { width: "100%" },
					"& .MuiTab-root": {
						[theme.breakpoints.down("md")]: { width: "100%" },
						[theme.breakpoints.up("lg")]: { width: "20%" },
					},
				}}
			>
				<Tab
					sx={{ color: "#C0C0C0", textTransform: "capitalize" }}
					label="My profile"
					{...a11yProps(0)}
				/>
				<Tab
					sx={{
						color: "#C0C0C0",
						textTransform: "capitalize",
						display: "none",
					}}
					label="My Settings"
					{...a11yProps(1)}
				/>
				<Tab
					sx={{ color: "#C0C0C0", textTransform: "capitalize" }}
					label="Privacy policy"
					{...a11yProps(2)}
				/>
				<Tab
					sx={{ color: "#C0C0C0", textTransform: "capitalize" }}
					label="Billing and payments"
					{...a11yProps(3)}
				/>
			</Tabs>
			<TabPanel value={MyAccountData.value} index={0}>
				<Box>
					<MyProfile />
				</Box>
			</TabPanel>
			{/* 			<TabPanel value={MyAccountData.value} index={1}>
				<Box>
					<MySettings />
				</Box>
			</TabPanel> */}
			<TabPanel value={MyAccountData.value} index={2}>
				<Box>
					<PrivacyPolicy />
				</Box>
			</TabPanel>
			<TabPanel value={MyAccountData.value} index={3}>
				<Box>
					<BillingPayments />
				</Box>
			</TabPanel>
		</>
	);
}
