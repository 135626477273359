import { ChatBubbleLeft, ChatBubbleRight } from "./chatBubble";

export default function DisplayAnswers({ obj }) {
	const { question, answer } = obj;
	return (
		<>
			<div className="message-container-left">
				{/* <img src={ProjectIcon} alt="" /> */}
				<ChatBubbleLeft message={question} timestamp={Date.now()} />
			</div>
			<div className="message-container-right">
				<ChatBubbleRight
					message={answer}
					timestamp={Date.now()}
					style={{ width: "-webkit-fill-available" }}
				/>
				{/* <img src={AuthData.user.photoURL} alt="" /> */}
			</div>
		</>
	);
}
