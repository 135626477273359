import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import "./Quote.css";
import TaskDescriptionIcon from "../../Sources/Icons/TaskDescriptionIcon.svg";
import quoteBottom from "../../Sources/images/quoteBottom.svg";

export default function PaidItem({ ticket, index }) {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				padding: "30px 30px 45px 30px",
				width: "100%",
				[theme.breakpoints.up("lg")]: {
					width: "100%",
				},
				[theme.breakpoints.up("xl")]: {
					width: "100%",
				},
				backgroundRepeat: "no-repeat",
				backgroundPosition: "bottom",
				backgroundColor: "#ffffffcc",
				backdropFilter: "blur(20px)",
				boxShadow: "8px 8px 20px #c0c0c040",
				position: "relative",
				borderRadius: "15px 15px 0px 0px",
				minHeight: "fit-content",
				marginBottom: "40px",
			}}
		>
			<Stack direction={"row"}>
				<Avatar
					sx={{ width: "2.1rem", height: "2.1rem" }}
					variant="iconTask"
					src={TaskDescriptionIcon}
				/>
				<Stack
					direction="row"
					sx={{ width: "100%", justifyContent: "space-between" }}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							paddingLeft: 2,
							width: "75%",
						}}
					>
						<Stack direction={"row"} sx={{ paddingBottom: 2 }}>
							<Typography variant="calendarMonth">
								Task &#35;{index + 1}&nbsp;{ticket.title}
							</Typography>
						</Stack>
						<Typography
							sx={{ paddingBottom: 2, textAlign: "start" }}
							variant="taskDescription"
						>
							{ticket.description}
						</Typography>
						<Typography variant="taskDescription">
							<img
								style={{
									height: "24px",
									opacity: "50%",
									marginRight: "10px",
								}}
								src={ticket.platform.icons?.inactive}
								className="image-platform"
								alt="platform"
							/>
						</Typography>
					</Box>
					<Box
						sx={{
							position: "relative",
							left: 0,
							width: "25%",
							minheight: "150px",
							maxHeight: "150px",
							background: theme.palette.electricBlue.secondary,
							borderRadius: "15px",
							padding: "15px",
							marginTop: "0px",
							marginLeft: "3rem",
							justifyContent: "space-between",
							height: "fit-content",
						}}
					>
						<Stack>
							<Typography variant="quoteCartTitle">
								Estimated time:&nbsp;
								<Typography variant="quoteCartContent">
									{ticket.estimatedTime}&nbsp;hour(s)
								</Typography>
							</Typography>
							<Typography variant="quoteCartTitle">
								Price:&nbsp;
								<Typography variant="quoteCartContent">
									{(ticket.price / 100)?.toLocaleString("en-us", {
										style: "currency",
										currency: "USD",
									})}
								</Typography>
							</Typography>
						</Stack>
						<Stack sx={{ alignItems: "flex-end" }}>
							<Typography variant="quoteCartPrice">Paid</Typography>
						</Stack>
					</Box>
				</Stack>
			</Stack>
			<img
				draggable={false}
				src={quoteBottom}
				className="img-quote-bottom"
				alt="circles"
			/>
		</Box>
	);
}
