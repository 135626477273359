import { Outlet } from "react-router-dom";

import { AuthState, getClientById } from "../hooks/auth";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../features/auth/authSlice";
import { getProjectsByUserId } from "../hooks/projects";
import {
	setActiveProjectById,
	setProjects,
} from "../features/project/projectSlice";

/**
 * Renders the non-private routes of the application, including user authentication and authorization.
 * @returns {JSX.Element} The JSX element representing the private routes of the application.
 */
const PseudoPrivateRoutes = () => {
	const user = AuthState();
	const dispatch = useDispatch();
	if (user === null) {
		return <Outlet />;
	}
	if (Object.keys(user).length === 0) {
		return <>loading</>;
	}
	if (user !== null && Object.keys(user).length !== 0) {
		const getUser = async () => {
			const User = await getClientById(user.uid, user.accessToken);
			dispatch(setToken(user.accessToken));
			dispatch(setUser(User));
			const projects = await getProjectsByUserId(user.uid, user.accessToken);
			dispatch(setProjects(projects));
			dispatch(setActiveProjectById(User.activeProject));
		};

		getUser();
		return <Outlet />;
	}
};

export { PseudoPrivateRoutes };
