import React from "react";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import hamburguerIcon from "../../Sources/Icons/HamburguerMenu.svg";
import { MyAccountMobile } from "../MyAccountButton/mobile";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import backgroundImg from "../../Sources/images/CURVE20.svg";
import MyAccountButton from "../MyAccountButton";
import LoadingGeneric, { LoadingMoreGeneric } from "../BackdropLoading";
import NewSteps from "../../components/stepper";
import AppBar from "@mui/material/AppBar";
import { StepperComponent } from "../../components/stepper/stepper";

import PropTypes from "prop-types";
import { Chat } from "../Chat";
import { useSelector } from "react-redux";

import advanteIcongraph from "../../Sources/images/AdvanteBannerImg-2.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import InactiveProject from "../stepper/inactiveProject";

import icon from "../../Sources/images/TuneUpImagotypeVariant.svg";

import { isMobile } from "../../hooks/utils";
/**
 * This component is used to render the main app
 * @component
 * @param {React.ReactElement} props - children to be rendered
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 * @example
 * 		<MainApp>
 * 			<SomeComponent/>
 * 		<MainApp/>
 */
export function MainApp({ children }) {
	const projects = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);
	const check = isMobile();

	const { user } = useSelector((state) => state.auth);
	const theme = useTheme();
	const [openSidebarMenu, setOpenSidebarMenu] = React.useState(false);

	React.useEffect(() => {
		function smoothScroll() {
			ref.current?.scrollIntoView({
				behavior: "smooth",
			});
		}
		smoothScroll();
	}, []);

	const ref = React.useRef();

	function toggleSidebarMenu() {
		setOpenSidebarMenu(!openSidebarMenu);
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				// background: `linear-gradient(180deg, ${theme.palette.backgroundGradient.first} 0%, ${theme.palette.backgroundGradient.second}) 100%`,
				width: "100%",
				minHeight: check ? "calc(100vh - 70px)" : "100vh",
				maxHeight: check ? "calc(100vh - 70px)" : "100vh",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "contain",
				backgroundAttachment: "fixed",
			}}
		>
			<a
				href="https://advantedigital.com/digital-tuneup/"
				target="_blank"
				rel="noreferrer"
			>
				<Avatar
					sx={{
						position: "absolute",
						bottom: 30,
						right: 30,
						width: "10rem",
						height: "auto",
						marginRight: "5px",
						borderRadius: 0,
						zIndex: 1,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					}}
					src={icon}
				/>
			</a>
			<LoadingGeneric />
			<LoadingMoreGeneric />
			{projects.project &&
				Object.keys(projects?.project).length !== 0 &&
				auth.user &&
				Object.keys(auth?.user).length !== 0 && <Chat />}
			{!check && (
				<>
					<MyAccountButton />
					<NewSteps />
				</>
			)}
			<Grid
				container
				minHeight={check ? "calc(100% - 70px)" : "100%"}
				width="100%"
				sx={{
					// backgroundImage: `url(${backgroundImg})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "contain",
				}}
			>
				{check && (
					<Stack zIndex={"appBar"} sx={{ width: "100%" }}>
						<AppBar
							position="static"
							sx={{
								height: "70px",
								width: "100%",
								backgroundColor: theme.palette.electricBlue.secondary,
								justifyContent: "center",
							}}
						>
							<Toolbar sx={{ paddingX: "40px" }}>
								<Stack
									sx={{ justifyContent: "space-between", width: "100%" }}
									direction={"row"}
									spacing={1}
								>
									<IconButton
										size="large"
										edge="start"
										aria-label="menu"
										sx={{
											visibility:
												Object.keys(user).length > 0 ? "visible" : "hidden",
											color: "#c6eff5",
										}}
										onClick={toggleSidebarMenu}
									>
										<Avatar variant="hamburguer" src={hamburguerIcon} />
									</IconButton>
									<Link href="/">
										<Avatar
											variant="square"
											sx={{
												height: "45px",
												width: "fit-content",
											}}
											src={advanteIcongraph}
										/>
									</Link>
									<MyAccountMobile />
								</Stack>
							</Toolbar>
						</AppBar>
						<SwipeableDrawer
							anchor={"left"}
							open={openSidebarMenu}
							onClose={toggleSidebarMenu}
							disableDiscovery
						>
							<Box
								sx={{
									backgroundColor: theme.palette.electricBlue.secondary,
									display: "flex",
									justifyContent: "space-between",
									padding: "10px",
									alignItems: "center",
								}}
							>
								<Link href="/">
									<Avatar
										variant="square"
										sx={{
											height: "45px",
											width: "fit-content",
										}}
										src={advanteIcongraph}
									/>
								</Link>
								<IconButton
									sx={{
										color: "#c6eff5",
									}}
									onClick={toggleSidebarMenu}
								>
									<MenuIcon fontSize="large" />
								</IconButton>
							</Box>
							<Box
								sx={{
									width: "80vw",
									height: "100vh",
									paddingY: "20px",
									position: "relative",
									// backgroundColor: theme.palette.electricBlue.secondary,
								}}
							>
								{Object.keys(projects.projects).length > 0 && (
									<StepperComponent
										sx={{
											marginTop: "20%",
										}}
										key={projects.project.uid}
										name={projects.project.projectName}
										project={projects.project}
										toggleSidebarMenu={toggleSidebarMenu}
									/>
								)}
								{projects.projects.map((project) => (
									<InactiveProject
										// onClick={() => dispatch(setActiveProjectById(project.uid))}
										key={project.uid}
										project={project}
										color="initial"
									>
										{project.uid}
									</InactiveProject>
								))}
							</Box>
						</SwipeableDrawer>
					</Stack>
				)}
				{children}
			</Grid>
		</Box>
	);
}

MainApp.propTypes = {
	children: PropTypes.node.isRequired,
};
