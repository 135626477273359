import { Navigate, Outlet } from "react-router-dom";

import { AuthState, getClientById } from "../hooks/auth";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../features/auth/authSlice";
import { getProjectsByUserId } from "../hooks/projects";
import {
	setActiveProjectById,
	setProjects,
} from "../features/project/projectSlice";
import LoadingScreen from "../components/persistorLoadingScreen";
/**
 * Renders the private routes of the application, including user authentication and authorization.
 * @returns {JSX.Element} The JSX element representing the private routes of the application.
 */
const PrivateRoutes = () => {
	const user = AuthState();
	const dispatch = useDispatch();
	if (user === null) {
		return <Navigate to="/start" />;
	}
	if (Object.keys(user).length === 0) {
		return <LoadingScreen />;
	}
	if (user !== null && Object.keys(user).length !== 0) {
		dispatch(setToken(user.accessToken));
		/**
		 * Gets the user data and project data from the database and dispatches it to the Redux store.
		 * @returns {Promise<void>} A Promise that resolves when the user and project data has been fetched and dispatched.
		 */
		const getUser = async () => {
			const User = await getClientById(user.uid, user.accessToken);

			Object.assign(User, {
				createdAt: parseInt(user.metadata.createdAt / 1000),
				lastLoginAt: parseInt(user.metadata.lastLoginAt / 1000),
			});
			dispatch(setUser(User));

			const projects = await getProjectsByUserId(user.uid, user.accessToken);
			dispatch(setProjects(projects));
			dispatch(setActiveProjectById(User.activeProject));
		};

		getUser();
		return <Outlet />;
	}
};

export { PrivateRoutes };
