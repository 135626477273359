import React from "react";

//! MUI IMPORTS
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
// import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
// import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
// import FormControlLabel from "@mui/material/FormControlLabel";

import {
	addFile,
	addTask,
	// changeNda,
	purgeRequests,
	removeNonUploadedFiles,
} from "../../features/authedRequests/authedRequestsSlice";
import Stack from "@mui/material/Stack";
// import { TransitionGroup } from "react-transition-group";
import {
	ref,
	getStorage,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { MainPage } from "../templates/MainPage";
import { Task } from "./Task"; //TODO[x] <--- This is the component you want to refactor
import AddIcon from "../../Sources/Icons/AddIcon.svg";
import { BackdropContext } from "../../Context/BackdropContext";
import { updateExclusiveChat } from "../../hooks/chat";

// import UncheckedTasks from "../../Sources/Icons/UncheckedTasks.svg";
// import CheckedTasks from "../../Sources/Icons/CheckedTasks.svg";
import { RequierementsContext } from "../../Context/RequierementsContext";
import { createRequests, editQuoteReady } from "../../hooks/projects";
import {
	exclusiveChatChange,
	handleComplete,
	setActiveStep,
	setRequests,
	setTickets,
	updateProject,
} from "../../features/project/projectSlice";
import { persistor } from "../../Store";
import { ChatContext } from "../../Context/ChatContext";
// import { ChatQuestionnarie } from "./ChatQuestionnarie";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import SendedTask from "./sendedTasks";
import { Typography } from "@mui/material";

export function RequierementsDescription() {
	const dispatch = useDispatch();
	const { requierementsData, setProgressById, setTotal, reset } =
		React.useContext(RequierementsContext);
	const requestSlice = useSelector((state) => state.authedRequestsSlice);
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);
	const { tasks /* , nda */ } = requestSlice;
	const storage = getStorage();
	const { dispatchChat } = React.useContext(ChatContext);

	const { project } = useSelector((state) => state.projectsRedux);
	const { user, token } = useSelector((state) => state.auth);

	React.useEffect(() => {
		setProgress((requierementsData.progress / requierementsData.total) * 100);
	}, [requierementsData.progress, requierementsData.total]);

	async function changeQuoteReady() {
		await editQuoteReady(project.uid, false, token);
	}

	async function UpdateExclusiveChat(value) {
		await updateExclusiveChat(value, project.uid, token);
	}

	const q = doc(db, "projects", project.uid);

	React.useEffect(() => {
		const unsubscribe = onSnapshot(q, (doc) => {
			const project = doc.data();
			if (project.quoteReady) {
				dispatch(setTickets(project.tickets));
				if (!project.completedSteps[3]) {
					dispatch(handleComplete(3));
				} else {
					dispatch(setActiveStep(4));
				}
				changeQuoteReady();
				UpdateExclusiveChat(false);
				dispatch(exclusiveChatChange(false));
				dispatch(updateProject());
			}
		});
		// setDisabled(false);

		dispatchChat({
			type: "SHOW_CHAT",
		});
		if (project?.exclusiveChat) {
			dispatchChat({
				type: "CHANGE_USER",
				payload: {
					ClientActiveChat: project?.activeChat?.user,
					ChatID: project.uid?.concat(project?.activeChat?.user),
					ClientActiveChatUsername: project?.activeChat?.displayName,
					photoURL: project?.activeChat?.photoURL,
				},
			});
		}
		changeLoadingBackdrop(true);
		dispatch(removeNonUploadedFiles());
		changeLoadingBackdrop(false);

		return () => {
			reset();
			unsubscribe();
		};
	}, []);

	const allTaskHaveAtLeast1Character = tasks.every((obj) => {
		return obj.description.trim().length > 0;
	});

	async function sendTasks() {
		if (tasks.length === 0) {
			enqueueSnackbar("you need to add at least one task", {
				variant: "error",
			});
			return;
		}
		if (!allTaskHaveAtLeast1Character) {
			enqueueSnackbar("all tasks need description", {
				variant: "error",
			});
			return;
		}
		await updateExclusiveChat(true, project.uid, token);
		dispatch(exclusiveChatChange(true));

		// const { project } = projects;

		/* 			
		const requests = await createRequests(request, auth.token);*/

		// send files to storage
		if (requierementsData.attachments.length > 0) {
			setLoadingBackdrop(true);
			setTotal(100 * requierementsData.attachments.length);
			const imgs = [];

			requierementsData.attachments.forEach((file) => {
				const storageRef = ref(
					storage,
					`requests/${file.taskUuid}/${file.file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file.file);
				uploadTask.on(
					"state_changed",
					(snapshot) => {
						setProgressById(
							file.id,
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100
						);
					},
					(error) => {
						console.error(error);
					},
					async () => {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						imgs.push({
							url: downloadURL,
							ref: uploadTask.snapshot.ref.fullPath,
							taskUuid: file.taskUuid,
							id: file.id,
						});
						if (imgs.length === requierementsData.attachments.length) {
							dispatch(addFile({ files: imgs }));
							setLoadingBackdrop(false);
							setProgress(0);
							reset();

							const sendingTasks = tasks.map((task) => {
								return {
									description: task.description,
									attachments: task.attachments ?? [],
									uuid: task.uuid,
									createdAt: task.createdAt,
								};
							});

							const request = {
								projectUid: project.uid,
								tasks: sendingTasks,
								projectName: project.projectName,
								completedSteps: project.completedSteps,
								clientName: project.clientName,
								clientUid: user.uid,
							};

							const requests = await createRequests(request, token);
							persistor.pause();
							dispatch(purgeRequests());
							persistor.purge();
							persistor.persist();
							dispatch(setRequests(requests));
							dispatch(exclusiveChatChange(true));
							UpdateExclusiveChat(true);
						}
					}
				);
			});
		} else {
			const sendingTasks = tasks.map((task) => {
				return {
					description: task.description,
					attachments: [],
					uuid: task.uuid,
					createdAt: task.createdAt,
				};
			});

			const request = {
				projectUid: project.uid,
				tasks: sendingTasks,
				projectName: project.projectName,
				completedSteps: project.completedSteps,
				clientName: project.clientName,
				clientUid: user.uid,
			};

			const requests = await createRequests(request, token);
			persistor.pause();
			persistor.purge();
			dispatch(purgeRequests());
			persistor.persist();
			dispatch(setRequests(requests));
			UpdateExclusiveChat(true);
			dispatch(exclusiveChatChange(true));
		}
	}

	const [loadingBackdrop, setLoadingBackdrop] = React.useState(false);
	const [progress, setProgress] = React.useState(0);

	// Work description
	// You can separate your requirement in multiple tasks and will
	// receive a quote for each one.

	const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up(1536));
	const isExtraLargeScreen = useMediaQuery((theme) =>
		theme.breakpoints.up(1540)
	);
	return (
		<MainPage
			title="Make your software request"
			subtitle="And get it done right now! No meetings are required."
			width="75%"
			header={true}
			show={false}
			sx={{
				marginTop: "130px",
				"& .css-1e57o7p-MuiPaper-root": {
					borderRadius: "10px",
					background: "rgba(17, 32, 49, 0.32)",
					backdropFilter: "blur(2px)",
					padding: "12px 19px 15px 19px",
					boxShadow: "none",
					marginTop: isExtraLargeScreen
						? "200px"
						: isLargeScreen
							? "150px"
							: "140px",
				},
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					paddingLeft: "293px",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loadingBackdrop}
			>
				<Stack alignItems="center" spacing={1}>
					Uploading your files... please wait
					<CircularProgress
						variant="determinate"
						value={progress}
						color="inherit"
					/>
				</Stack>
			</Backdrop>
			<Box
				sx={{
					overflowY: "visible",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Stack spacing={2} marginBottom={2}>
					{project.requests?.map((request, index) => (
						<SendedTask key={request.uuid} task={request} index={index} />
					))}
				</Stack>
				<Stack spacing={2} marginBottom={2}>
					{/* <TransitionGroup> */}
					{tasks?.map((task, index) => (
						<>
							{/* <Collapse sx={{ marginBottom: "15px" }} key={task.uuid}> */}
							<Task
								key={task.uuid}
								// index={index}
								task={task}
								disabled={false}
								taskIndex={project.requests?.length + index}
							/>
							{/* </Collapse> */}
						</>
					))}
					{/* </TransitionGroup> */}
				</Stack>
				<Button
					disableElevation
					id="add-task"
					variant="taskButton"
					onClick={() => dispatch(addTask())}
					startIcon={<Avatar variant="iconTaskSmaller" src={AddIcon} />}
					sx={{
						alignSelf: "flex-start",
						marginBottom: "30px",
						padding: "6px 2px",
					}}
				>
					Add task
				</Button>
			</Box>
			<Stack
				sx={{
					alignItems: "flex-end",
					justifyContent: "center",
					paddingBottom: "calc(5% + 64px)",
				}}
			>
				<Stack direction="row" spacing={1}>
					<Button
						sx={{
							// marginTop: "50px",
							alignSelf: "flex-end",
							opacity: allTaskHaveAtLeast1Character && tasks.length > 0 ? 1 : 0,
							visibility:
								allTaskHaveAtLeast1Character && tasks.length > 0
									? "visible"
									: "hidden",
							transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
						}}
						variant="primaryButton"
						onClick={sendTasks}
					>
						Send my request
					</Button>
					{project.completedSteps[3] && (
						<Button
							sx={{
								marginTop: "50px",
								alignSelf: "flex-end",
							}}
							variant="primaryButton"
							onClick={() => {
								dispatch(setActiveStep(4));
								dispatch(updateProject());
							}}
						>
							Next
						</Button>
					)}
				</Stack>
				{/*
				<FormControlLabel
					disabled={true}
					control={
						<Checkbox
							icon={<Avatar variant="checkboxTasks" src={UncheckedTasks} />}
							checkedIcon={
								<Avatar variant="checkboxTasks" src={CheckedTasks} />
							}
							onChange={() => dispatch(changeNda(!nda))}
							checked={project.requireNda}
						/>
					}
					sx={{
						"& .MuiTypography-root": {
							fontSize: "1.2rem",
						},
						margin: 0,
					}}
					label="I want to sign an NDA"
				/> */}
				{project?.exclusiveChat && (
					<Stack width="100%" alignItems="center">
						<Typography variant="descriptiveText">
							We received your request and are working on the quote. You can
							close this site now.
						</Typography>
					</Stack>
				)}
			</Stack>

			{/* {project?.exclusiveChat && <ChatQuestionnarie />} */}
		</MainPage>
	);
}
