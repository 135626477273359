import React from "react";

import { onSnapshot, doc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { Accordion } from "../templates/chatComponents";

//! MUI IMPORT

import { db } from "../../firebase";
import { ChatContext } from "../../Context/ChatContext";

//! ICON IMPORT
import ChatHeader from "./components/chatHeader";
import ChatBody from "./components/chatBody";

export function Chat(props) {
	// const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);

	const { chatData, dispatchChat } = React.useContext(ChatContext);
	const [messages, setMessages] = React.useState([]);

	/* 
		Disclaimer:
		I need to refactor all this code, it's a mess, but it works.
		Also, I need to add a loading state to the chat, so it doesn't look like it's broken.
		but i have not time for that right now, so i'll leave it like this for now.
		i pray for the future me that will have to refactor this or the poor soul that will have to work with this, im so sorry.
		- Matzu

		Update: I'm the future me, and i'm so sorry for the past me, i'll start refactoring this right now. 1-jun-2023@9:31

		Update: I finally refactored this, it's still a mess, but it's a cleaner mess now. 1-jun-2023@14:15
	*/

	React.useEffect(() => {
		const { project } = projects;
		const { uid } = project;
		const unsub = onSnapshot(doc(db, "generalChat", uid), (doc) => {
			if (!doc.exists()) {
				return;
			}

			if (chatData?.ClientActiveChat === "") {
				setMessages([]);
				return;
			}
			setMessages(doc.data()?.messages);
		});
		const unsuscribeProjects = onSnapshot(doc(db, "projects", uid), (doc) => {
			if (doc?.data()?.activeChat?.user === "" || doc.exists() === false) {
				dispatchChat({
					type: "CHANGE_USER",
					payload: {
						ClientActiveChat: "",
						ChatID: "",
						ClientActiveChatUsername: "",
						photoURL: "",
					},
				});
			} else {
				dispatchChat({
					type: "CHANGE_USER",
					payload: {
						ClientActiveChat: doc?.data()?.activeChat?.user,
						ChatID: projects.project.uid?.concat(doc?.data()?.activeChat?.user),
						ClientActiveChatUsername: doc?.data()?.activeChat?.displayName,
						photoURL: doc?.data()?.activeChat?.photoURL,
					},
				});
			}
		});
		return () => {
			unsub();
			unsuscribeProjects();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projects.project?.uid]);

	React.useEffect(() => {
		const unsub = onSnapshot(
			doc(db, "generalChat", projects?.project?.uid),
			(doc) => {
				if (!doc.exists()) {
					return;
				}

				if (chatData.ClientActiveChat === "") {
					setMessages([]);
					return;
				}
				setMessages(doc.data().messages);
			}
		);
		return () => {
			unsub();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatData?.ClientActiveChat]);

	return (
		<>
			{messages.length > 0 && (
				<Accordion defaultExpanded Hidden={chatData.Hidden}>
					<ChatHeader messages={messages} />
					<ChatBody messages={messages} questionnarie={props.questionnarie} />
				</Accordion>
			)}
		</>
	);
}
