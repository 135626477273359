import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
} from "@mui/material";
import { MyProfile } from "./MyProfile";
// import { MySettings } from "./MySettings";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { BillingMobile } from "./BillingPayments/mobile";
import ExpandIcon from "../../Sources/Icons/ExpandIcon.svg";
import ExpandIconDisabled from "../../Sources/Icons/ExpandIconDisabled.svg";

export function MobileAccordions() {
	const tabs = {
		"My profile": {
			id: "my-profile",
			content: <MyProfile />,
		},
		/* 
		"My Settings": {
			id: "my-settings",
			content: <MySettings />,
		},
		 */
		"Privacy policy": {
			id: "privacy-policy",
			content: <PrivacyPolicy />,
		},
		"Billing and payments": {
			id: "billing-payments",
			content: <BillingMobile />,
		},
	};

	function CustomAccordion({ title, content, key }) {
		const [expanded, setExpanded] = React.useState(false);

		function handleChange(expanded) {
			setExpanded(expanded);
		}

		return (
			<Accordion
				elevation={0}
				sx={{
					"&.MuiAccordion-root": {
						backgroundColor: "transparent",
					},
					"&:before": {
						display: "none",
					},
				}}
				onChange={(e, expanded) => handleChange(expanded)}
				key={key}
			>
				<AccordionSummary
					sx={{
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "15px",
						color: expanded ? "#285D69" : "#c0c0c0",
					}}
					expandIcon={
						<Avatar
							variant="sidebarProjectIcon"
							src={expanded ? ExpandIcon : ExpandIconDisabled}
						/>
					}
				>
					{title}
				</AccordionSummary>
				<AccordionDetails>{content}</AccordionDetails>
			</Accordion>
		);
	}

	return (
		<Box>
			{Object.entries(tabs).map(([title, { id, content }]) => (
				<CustomAccordion key={id} title={title} content={content} />
			))}
		</Box>
	);
}
