import React from "react";

import Linkify from "react-linkify";

//! MUI IMPORT
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MediaMessageRight, MessageRight } from "./messageRight";
import { MediaMessageLeft, Messageleft } from "./messageLeft";
import { Stack } from "@mui/material";

export function ChatBubbleRight({ message, name, img }) {
	return (
		<Linkify
			componentDecorator={(decoratedHref, decoratedText, key) => (
				<a target="blank" href={decoratedHref} key={key}>
					{decoratedText}
				</a>
			)}
		>
			<Stack
				direction="row"
				sx={{
					marginRight: "5px",
					marginLeft: "auto",
					justifyContent: "flex-end",
					width: "calc(95% - 5px)",
				}}
			>
				{img ? (
					<MediaMessageRight message={message} img={img} name={name} />
				) : (
					<MessageRight message={message} name={name} />
				)}
			</Stack>
		</Linkify>
	);
}

export function ChatBubbleLeft({ message, name, img, type }) {
	const [loaded, setLoaded] = React.useState(false);

	const ref = React.useRef();

	React.useEffect(() => {
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
		setLoaded(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message && loaded]);
	return (
		<Linkify
			componentDecorator={(decoratedHref, decoratedText, key) => (
				<a target="blank" href={decoratedHref} key={key}>
					{decoratedText}
				</a>
			)}
		>
			<Box
				sx={{
					width: "calc(95% - 5px)",
					display: "flex",
					flexDirection: "row",
					marginLeft: "5px",
				}}
			>
				{img ? (
					<MediaMessageLeft message={message} img={img} name={name} />
				) : type === "system" ? (
					<Typography
						variant="chatLeft"
						sx={{
							width: "100%",
							color: "#c0c0c0",
							textAlign: "center",
							alignSelf: "center",
						}}
						ref={ref}
					>
						{message}
					</Typography>
				) : (
					<Messageleft message={message} name={name} />
				)}
			</Box>
		</Linkify>
	);
}
