import { useSelector } from "react-redux";
import { RequierementsDescription } from "../../components/RequirementsDescription";
import LoadingGeneric from "../../components/BackdropLoading";

export default function RequierementsDescriptionPage() {
	const projects = useSelector((state) => state.projectsRedux);
	if (projects?.project?.uid === undefined) {
		return <LoadingGeneric />;
	} else {
		return (
			<>
				{Object.keys(projects?.project).length > 0 && (
					<RequierementsDescription />
				)}
			</>
		);
	}
}
