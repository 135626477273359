/* Test Component */
import React from "react";
import { quotes } from "../ReadyApprove/datosPrueba";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";

export const ConfirmQuote = () => {
	const [filterQuotes, setFilterQuotes] = React.useState([]);

	const filterArray = () => {
		const approve = true;
		const newQuoteArray = quotes.filter((obj) => {
			return Object.values(obj).indexOf(approve) > -1;
		});
		setFilterQuotes(newQuoteArray);
	};

	React.useEffect(() => {
		filterArray();
	}, []);

	return (
		<div>
			<Grid
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				container
				spacing={2}
				style={{ paddingLeft: "13%", paddingRight: "13%", paddingTop: "3%" }}
			>
				<Grid item xs={3}>
					<Typography variant="h5" color="initial">
						<b>Ready to approve</b>
					</Typography>
				</Grid>
			</Grid>
			<Grid
				direction="row"
				justifyContent="center"
				alignItems="center"
				container
				spacing={1}
				className="containerApprove"
			>
				{filterQuotes.map((quote, index) => {
					return (
						<Grid
							style={{ paddingBottom: "1%" }}
							key={quote.id}
							item
							xs={10}
							sm={10}
							md={10}
							lg={9}
							xl={9}
						>
							<Card>
								<CardContent>
									<section>
										<Typography variant="subtitle1" color="initial">
											<b>Requirement</b> {index + 1})
										</Typography>
										<Typography variant="subtitle1" color="initial">
											<b>Title</b> {quote.title}
										</Typography>
										<Typography variant="subtitle1" color="initial">
											<b>Description</b>
										</Typography>
										<Grid style={{ paddingTop: "1%" }} container spacing={1}>
											<Grid item xs={12} sm={12} md={12} lg={3}>
												<div>
													<Typography variant="subtitle1" color="initial">
														{quote.description}
													</Typography>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={9}>
												<div>
													<div>
														<Typography variant="subtitle1" color="initial">
															Platform(s):
															{quote.platforms.map((platform, index) => {
																return (
																	<Typography
																		key={index}
																		variant="subtitle1"
																		color="initial"
																	>
																		{platform}
																	</Typography>
																);
															})}
														</Typography>
													</div>
													<Typography variant="subtitle1" color="initial">
														Type: {quote.type}
													</Typography>
													<Typography variant="subtitle1" color="initial">
														Intgration(s): {quote.integrations}
													</Typography>
												</div>
											</Grid>
										</Grid>
										<div style={{ paddingTop: "1%" }}>
											<Typography variant="subtitle1" color="initial">
												Aditional Work:
											</Typography>
											<Typography variant="subtitle1" color="initial">
												{quote.aditionaWork}
											</Typography>
										</div>
										<Grid
											direction="row"
											justifyContent="center"
											alignItems="center"
											container
											spacing={1}
											style={{ paddingTop: "1%" }}
										>
											<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
												<Typography variant="subtitle1" color="initial">
													The work is completed and live.
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
												<Button variant="contained" color="primary">
													{/* onClick={() => quote.isApprove = true} */}
													Confirm
												</Button>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
												<Typography variant="subtitle1" color="initial">
													<ins>I need something else</ins>
												</Typography>
											</Grid>
										</Grid>
									</section>
								</CardContent>
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};
