import { Stack, Typography, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SharedCredentials({ platform }) {
	return (
		<>
			<Stack direction="row" spacing={4}>
				<Typography
					variant="descriptiveText"
					sx={{
						fontWeight: "bold",
						color: useTheme().palette.lightBlue.main,
					}}
				>
					{platform.name}
				</Typography>
				<Stack spacing={1} direction="row">
					<CheckCircleIcon sx={{ fontSize: 20, color: "#94A3CA" }} />
					<Typography sx={{ color: "#94A3CA", fontSize: "12px" }}>
						Completed.{" "}
						<span className="spanVLE">
							Your credentials will be deleted 24 hours after project completion
						</span>
					</Typography>
				</Stack>
			</Stack>
		</>
	);
}
