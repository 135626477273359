import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { useTheme } from "@emotion/react";
import { getById } from "../../backend/services";

import { MainPage } from "../templates/MainPage";
import { DesktopTabs } from "./desktop";
import { MobileAccordions } from "./mobile";
import { useSelector } from "react-redux";

import { isMobile } from "../../hooks/utils";


function MyAccountTabs() {
	const check = isMobile();

	const [company, setCompany] = React.useState("");
	const auth = useSelector((state) => state.auth);
	// const { auth } = UserAuth();
	const theme = useTheme();

	useEffect(() => {
		getById({
			route: "client/getClientById",
			id: auth.user.uid,
		}).then((res) => {
			setCompany(res.company);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		auth.user && (
			<MainPage
				header={true}
				title="My Account"
				// show={true}
			>
				<Stack
					direction={{ xs: "column", lg: "row" }}
					sx={{
						mb: 1,
						[theme.breakpoints.up("lg")]: { gap: "15%" },
						marginBottom: "3%",
					}}
				>
					<Box
						sx={{
							[theme.breakpoints.down("md")]: {
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								marginTop: "30px",
							},
							[theme.breakpoints.up("lg")]: {
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								gap: "20px",
							},
						}}
					>
						<Typography gutterBottom variant="titles" sx={{ fontSize: "20px" }}>
							{auth.user.displayName || auth.user.email}
						</Typography>
						<Typography
							sx={{ color: "#C6EFF5", fontSize: "20px" }}
							gutterBottom
							variant="titles"
						>
							{company}
						</Typography>
					</Box>
				</Stack>
				{check ? <MobileAccordions /> : <DesktopTabs />}
			</MainPage>
		)
	);
}
export default MyAccountTabs;
