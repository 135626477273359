//! React imports
import React from "react";
import { useState } from "react";

//! Firebase imports
import { db } from "../../firebase";
import {
	collection,
	query,
	onSnapshot,
	getDocs,
	where,
} from "firebase/firestore";

//! MUI imports
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

//! Custom imports
import DataGridComponent from "./components/DataGridComponent";
import backgroundImg from "../../Sources/images/CURVE20.svg";

export default function CustomClient() {
	const theme = useTheme();
	const [tickets, setTickets] = useState([]);

	React.useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const init = async () => {
		const projects = await getDocs(collection(db, "projects"));
		let ticketsdb = [];
		//TODO Cambiar el project ID por el del usuario
		const p = query(
			collection(db, "tickets"),
			where("projectId", "==", "vXwGaEfPDmAYoJSEklwd")
		);
		onSnapshot(p, (querySnapshot) => {
			ticketsdb = querySnapshot.docs.map((doc) => {
				const project = projects.docs.find(
					(project) => project.id === doc.data().projectId
				);
				let ticket = { ...doc.data(), id: doc.id };
				if (project !== undefined && project.data() !== undefined) {
					ticket.project = project.data();
				} else {
					//TODO temporal, supongo
					ticket.project = { name: "No project" };
				}
				const nodes = getDocs(collection(db, "tickets", ticket.id, "nodes"));
				if (nodes.docs !== undefined) {
					ticket.nodes = nodes.docs.map((doc) => {
						return { ...doc.data(), id: doc.id };
					});
				}
				const platforms = getDocs(
					collection(db, "tickets", ticket.id, "platforms")
				);
				if (platforms.docs !== undefined) {
					ticket.platforms = platforms.docs.map((doc) => {
						return { ...doc.data(), id: doc.id };
					});
				}
				return ticket;
			});
			setTickets(ticketsdb);
		});
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",

				background: `linear-gradient(180deg, ${theme.palette.backgroundGradient.first} 0%, ${theme.palette.backgroundGradient.second}) 100%`,
			}}
		>
			<Box
				sx={{
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "contain",
					backgroundAttachment: "fixed",
					backgroundImage: `url(${backgroundImg})`,
				}}
			>
				<Box
					sx={{
						placeSelf: "center",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "97%",
						margin: "0 auto",
					}}
				>
					<DataGridComponent tickets={tickets} />
				</Box>
			</Box>
		</Box>
	);
}
