import React from "react";
import { ChatContext } from "../../Context/ChatContext";

import { doc, onSnapshot } from "firebase/firestore";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";

import "./WorkInProgress.css";

import { MainPage } from "../templates/MainPage";
import { db } from "../../firebase";
import {
	handleComplete,
	setActiveStep,
	setTickets,
	updateProject,
} from "../../features/project/projectSlice";
import TaskItem from "./taskItem";
import TaskItemSkeleton from "./skeleton";
import SharedCredentials from "./sharedCredentials";
import { Typography } from "@mui/material";

export function WorkInProgress() {
	const projects = useSelector((state) => state.projectsRedux);
	const { dispatchChat } = React.useContext(ChatContext);

	React.useEffect(() => {
		dispatchChat({ type: "SHOW_CHAT" });
	}, []);

	const q = doc(db, "projects", projects?.project?.uid);
	const dispatch = useDispatch();

	const sharedCredentials = projects.project.platforms.filter(
		(platform) =>
			platform.sharedCredentials === true && platform.requested === true
	);
	const [allTasksCompleted, setTasksCompleted] = React.useState(false);

	function complete() {
		if (!projects.project.completedSteps[9]) {
			dispatch(handleComplete(9));
		} else {
			dispatch(setActiveStep(10));
		}
		dispatch(updateProject());
	}

	React.useEffect(() => {
		const unsub = onSnapshot(q, (snapshot) => {
			const data = snapshot.data();
			const { tickets } = data;
			dispatch(setTickets(tickets));
			const filteredTickets = tickets.filter(
				(ticket) => ticket.payment.status === "completed"
			);
			const allObjectsMeetCondition = filteredTickets.every((obj) => {
				return obj.status === 4;
			});

			if (allObjectsMeetCondition) {
				// Update the value outside the array
				setTasksCompleted(true);
			} else {
				setTasksCompleted(false);
			}
		});
		return () => {
			unsub();
		};
	}, []);

	return (
		<MainPage
			header={true}
			title="Work in progress"
			subtitle={
				projects.project.projectLeader &&
				`Under the supervision of ${projects.project.projectLeader.displayName}, Project manager`
			}
			backFunction={8}
			width="45%"
			avatarImg={
				projects.project?.projectLeader?.photoURL &&
				projects.project.projectLeader?.photoURL
			}
			avatar={true}
		>
			{sharedCredentials.length > 0 && (
				<>
					<Typography variant="titles">Shared credentials:</Typography>
					<Stack>
						{sharedCredentials.map((platform) => (
							<SharedCredentials key={platform.uid} platform={platform} />
						))}
					</Stack>
				</>
			)}
			<Stack
				sx={{
					marginTop: 1,
					paddingBottom: 4,
				}}
			>
				{projects.project.tickets.length > 0 ? (
					<>
						{projects.project.tickets.map((ticket) => (
							<TaskItem key={ticket.uuid} task={ticket} />
						))}
					</>
				) : (
					<TaskItemSkeleton />
				)}
				<Stack marginTop={4} alignItems="center" justifyContent="center">
					<Button
						disabled={!allTasksCompleted}
						variant="primaryButton"
						sx={{
							width: "fit-content",
							display: allTasksCompleted ? "inline-flex" : "none",
						}}
						fullWidth
						onClick={allTasksCompleted ? complete : null}
					>
						Continue
					</Button>
				</Stack>
			</Stack>
		</MainPage>
	);
}
