import React from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { doc, onSnapshot } from "firebase/firestore";

import { db } from "../../firebase";
import { MainPage } from "../templates/MainPage";
import QuoteItem from "./item";
import { BackdropContext } from "../../Context/BackdropContext";
import { postQuotes } from "../../hooks/projects";

import {
	handleComplete,
	setActiveStep,
	setMembers,
	setProjectLeader,
	setTickets,
	updateProject,
} from "../../features/project/projectSlice";
import BottomValues from "./bottomValues";
import PaidItem from "./payedItem";
import PayedItemMobile from "./PayedItemMobile";

import { isMobile } from "../../hooks/utils";

export function Quote() {
	const theme = useTheme();

	const checked = isMobile();

	const dispatch = useDispatch();

	const projects = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);

	const [tasks, setTasks] = React.useState([]);

	const [payedTickets, setPayedTickets] = React.useState([]);

	const [totals, setTotals] = React.useState({});

	const q = doc(db, "projects", projects?.project?.uid);

	React.useEffect(() => {
		const unsub = onSnapshot(q, (doc) => {
			const docData = doc.data();
			dispatch(setMembers(docData.members));
			dispatch(setProjectLeader(docData.projectLeader));
			const payedTickets = [];
			const tasks = [];
			const tickets = [];
			let totalCost = 0;
			let totalTime = 0;

			docData.tickets.forEach((ticket) => {
				const { payment } = ticket;
				const { payedAt, ...payment2 } = payment;
				false && console.log(payedAt);
				const ticket2 = Object.assign(ticket, { payment: payment2 });
				tickets.push(ticket2);
				if (ticket.payment.status === "completed") {
					totalCost += ticket2.price;
					totalTime += ticket2.estimatedTime;
					payedTickets.push(ticket2);
				} else {
					tasks.push(ticket);
				}
				setTotals({ totalPrice: totalCost, estimatedTime: totalTime });
				setTasks(tasks);
				setPayedTickets(payedTickets);
			});
			dispatch(setTickets(tickets));
		});
		return () => {
			unsub();
		};
	}, [projects?.project?.uid]);

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);
	async function sendQuotes() {
		try {
			if (projects.project?.quotes?.length === 0) {
				enqueueSnackbar("Please select at least one ticket", {
					variant: "error",
				});
				return;
			} else {
				changeLoadingBackdrop(true);
				await postQuotes(projects.project, auth.token);
				if (!projects.project.completedSteps[4]) {
					dispatch(handleComplete(4));
				} else {
					dispatch(setActiveStep(5));
				}
				dispatch(updateProject());
				changeLoadingBackdrop(false);
			}
		} catch (error) {
			enqueueSnackbar(error, { variant: "error" });
		}
	}

	return (
		<MainPage
			header={true}
			title="Quote"
			description="Please select the tasks you want us to do and proceed to pay"
			width="75%"
			show={true}
			backFunction={3}
		>
			{/* <Stack> */}
			<Stack
				sx={{
					[theme.breakpoints.down("xl")]: {
						marginTop: "45px",
					},
					[theme.breakpoints.up("xl")]: {
						marginTop: "50px",
					},
				}}
			>
				{payedTickets?.map((item, index) => (
					<>
						{checked ? (
							<PayedItemMobile ticket={item} index={index} />
						) : (
							<PaidItem key={item.uuid} ticket={item} index={index} />
						)}
					</>
				))}
				{payedTickets.length > 0 && (
					<Stack
						sx={{
							placeSelf: "flex-end",
							alignItems: "flex-end",
							// marginTop: "60.5px",
							marginBottom: "76.5px",
							[theme.breakpoints.up("lg")]: {
								width: "100%",
							},
							[theme.breakpoints.up("xl")]: {
								width: "90%",
							},
						}}
					>
						<BottomValues quote={totals} />
					</Stack>
				)}
				{tasks.length > 0 &&
					tasks?.map((item, index) => (
						<QuoteItem
							key={item.uuid}
							ticket={item}
							index={(index += payedTickets.length)}
						/>
					))}
				<Stack
					sx={{
						placeSelf: "flex-end",
						alignItems: "flex-end",
						// marginTop: "60.5px",
						marginBottom: "76.5px",
					}}
				>
					{tasks.length > 0 && (
						<BottomValues quote={projects?.project?.quote} />
					)}
					<Stack direction="row" spacing={1}>
						{payedTickets.length > 0 && (
							<Button
								variant="primaryButton"
								onClick={() => {
									dispatch(setActiveStep(8));
									dispatch(updateProject());
								}}
							>
								Next
							</Button>
						)}
						{tasks.length > 0 && (
							<Button
								variant="primaryButton"
								onClick={sendQuotes}
								sx={{
									marginTop: 1,
									width: "100%",
									[theme.breakpoints.down("md")]: {
										width: "100%",
									},
									maringTop: "29px",
								}}
							>
								Proceed to pay
							</Button>
						)}
					</Stack>
				</Stack>
			</Stack>
			{/* </Stack> */}
		</MainPage>
	);
}
