import React from "react";

export const MyAccountContext = React.createContext();
export const MyAccountTabsContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		value: 0,
	};
	const MyAccountReducer = (state, action) => {
		switch (action.type) {
		case "GO_TAB":
			return {
				value: action.payload,
			};
		default:
			return null;
		}
	};
	const [state, dispatchMyAccount] = React.useReducer(
		MyAccountReducer,
		INITIAL_STATE
	);
	return (
		<MyAccountContext.Provider
			value={{ MyAccountData: state, dispatchMyAccount }}
		>
			{children}
		</MyAccountContext.Provider>
	);
};
