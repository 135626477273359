import React from "react";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { doc, onSnapshot } from "firebase/firestore";

//! MUI ICONS

//! ICONS

import "../RequirementsDescription/Loader.css";
import "./MeetTheTeam.css";
import { db } from "../../firebase";

import { ChatContext } from "../../Context/ChatContext";
import { BackdropContext } from "../../Context/BackdropContext";
// import { DialogContent } from "./modalContent";

import { MainPage } from "../templates/MainPage";

import questionVariant from "../../Sources/Icons/questionVariant.svg";
import PlatformCredentials from "./platformCredentials";
import {
	handleComplete,
	setActiveStep,
	updatePlatforms,
	updateProject,
} from "../../features/project/projectSlice";
import { useTheme } from "@emotion/react";
import { Button, Grid } from "@mui/material";
import { isMobile } from "../../hooks/utils";
// import { MainModal } from "../templates/modals";
// import PlatformCredentials from "./platformCredentials";
// import { setProjectLeader } from "../../features/project/projectSlice";

function MeetTheTeam() {
	// const [projectPlatforms, setProjectPlatforms] = React.useState([]);
	const dispatch = useDispatch();
	const projects = useSelector((state) => state.projectsRedux);
	const theme = useTheme();
	const q = doc(db, "projects", projects?.project?.uid);
	const check = isMobile();

	const [allPlatformsShared, setAllPlatformsShared] = React.useState(false);
	const [notPlatformsRequested, setNotPlatformsRequested] =
		React.useState(false);

	React.useEffect(() => {
		const unsub = onSnapshot(q, (snapshot) => {
			const data = snapshot.data();
			const platforms = [];
			data.platforms.forEach((platform) => {
				if (platform?.createdAt) {
					delete platform?.createdAt;
				}
				if (platform?.updatedAt) {
					delete platform?.createdAt;
				}
				if (platform?.credentials?.createdAt) {
					delete platform?.credentials?.createdAt;
				}
				platforms.push(platform);
			});
			dispatch(updatePlatforms(platforms));

			//Check if at least one platform is required
			const atLeastOnePlatformRequired = platforms.some((obj) => {
				return obj.requested === true;
			});
			if (!atLeastOnePlatformRequired) {
				setNotPlatformsRequested(true);
			} else {
				setNotPlatformsRequested(false);
			}
			// Check if all platforms are shared
			const array = [...data.platforms].filter((obj) => obj.requested === true);
			const allObjectsMeetCondition = array.every((obj) => {
				return obj.sharedCredentials === true;
			});

			if (allObjectsMeetCondition) {
				// Update the value outside the array
				setAllPlatformsShared(true);
			} else {
				setAllPlatformsShared(false);
			}
		});
		return () => {
			unsub();
		};
	}, []);

	const { changeLoadingBackdrop } = React.useContext(BackdropContext);
	const { dispatchChat } = React.useContext(ChatContext);
	React.useEffect(() => {
		changeLoadingBackdrop(false);
		dispatchChat({ type: "SHOW_CHAT" });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleClick() {
		if (!projects.project.completedSteps[8]) {
			dispatch(handleComplete(8));
		} else {
			dispatch(setActiveStep(9));
		}
		dispatch(updateProject());
	}

	// todo[] create modal for mobile
	function setAssignedTasks(member) {
		const tasks = projects.project.tickets.filter(
			(ticket) => ticket.assignee.uid === member.uid
		);
		return tasks;
	}

	// const theme = useTheme();
	// const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<MainPage
			title="Meet the team"
			subtitle=""
			width="80%"
			header={true}
			show={true}
			backFunction={4}
		>
			<Stack
				sx={{
					width: check ? "100%" : "60%",
				}}
			>
				<Paper variant="teamMember">
					<Box sx={{ display: "flex" }}>
						<Avatar
							sx={{
								filter: "drop-shadow(4px 4px 10px #E1E1E1)",
								width: "100px",
								height: "100px",
							}}
							src={projects.project.projectLeader?.photoURL}
						/>
						<Box
							sx={{
								marginLeft: 6,
								flexWrap: "wrap",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Stack>
								<Typography
									variant="calendarMonth"
									sx={{ marginBottom: "4px" }}
								>
									{projects.project.projectLeader?.displayName}
									,&nbsp;Project manager
								</Typography>
								<Typography gutterBottom variant="taskDescription">
									Leading the following project:
								</Typography>
							</Stack>
							<Typography variant="descriptiveText" sx={{ color: "#5F5F5F" }}>
								<b style={{ margin: "0px 10px", fontWeight: 600 }}>•</b>
								{projects.project.projectName}
							</Typography>
						</Box>
					</Box>
				</Paper>
				{projects?.project?.members?.map((teamMember, index) => (
					<Paper key={index} variant="teamMember">
						<Box sx={{ display: "flex" }}>
							<Avatar
								sx={{
									filter: "drop-shadow(4px 4px 10px #E1E1E1)",
									width: "100px",
									height: "100px",
								}}
								src={teamMember?.photoURL}
							/>
							<Box
								sx={{
									marginLeft: 6,
									flexWrap: "wrap",
									alignItems: "center",
									width: "100%",
								}}
							>
								<Stack>
									<Typography
										variant="calendarMonth"
										sx={{ marginBottom: "4px" }}
									>
										{teamMember?.displayName.split(" ")[0]}&nbsp;
										{teamMember?.userRole ?? ""}
									</Typography>
									<Typography gutterBottom variant="taskDescription">
										{setAssignedTasks(teamMember).length > 1
											? "Working on the following tasks"
											: "Working on the following task"}
									</Typography>
								</Stack>
								<Stack>
									{setAssignedTasks(teamMember).map((task) => (
										<Typography
											key={task.uuid}
											variant="descriptiveText"
											sx={{ color: "#5F5F5F" }}
										>
											<b style={{ margin: "0px 10px", fontWeight: 600 }}>•</b>
											{task.title}
										</Typography>
									))}{" "}
								</Stack>
							</Box>
						</Box>
					</Paper>
				))}
				<Stack
					direction="row"
					spacing={1}
					sx={{
						alignItems: "center",
						justifyContent: notPlatformsRequested ? "center" : "flex-start",
						padding: "10% 0% 1% 0%",
					}}
				>
					<Typography variant="credentialsShare">
						{notPlatformsRequested
							? "No credentials requiered"
							: "Share the credentials securely"}
					</Typography>
					<Avatar
						sx={{ display: notPlatformsRequested ? "none" : "block" }}
						src={questionVariant}
						variant="cloudIcon"
					/>
				</Stack>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						marginTop: 2,
						width: "100%",
						flexWrap: "wrap",
					}}
				>
					<Grid container>
						{projects.project.platforms.map((platform) => (
							<PlatformCredentials key={platform.uid} platform={platform} />
						))}
					</Grid>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						sx={{
							visibility: allPlatformsShared ? "visible" : "hidden",
							opacity: allPlatformsShared ? 1 : 0,
							transition: "visibility 0s, opacity 0.5s linear",
							[theme.breakpoints.down("lg")]: {
								paddingBottom: "12%",
							},
							[theme.breakpoints.down("xl")]: {
								paddingBottom: "15%",
							},
							[theme.breakpoints.up("xl")]: {
								paddingBottom: "10%",
							},
							marginTop: "50px",
						}}
					>
						<Button variant="primaryButton" onClick={handleClick}>
							Start working on the project
						</Button>
					</Stack>
				</Box>
			</Stack>
		</MainPage>
	);
}
export { MeetTheTeam };
