import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// import { StyledBadge } from "../muiCustomComponents/styleBadge";
import MuiLink from "@mui/material/Link";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import { isMobile } from "../../hooks/utils";
import "./WorkInProgress.css";

/**
 * Renders a skeleton component for a task item.
 * @component
 * @returns {JSX.Element} The JSX element representing the task item skeleton component.
 */
export default function TaskItemSkeleton() {
	const theme = useTheme();
	const check = isMobile();

	return (
		<Box
			sx={{
				width: "100%",
			}}
		>
			<Box
				sx={{
					border: "1px dashed #333",
					position: "relative",
					display: "flex",
					flexWrap: "wrap",
					borderRadius: "20px",
					backgroundColor: "transparent",
					width: "70%",
					marginTop: 5,
					[theme.breakpoints.down("md")]: {
						marginTop: 2,
					},
				}}
				className="task-container"
			>
				<div className="image-team-member-task">
					<Skeleton variant="circular" width="40px" height="40px" />
				</div>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							paddingLeft: 2,
						}}
					>
						<Typography className="title-task" sx={{ fontWeight: "bold" }}>
							<Skeleton />
						</Typography>
						<MuiLink
							// onClick={() => handleOpen(index)}
							disabled={true}
							sx={{
								textDecoration: "underline",
								border: "none",
								background: "transparent",
								cursor: "default",
								marginBottom: "70px",
								fontFamily: "Ulagadi Sans",
								fontStyle: "normal",
								fontWeight: "200",
								fontSize: "15px",
								lineHeight: "20px",
								color: "#285D69",
							}}
							variant="caption"
						>
							See details
						</MuiLink>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							position: "absolute",
							top: "26px",
							right: "50px",
						}}
					>
						<EventAvailableIcon sx={{ fontSize: 40, color: "#94A3CA" }} />
						<Stack spacing={-0.5}>
							<Typography variant="calendarDay">ETA:</Typography>
							<Skeleton width="40px" />
						</Stack>
					</Box>
				</Box>

				<ul className={check ? "stepper-work-mobile" : "stepper-work"}>
					<li className="inactive">
						<div className="circle"></div>
						<p>Development</p>
						<div className="line-steper-right"></div>
					</li>
					<li className="inactive">
						<div className="line-steper-left"></div>
						<div className="circle"></div>
						<p>Testing</p>
						<div className="line-steper-right"></div>
					</li>
					<li className="inactive">
						<div className="line-steper-left"></div>
						<div className="circle"></div>
						<p>Approval</p>
						<div className="line-steper-right"></div>
					</li>
					<li className="inactive">
						<div className="line-steper-left"></div>
						<div className="circle"></div>
						<p>Deployment</p>
					</li>
				</ul>
			</Box>
		</Box>
	);
}
