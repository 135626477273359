//! Colors
// Teal: #8EDED9, #285D69, #F3FDFC
// lightBlue: #93B1FF, #94A3CA, #E5E9F4
// Electric Blue: #0056D8, #264471, #F1F7FF
// Dark Blue: #112031, #5F5F5F, #C0C0C0
// Primary gradient 	#8EDED9 #93B1FF #0056D8
// background Gradient #F3FDFC #F5F5F5
export const MyTheme = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 2500,
		},
	},
	typography: {
		fontFamily: ["Ulagadi Sans", "sans-serif"].join(","),
		fontWeight: "400",
		htmlFontSize: 10,
		titles: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2.6rem",
			color: "#112031",
		},
		descriptiveText: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.2rem",
			color: "#264471",
		},
		tos: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1rem",
			color: "#112031",
		},
		sideBarTextActive: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.2rem",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			color: "#fff",
		},
		calendarMonth: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#264471",
		},
		calendarDay: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#94A3CA",
		},
		calendarSchedule: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2rem",
			color: "#112031",
		},
		taskTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.8rem",
			color: "##5F5F5F",
		},
		taskDescription: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "200",
			fontSize: "1.4rem",
			color: "#5f5f5f",
		},
		quoteCartTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.2rem",
			color: "#93B1FF",
		},
		credentialsShare: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#657A9A",
		},
		quoteCartContent: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.2rem",
			color: "#fff",
		},
		quoteCartPrice: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.2rem",
			color: "#fff",
		},
		quoteTotal: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.4rem",
			color: "#93B1FF",
		},
		quoteTotalValue: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#264471",
		},
		myTeamRole: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#5f5f5f",
		},
		taskCollapsedTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#285D69",
		},
		label: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.2rem",
			color: "#112031",
		},
		buttonShareCredentials: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "200",
			fontSize: "1.2rem",
			color: "#93B1FF",
		},
		buttonShareCredentialsBold: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "500",
			fontSize: "1.2rem",
			color: "#93B1FF",
		},
		NdaTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "2.2rem",
			color: "#000000",
		},
		chatRight: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#285D69",
			textAlign: "left",
			"& span": {
				"& a": {
					color: "#3074E5",
				},
			},
			"& a": {
				color: "#3074E5",
			},
		},
		chatLeft: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#264471",
			textAlign: "left",
			padding: "2%",
			"& span": {
				"& a": {
					fontWeight: "500",
					color: "#3074E5",
				},
			},
			"& a": {
				fontWeight: "500",
				color: "#3074E5",
			},
		},
		myAccountMenuTitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.7rem",
			color: "#112031",
		},
		myAccountMenuSubtitle: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.5rem",
			color: "#93B1FF",
		},
		myAccountMenuItem: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#112031",
		},
		chatHeader: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "400",
			fontSize: "1.4rem",
			color: "#fff",
		},
		gridHeaders: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "450",
			fontSize: "1.5rem",
			color: "#285D69",
		},
		gradientText: {
			fontFamily: "Ulagadi Sans, sans-serif",
			fontWeight: "450",
			fontSize: "1.5rem",
			background: `linear-gradient(91.51deg, #C6EFF5 6.33%, #93B1FF 74.55%, #0056D8 108.54%);`,
			backgroundClip: "text",
		},
	},
	palette: {
		teal: {
			main: "#C6EFF5",
			secondary: "#285D69",
			background: "#F3FDFC",
		},
		lightBlue: {
			main: "#93B1FF",
			secondary: "#94A3CA",
			background: "#E5E9F4",
		},
		electricBlue: {
			main: "#0056D8",
			secondary: "#264471",
			background: "#F1F7FF",
		},
		darkBlue: {
			main: "#112031",
			secondary: "#5F5F5F",
			background: "#C0C0C0",
		},
		primaryGradient: {
			first: "#C6EFF5",
			second: "#93B1FF",
		},
		backgroundGradient: {
			first: "#F1F7FF",
			second: "#ffffff",
		},
		black: "#212121",
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				fontSize: "1.2rem",
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				html: { fontSize: "62.5%" },
			},
		},
		MuiLink: {
			variants: [
				{
					props: { variant: "calendarScheduleLink" },
					style: {
						color: "#0056D8",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "14px",
					},
				},
				{
					props: { variant: "newProjectLink" },
					style: {
						"&:hover": {
							cursor: "pointer",
						},
						color: "#94A3CA",
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						fontSize: "14px",
					},
				},
			],
		},
		MuiStepper: {
			styleOverrides: {
				root: {},
			},
		},
		MuiButton: {
			defaultProps: {
				style: {
					fontSize: "1.2rem",
				},
			},
			variants: [
				{
					props: {
						variant: "primaryButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						background: `linear-gradient(155deg, #C6EFF5 27.11%, #93B1FF 71.86%)`,
						borderRadius: "18px",
						color: "#fff",
						fontSize: "1.2rem",
						":hover": {
							background: `linear-gradient(#93B1FF, #93B1FF);`,
						},
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "secondaryButton",
					},
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "500",
						background:
							"radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 9px, transparent 9px) 0% 0%/11px 11px no-repeat, radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 9px, transparent 9px) 100% 0%/11px 11px no-repeat,radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 9px, transparent 9px) 0% 100%/11px 11px no-repeat,radial-gradient(circle at 0 0, #ffffff 0, #ffffff 9px, transparent 9px) 100% 100%/11px 11px no-repeat,linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 22px) no-repeat,linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 22px) calc(100% - 4px) no-repeat,linear-gradient(90deg, #C6EFF5 0%, #93b1ff 46%, #0056d8 100%)",
						borderRadius: "11px",
						// backgroundClip: "text",
						// color: "#C6EFF5",
						color: "transparent",
						fontSize: "1.6rem",
						":hover": {
							//background: "#C6EFF5",
							background: `linear-gradient(#C6EFF5, #C6EFF5);`,
							color: "#fff",
						},
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "signUpButton",
					},
					style: {
						background: `#fff`,
						color: "#12B8FF",
						boxShadow: "1px 1px 10px rgba(129, 129, 129, 0.13)",
						borderRadius: "6px",
						textTransform: "none",
					},
				},
				{
					props: {
						variant: "shareCreadentialsButton",
					},
					style: {
						background: "transparent",
						textTransform: "none",
						":hover": {
							background: "transparent",
						},
						"&.Mui-disabled": {
							opacity: "1",
						},
					},
				},
				{
					props: { variant: "taskButton" },
					style: {
						":hover": {
							background: "transparent",
						},
						color: "#93B1FF",
						fontSize: "1.8rem",
						fontWeight: "500",
						textTransform: "none",
					},
				},
				{
					props: { variant: "attachmentTaskButton" },
					style: {
						":hover": {
							background: "transparent",
						},
						color: "#264471",
						fontSize: "1.2rem",
						fontWeight: "500",
						textTransform: "none",
						padding: "0px",
					},
				},
				{
					props: { variant: "testButton" },
					style: {
						":hover": {
							background: "transparent",
						},
						background: "transparent",
					},
				},
			],
		},
		MuiPaper: {
			variants: [
				{
					props: { variant: "platformsUncheckedButton" },
					style: {
						borderRadius: "50px",
						backgroundColor: "#fff",
						width: "32px",
						height: "32px",
						boxShadow: "4px 4px 10px #E9E9E9",
					},
				},
				{
					props: { variant: "platformsUncheckedButtonLg" },
					style: {
						borderRadius: "50px",
						backgroundColor: "#fff",
						width: "27px",
						height: "27px",
						boxShadow: "4px 4px 10px #E9E9E9",
					},
				},
				{
					props: { variant: "platformsCheckedButton" },
					style: {
						position: "absolute",
						borderRadius: "50px",
						background: `linear-gradient(141.2deg, #C6EFF5 24.23%, #93B1FF 61.32%, #0056D8 104.45%)`,
						width: "60%",
						aspectRatio: "1 / 1",
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						margin: "auto",
					},
				},
				{
					props: { variant: "platformsCheckedButtonLg" },
					style: {
						position: "absolute",
						borderRadius: "50px",
						background: `linear-gradient(141.2deg, #C6EFF5 24.23%, #93B1FF 61.32%, #0056D8 104.45%)`,
						width: "60%",
						aspectRatio: "1 / 1",
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						margin: "auto",
					},
				},
				{
					props: { variant: "pagePaper" },
					style: {
						display: "flex",
						backgroundColor: "transparent",
						paddingTop: "5%",
						justifySelf: "center",
						width: "100%",
						maxHeight: "100%",
						overflow: "auto",
						flexDirection: "column",
						borderRadius: 0,
					},
				},
				{
					props: { variant: "innerContent" },
					style: {
						display: "flex",
						backgroundColor: "transparent",
						justifySelf: "center",
						height: "100%",
						overflow: "visible",
						flexDirection: "column",
						margin: "0 auto",
						marginBottom: "20px",
					},
				},
				{
					props: { variant: "translucidPagePaper" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						placeSelf: "center",
						display: "flex",
						flexDirection: "column",
						minWidth: "100%",
					},
				},
				{
					props: { variant: "teamModal" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						// placeSelf: "center",
						display: "grid",
						height: "90vh",
						width: "100%",
						boxShadow: "8px 8px 20px #c0c0c040",
						borderRadius: "15px",
						padding: "20px",
						alignItems: "start",
					},
				},
				{
					props: { variant: "teamMember" },
					style: {
						backgroundColor: "#ffffffcc",
						backdropFilter: `blur(20px)`,
						placeSelf: "center",
						display: "flex",
						flexDirection: "column",
						height: "auto",
						width: "100%",
						boxShadow: "8px 8px 20px #c0c0c040",
						borderRadius: "15px",
						padding: "20px",
						marginBottom: "3%",
					},
				},
			],
		},
		MuiCard: {
			variants: [
				{
					props: { variant: "platforms" },
					style: {
						backgroundColor: "#ffffff99",
						borderRadius: "9px",
						boxShadow: "2px 2px 15px #E9E9E9",
						backdropFilter: `blur(10px)`,
						fontSize: "15px",
						Color: "#112031",
						fontWeight: "500",
					},
				},
			],
		},
		MuiAvatar: {
			defaultProps: {
				imgProps: { draggable: "false", referpolicy: "no-referrer" },
			},
			variants: [
				{
					props: { variant: "iconAvatar" },
					style: {
						width: "24px",
						height: "24px",
						marginRight: "5px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "inputAdorment" },
					style: {
						width: "14px",
						height: "14px",
						marginRight: "5px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconPlatforms" },
					style: {
						width: "28px",
						height: "28px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "sendMessage" },
					style: {
						width: "22px",
						height: "22px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "expandAccordionIconTasks" },
					style: {
						width: "32px",
						height: "32px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconTaskSmaller" },
					style: {
						width: "1.9rem",
						height: "1.9rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconTask" },
					style: {
						width: "34px",
						height: "34px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconCheck" },
					style: {
						width: "1.2rem",
						height: "1.2rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconArrows" },
					style: {
						width: "18px",
						height: "18px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "questionIcon" },
					style: {
						width: "20px",
						height: "20px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "cloudIcon" },
					style: {
						width: "1.4rem",
						height: "1.4rem",
						fontSize: "1.4rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "closeIcon" },
					style: {
						width: "3rem",
						height: "3rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "stepperIcon" },
					style: {
						width: "10px",
						height: "10px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							width: "10px",
							height: "10px",
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "stepperCompletedIcon" },
					style: {
						width: "1.1rem",
						height: "1.1rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "chatIcons" },
					style: {
						width: "17px",
						height: "17px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "backArrowIcon" },
					style: {
						width: "21px",
						height: "21px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "checkboxTasks" },
					style: {
						width: "14px",
						height: "14px",
						boxShadow: "4px 4px 10px 0px #E9E9E9",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconCheckboxTasks" },
					style: {
						width: "30px",
						height: "30px",
						marginTop: "8px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "sidebarProjectIcon" },
					style: {
						width: "1.2rem",
						height: "1.2rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "threeDotsIcon" },
					style: {
						width: "1rem",
						height: "0.3rem",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "bannerImage" },
					style: {
						width: "125px",
						height: "40px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "modalImage" },
					style: {
						width: "80%",
						height: "80%",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "iconTasks" },
					style: {
						width: "18px",
						height: "18px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "WipAvatar" },
					style: {
						width: "30px",
						height: "30px",
						boxShadow: "4px 4px 10px #E9E9E9",
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "hamburguer" },
					style: {
						width: "30px",
						height: "30px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "MyAccountMobile" },
					style: {
						"&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall": {
							padding: "0px",
						},
						marginRight: "-5px",
						marginLeft: "-5px",
						width: "40px",
						height: "40px",
						// borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
			],
		},
		MuiIconButton: {
			variants: [
				{
					props: { variant: "iconVisibility" },
					style: {
						":hover": {
							backgroundColor: "inherit",
						},
					},
				},
			],
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					// inputProps={{
					//        style: {
					// 		padding: 5
					// 	  }
					//    }}
					// size: "41px",
					borderRadius: "9px",
					color: "#657A9A",
					backgroundColor: "#fff",
					"&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
						borderColor: "#C6EFF5",
					},
					"& input + fieldset": {
						border: "1px solid #F1F1F1",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#F1F1F1",
					},
					"& .Mui-error": {
						borderWidth: 2,
					},
					"& input:valid:focus + fieldset": {
						borderColor: "#C6EFF5",
					},
					"& ~ p": {
						marginTop: "0px",
					},
					"& .MuiInputBase-input::placeholder": {
						color: "#657A9A",
						opacity: 1,
					},
				},
			},
		},
		MuiMenu: {
			variants: [
				{
					props: { variant: "contextMenu" },
					style: {
						"& .MuiPaper-root": {
							borderRadius: 0,
							background: "rgba(17, 32, 49, 0.32)",
							backdropFilter: "blur(2px)",
							padding: "12px 19px 15px 19px",
							"& .MuiList-root": {
								padding: "0px",
								color: "#fff",
								"& .MuiMenuItem-root": {
									paddingLeft: "0px",
									paddingRight: "0px",
									paddingTop: "10px",
									paddingBottom: "10px",
									":hover": {
										backgroundColor: "inherit",
									},
									":nth-of-type(1)": {
										paddingTop: "0px",
										paddingBottom: "10px",
									},
									":nth-last-of-type(1)": {
										paddingTop: "10px",
										paddingBottom: "0px",
									},
								},
							},
							boxShadow: "none",
						},
					},
				},
			],
		},
		MuiDivider: {
			variants: [
				{
					props: { variant: "mainDivider" },
					style: {
						color: "#285D69",
						"&::before, &::after": {
							borderColor: "#285D69",
						},
					},
				},
			],
		},
		MuiList: {
			variants: [
				{
					props: { variant: "weekHeader" },
					style: {
						display: "flex",
						listStyle: "none",
					},
				},
				{
					props: { variant: "dayList" },
					style: {
						display: "flex",
						listStyle: "none",
						flexWrap: "wrap",
						gap: "5px",
					},
				},
				{
					props: { variant: "hourList" },
					style: {
						padding: "0px",
						padddingRight: "20px",
						listStyle: "none",
					},
				},
			],
		},
		MuiListItemButton: {
			variants: [
				{
					props: { variant: "dayItem" },
					style: {
						// backgroundColor: "rgba(255, 255, 255, 0.2)",
						width: "calc(14.2% - 5px)",
						aspectRatio: "1/1",
						boxSizing: "border-box",
						textAlign: "center",
						borderRadius: "10px",
						boxShadow: "8px 8px 20px #c0c0c040",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						paddingBottom: "5px",
						paddingRight: "5px",
						backgroundColor: "#fff",
						"& .MuiTypography-root": {
							color: "#112031",
						},
						"&.Mui-disabled": {
							backgroundColor: "#f8fafe",
							opacity: 1,
							"& .Mui-selected": {
								backgroundColor: "#f8fafe",
							},
							"& 	.MuiTypography-root": {
								color: "#264471",
							},
						},
						"&.Mui-selected": {
							backgroundColor: "#C8D4F3",
							"& 	.MuiTypography-root": {
								color: "#112031",
							},
							":hover": {
								backgroundColor: "#C8D4F3",
							},
						},
						":hover": {
							backgroundColor: "inherit",
						},
					},
				},
				{
					props: { variant: "hourItem" },
					style: {
						padding: "1vh 10px",
						boxSizing: "border-box",
						borderRadius: "15px",
						background: "#fff",
						marginBottom: "10px",
						boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
						"& .MuiTypography-root": {
							color: "#112031",
						},
						":hover": {
							backgroundColor: "#EEF3FF",
						},
						"&.Mui-selected": {
							backgroundColor: "#C8D4F3",
							":hover": {
								backgroundColor: "#C8D4F3",
							},
						},
					},
				},
			],
		},
		MuiListItem: {
			variants: [
				{
					props: { variant: "weekHeaderItem" },
					style: {
						width: "14.2%",
					},
				},
			],
		},
		MuiMenuItem: {
			variants: [
				{
					props: { variant: "myAccountMenuItem" },
					style: {
						fontFamily: "Ulagadi Sans, sans-serif",
						fontWeight: "400",
						fontSize: "15px",
						color: "#112031",
					},
				},
			],
		},
		MuiFormControlLabel: {
			variants: [
				{
					props: { variant: "quoteCheckboxLabel" },
					style: {
						"& .MuiFormControlLabel-label": {
							fontFamily: "Ulagadi Sans, sans-serif",
							fontWeight: "500",
							fontSize: "1rem",
							color: "#fff",
						},
					},
				},
			],
		},
		MuiPopover: {
			variants: [
				{
					props: { variant: "modalPopoverPaper" },
					style: {
						"& .MuiPopover-paper": {
							backgroundColor: "#ffffffFF",
							backdropFilter: `blur(20px)`,
							// placeSelf: "center",
							display: "grid",
							// maxHeight: "30vh",
							maxWidth: "100vw",
							boxShadow: "8px 8px 20px #c0c0c040",
							borderRadius: "15px",
							padding: "20px",
							alignItems: "start",
						},
					},
				},
			],
		},
		MuiTextField: {
			defaultProps: {
				style: {
					"& .MuiSelect.MuiInputBase": {
						padding: "5px 10px",
						fontSize: "1.2rem",
					},
				},
				inputProps: {
					style: {
						padding: "5px 10px",
						fontSize: "1.2rem",
					},
				},
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				style: {
					fontSize: "0.8rem",
				},
			},
		},
	},
};
