import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import LoginButton from "./LoginButton";
import MenuButton from "./menuButton";

import { isMobile } from "../../hooks/utils";
function MyAccountButton() {
	const auth = useSelector((state) => state.auth);
	const theme = useTheme();
	const check = isMobile();
	return (
		<Box
			sx={{
				position: check ? "flex" : "fixed",
				top: 0,
				right: 0,
				marginTop: "12px",
				marginRight: "12px",
				[theme.breakpoints.down("md")]: {
					margin: 0,
					width: "fit-content",
				},
				[theme.breakpoints.between("md", "lg")]: {
					marginTop: "12px",
					marginRight: "12px",
				},
			}}
			zIndex={1}
		>
			{auth.authed ? <MenuButton /> : <LoginButton />}
		</Box>
	);
}

export default MyAccountButton;
