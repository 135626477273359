export const quotes = [
	{
		id: 1,
		title: "Mejoras App movil Flutter",
		description: "Cambiar login y registro",
		platforms: ["Flutter"],
		type: "Cambios",
		integrations: "",
		aditionaWork: "Proponer diseño",
		isApprove: true,
	},
	{
		id: 2,
		title: "Pantallas Shopify",
		description:
			"Nulla incididunt pariatur ex quis veniam duis dolore cillum fugiat nulla elit. Deserunt velit nisi quis nostrud. Nisi aute excepteur enim reprehenderit aute ad laborum ea.",
		platforms: ["Shopify"],
		type: "Agregar",
		integrations: "",
		aditionaWork: "",
		isApprove: true,
	},
	{
		id: 3,
		title: "Optimizar Backend y Frontend",
		description: "Añadir mejoras de rendimiento y nuevos endpoints",
		platforms: ["Php", "Flutter"],
		type: "",
		integrations: "",
		ETA: "",
		isApprove: false,
	},
	{
		id: 4,
		title: "Optimizar Backend y Frontend",
		description:
			"Nisi labore deserunt amet reprehenderit occaecat qui et. Eiusmod magna ut nostrud eu in id sunt quis minim qui laborum. Est officia commodo aliquip veniam pariatur.",
		platforms: ["Php", "Flutter"],
		type: "",
		integrations: "",
		aditionaWork:
			"Laborum non incididunt exercitation duis Lorem nisi dolore non id quis incididunt fugiat nisi cupidatat.",
		isApprove: false,
	},
];
