import React from "react";
import { ProjectsContext } from "../Context/ProjectsContext";
import { UserAuth } from "../Context/AuthContext";
import { StepperContext } from "@mui/material";
import { ChatContext } from "../Context/ChatContext";

// import { StepperContext2 } from "../Context/StepperContextRefactorized";
import { useNavigate } from "react-router-dom";
import { getProjectsByUserId } from "./projects";

/**
 * deprecated function do not use
 * @deprecated This hook is deprecated
 */
export function useProjects() {
	const { projectsData, dispatchProjects } = React.useContext(ProjectsContext);
	const { dispatchStepper } = React.useContext(StepperContext);
	const { dispatchChat } = React.useContext(ChatContext);
	const { AuthData } = UserAuth();
	// const { dispatchStepper2 } = React.useContext(StepperContext2);

	const navigate = useNavigate();

	function getStep(projectId) {
		const treatedProject = projectsData.projects;
		const projectToUpdate = treatedProject?.find(
			(step) => step.projectId === projectId
		);
		// return { ...projectToUpdate };
		return projectToUpdate;
	}

	async function projects() {
		const tempProjects = [];

		if (localStorage.getItem(`localStorageProject`)) {
			const projectId = localStorage.getItem(`localStorageProject`);
			dispatchProjects({ type: "SET_PROJECT_ID", payload: projectId });
		}
		const projects = localStorage.getItem(`localStorageProjects`);
		if (localStorage.getItem(`localStorageProjects`) && projects.length > 0) {
			JSON.parse(localStorage.getItem(`localStorageProjects`)).forEach(
				(project) => {
					tempProjects.push(project);
				}
			);
			dispatchProjects({ type: "SET_PROJECTS", payload: tempProjects });
		} else {
			try {
				await getProjectsByUserId({
					route: "project/getClientProjects",
					id: AuthData.user.uid,
				}).then((response) => {
					if (
						response.success !== false &&
						response?.data?.projects?.length > 0
					) {
						const res = response.data;
						res.forEach((project) => {
							tempProjects.push(project);
						});
						localStorage.setItem(
							"localStorageProjects",
							JSON.stringify(tempProjects)
						);
						dispatchProjects({ type: "SET_PROJECTS", payload: tempProjects });
						dispatchProjects({
							type: "SET_PROJECT_ID",
							payload: tempProjects[0].projectId,
						});
						// initializeStepper();
					}
				});
			} catch (e) {
				console.error("Error in getProjects");
				console.error(e);
			}
		}
	}

	function resetContexts() {
		dispatchStepper({ type: "RESET_STEPPER" });
		dispatchProjects({ type: "RESET_PROJECTS" });
		dispatchChat({ type: "RESET_CHAT" });
	}

	function initializeStepper() {
		const treatedSteps = projectsData?.projects;
		const newtreatedSteps = [];
		treatedSteps.forEach((step) => {
			const tempArray = [];
			for (let i = 0; i <= step.activeStep + 1; i++) {
				tempArray.push(projectsData.steps[i]);
			}
			if (projectsData.steps.length < step.activeStep + 1) {
				tempArray.push(projectsData.steps[step.activeStep + 1]);
			}
			step.tempSteps = tempArray;
			newtreatedSteps.push(step);
		});
		dispatchProjects({ type: "SET_STEPS", payload: newtreatedSteps });

		navigate(newtreatedSteps[0].tempSteps[0].url);
	}

	function handleNext() {
		const stepToUpdate = getStep(projectsData.projectId);
		const newActiveStep = stepToUpdate.activeStep + 1;
		dispatchProjects({ type: "SET_STEPPER", payload: newActiveStep });
		localStorage.setItem("ActiveStep", newActiveStep);
		navigate(projectsData.steps[newActiveStep].url);
	}

	function handleNextEmptyProject() {
		navigate("/signUp");
	}

	function handleBack(projectId) {
		const stepToUpdate = getStep(projectId);
		stepToUpdate.activeStep = stepToUpdate.activeStep - 1;
		dispatchProjects({ type: "SET_STEPPER", payload: stepToUpdate });
		const url = projectsData.steps[stepToUpdate.activeStep].url;
		navigate(url);
	}

	function SetActiveStep(index) {
		if (projectsData.projectId === undefined || projectsData.projectId === "") {
			return;
		}
		const stepToUpdate = getStep(projectsData.projectId);
		stepToUpdate.activeStep = index;

		const tempArray = [];
		try {
			for (let i = 0; i <= index + 1; i++) {
				if (projectsData.steps.length === i) {
					break;
				}
				tempArray.push(projectsData.steps[i]);
			}

			stepToUpdate.tempSteps = tempArray;
		} catch (e) {
			console.error(e, "error in set active step");
		}
		dispatchProjects({
			type: "SET_ACTIVE_STEP",
			payload: projectsData.projects,
		});
	}

	function handleStep(index, project) {
		const projectToUpdate = getStep(project.projectId);
		projectToUpdate.activeStep = index;
		const newProjects = projectsData.projects;
		dispatchProjects({ type: "SET_STEPPER", payload: newProjects });
		navigate(projectsData.steps[index].url);
	}

	function handleComplete(projectId) {
		const stepToUpdate = getStep(projectId);
		const newCompleted = stepToUpdate.completedSteps;
		newCompleted[stepToUpdate.activeStep] = true;
		dispatchProjects({
			type: "SET_STEPPER",
			payload: stepToUpdate,
		});

		const projects = projectsData.projects.map((object) => {
			delete object.tempSteps;
			return object;
		});
		const stringProjects = JSON.stringify(projects);

		localStorage.setItem("localStorageProjects", stringProjects);

		handleNext();
	}

	return {
		projects,
		resetContexts,
		initializeStepper,
		handleBack,
		handleStep,
		handleComplete,
		SetActiveStep,
		handleNext,
		handleNextEmptyProject,
		getStep,
	};
}
