import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import styled from "@mui/material/styles/styled";

export const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderRadius: "5px",
	backgroundColor: "#ffffffcc",
	padding: "2.5rem",
	overflow: "hidden",
	border: `1px solid ${theme.palette.lightBlue.main}`,
	boxShadow: "8px 8px 20px 0px rgba(0, 0, 0, 0.05)",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(0deg)",
	},
	"& .MuiAccordionSummary-root.Mui-focusVisible": {
		background: "transparent",
	},
	"&:not(:last-child)": {
		// borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
	"& .MuiButtonBase-root.MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
		cursor: "default",
	},
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary {...props} />
))(({ theme }) => ({
	borderRadius: "15px",
	background: "transparent",
	minHeight: "fit-content",
	padding: theme.spacing(0),
	paddingBottom: theme.spacing(1),
	"& .MuiAccordionSummary-content": {
		margin: theme.spacing(0),
	},
	"& .MuiAccordionSummary-root:hover": {
		pointer: "cursor",
	},
	boxSizing: "border-box",
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(0),
	background: "transparent",
	borderRadius: "0 0 15px 15px",
	boxSizing: "border-box",
}));
