// import React from "react";
import Avatar from "@mui/material/Avatar";
// import FormControl from "@mui/material/FormControl";
// import FormHelperText from "@mui/material/FormHelperText";
// import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import { RequierementsContext } from "../../Context/RequierementsContext";
import TaskDescriptionIcon from "../../Sources/Icons/TaskDescriptionIcon.svg";

export function TaskHeaderMobile({
	taskIndex,
	index,
	// expanded,
	// taskName,
	// disabled,
	// handleTaskName,
	// setName,
}) {
	// const { requierementsData } = React.useContext(RequierementsContext);

	return (
		<Stack
			direction={"column"}
			sx={{
				justifyContent: "flex-start",
				width: "100%",
				alignItems: "flex-start",
			}}
			spacing={1}
		>
			<Stack
				width={"100%"}
				spacing={1}
				direction={"row"}
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<Stack direction={"row"} spacing={1}>
					<Avatar
						sx={{ width: "25px", height: "25px" }}
						variant="iconTask"
						src={TaskDescriptionIcon}
					/>
					<Typography variant="calendarMonth">
						Task #{index ?? taskIndex + 1}
					</Typography>
				</Stack>
				{/* 
				{!expanded ? (
					<Stack direction={"row"} paddingRight={1}>
						{requierementsData.tasks[index]?.platforms?.map((platform) => (
							<Avatar
								key={platform.id}
								variant="iconTasks"
								src={platform.checked && platform.icons.inactive}
								sx={{
									"&:nth-of-type(1)": {
										marginLeft: 0,
									},
									marginLeft: 1,
								}}
							/>
						))}
					</Stack>
				) : null}
				 */}
			</Stack>
			<Stack
				sx={{ width: "calc(100% + 76px)", alignItems: "center", gap: "15px" }}
				direction={"row"}
				spacing={2}
			>
				{/* 
				{expanded ? (
					""
				) : (
					<Typography
						variant="taskCollapsedTitle"
						textAlign={"justify"}
						sx={{
							"& .MuiTypography-root": {
								hyphens: "auto",
							},
						}}
					>
						{taskName.length > 0 ? taskName : " "}
					</Typography>
				)}
				 */}
				{/*
				{expanded ? (
					<FormControl sx={{ width: "90%", top: 10 }} fullWidth>
						<OutlinedInput
							size="small"
							fullWidth
							name="taskName"
							value={taskName}
							placeholder="Add a title..."
							onChange={(e) => {
								handleTaskName(e);
							}}
							sx={{
								fontSize: "14px",
								"& .MuiOutlinedInput-input::placeholder": {
									color: "#C0C0C0",
									fontWeight: "200",
								},
							}}
							onBlur={(e) => setName(e)}
							disabled={disabled}
						/>

						<FormHelperText> </FormHelperText>
					</FormControl>
				) : null} */}
			</Stack>
		</Stack>
	);
}
