function Question({ question, handleInput }) {
	const { options } = question;
	function GenerateOptions({ options }) {
		return (
			<div className="options-box">
				{options.map((option, index) => {
					const { opcion, next } = option;
					const { id } = question;
					return (
						<>
							<input
								onClick={(e) => {
									handleInput(e, next);
								}}
								value={JSON.stringify({ option: opcion, next, id })}
								id={opcion}
								className="radio"
								type="radio"
								name="options"
							/>
							<label
								className={index === 0 ? "label-radio active" : "label-radio"}
								htmlFor={opcion}
							>
								{opcion}
							</label>
						</>
					);
				})}
			</div>
		);
	}

	return <>{options && <GenerateOptions options={options} />}</>;
}

export { Question };
