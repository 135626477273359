import React from "react";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { MenuComponent } from "./menu";
import { MyAccountContext } from "../../Context/MyAccountContext";

import iconButtonArrowDown from "../../Sources/Icons/iconButtonArrowDown.svg";

import { isMobile } from "../../hooks/utils";

export default function MenuButton() {
	const check = isMobile();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const { dispatchMyAccount } = React.useContext(MyAccountContext);

	const auth = useSelector((state) => state.auth);

	const theme = useTheme();

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleRoute(e) {
		dispatchMyAccount({ type: "GO_TAB", payload: e.target.value });
		navigate("/MyAccount");
	}
	return (
		<>
			<Button
				size="small"
				disableRipple
				sx={{
					borderRadius: "50px",
					color: "#e1e1e1",
					background: "white",
					backgroundColor: "white",
					border: "1px solid transparent",
					backgroundClip: "padding-box",
					width: "200px",
					textTransform: "none",
					height: "100%",
					"&:after": {
						position: "absolute",
						top: "-1px",
						left: "-1px",
						right: "-1px",
						bottom: "-1px",
						background: `linear-gradient(155deg, ${theme.palette.primaryGradient.first} 27.11%, ${theme.palette.primaryGradient.second} 71.86%)`,
						content: '""',
						zIndex: -1,
						borderRadius: "50px",
					},
					":hover": {
						backgroundColor: "whitesmoke",
					},
					[theme.breakpoints.down("md")]: {
						padding: 0,
						"& .MuiButton-startIcon": {
							margin: 0,
						},
						backgroundColor: "transparent",
						":hover": {
							backgroundColor: "transparent",
						},
					},
					[theme.breakpoints.up("md")]: {
						"& .MuiAvatar-root": {
							width: "30px",
							height: "30px",
						},
					},
				}}
				id="account-button"
				aria-controls={open ? "profile-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				{!check && (
					<Stack
						direction="row"
						sx={{
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
						}}
						spacing={1}
					>
						<Avatar
							src={auth?.user?.photoURL}
							alt={auth?.user?.displayName}
							imgProps={{ referrerPolicy: "no-referrer" }}
							sx={{
								[theme.breakpoints.down("md")]: {
									margin: 0,
								},
							}}
						/>
						<Typography
							sx={{
								marginX: "auto",
							}}
						>
							{auth?.user?.displayName?.split(" ")[0]}
						</Typography>
						<Avatar variant="stepperIcon" src={iconButtonArrowDown} />
					</Stack>
				)}
			</Button>
			<MenuComponent
				anchorEl={anchorEl}
				open={open}
				handleRoute={handleRoute}
				setAnchorEl={setAnchorEl}
			/>
		</>
	);
}
