import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

export function MediaDialog({ img }) {
	return (
		<Card
			sx={{
				backgroundColor: "transparent",
			}}
		>
			<CardMedia component={"img"} src={img} alt={"img"} />
			<Typography
				component={Link}
				href={img}
				target={"_blank"}
				color={"#fff"}
				sx={{
					textDecoration: "none",
					transition: "color 0.07s ease-in",
					":hover": {
						textDecoration: "underline",
						color: "#fff",
					},
				}}
			>
				Open original
			</Typography>
		</Card>
	);
}
