// to send a message i need: message, projectUid, (sender id) userUid, (if file message) file,

import { getAuthed, postMethod, postMethodAuthed } from "../backend/services";

export async function sendMediaMessageUtil(
	message,
	file,
	projectUid,
	userUid,
	chatUid,
	imgRef,
	clientName,
	projectName,
	accessToken,
	email,
	photoURL,
	reciptUid
) {
	await postMethodAuthed({
		route: "chat/sendMessage",
		body: {
			chatId: chatUid,
			text: message,
			projectId: projectUid,
			sender: {
				uid: userUid,
				displayName: clientName,
				photoURL,
			},
			attachments: {
				img: file,
				imgRef,
			},
			reciptUid,
		},
		accessToken,
	});
}

export async function sendMessageUtil(
	message,
	projectUid,
	userUid,
	chatUid,
	clientName,
	projectName,
	accessToken,
	email,
	photoURL,
	reciptUid
) {
	await postMethodAuthed({
		route: "chat/sendMessage",
		body: {
			chatId: chatUid,
			text: message,
			projectId: projectUid,
			sender: {
				uid: userUid,
				displayName: clientName,
				photoURL,
			},
			reciptUid,
		},
		accessToken,
	}).catch((e) => console.error("something went wrong: ", e));
}

export async function getQuestions(accessToken) {
	try {
		const questions = await getAuthed({
			route: "questionnaire/getQuestionnaire",
			accessToken,
		});
		if (questions.success) {
			return questions.questionnaire;
		}
	} catch (e) {
		console.error(e);
	}
}
/**
 * @deprecated
 */
export async function RestartChat(clientUid) {
	try {
		const res = await postMethod({
			route: "client/restartChat",
			body: {
				clientUid,
			},
		});
		if (!res.success) {
			throw new Error(`Could not restart chat: ${res.message}`);
		}
		return res.data;
	} catch (e) {
		console.error(e);
	}
}

export async function updateExclusiveChat(exclusive, projectUid, accessToken) {
	const res = await postMethodAuthed({
		route: "project/updateExclusiveChat",
		body: {
			projectUid,
			exclusive,
		},
		accessToken,
	});
	if (!res.success) {
		throw new Error(`Could not update exclusive chat: ${res.message}`);
	}
	return res.data;
}
