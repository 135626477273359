import { Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { isMobile } from "../../hooks/utils";

export default function LoginButton() {
	const theme = useTheme();
	const navigate = useNavigate();
	const check = isMobile();
	return (
		<Button
			disableRipple
			sx={{
				borderRadius: "50px",
				color: "#e1e1e1",
				background: "white",
				backgroundColor: "white",
				border: "1px solid transparent",
				backgroundClip: "padding-box",
				paddingLeft: 2,
				width: "150px",
				textTransform: "none",
				"&:after": {
					position: "absolute",
					top: -1,
					left: -1,
					right: -1,
					bottom: -1,
					background: `linear-gradient(155deg, ${theme.palette.primaryGradient.first} 27.11%, ${theme.palette.primaryGradient.second} 71.86%)`,
					content: '""',
					zIndex: -1,
					borderRadius: "50px",
				},
				":hover": {
					backgroundColor: "whitesmoke",
				},
				[theme.breakpoints.down("md")]: {
					padding: 0,
					"& .MuiButton-startIcon": {
						margin: 0,
					},

					backgroundColor: "transparent",
					":hover": {
						backgroundColor: "transparent",
					},
				},
			}}
			id="account-button"
			aria-controls={open ? "profile-menu" : undefined}
			aria-haspopup="true"
			aria-expanded={open ? "true" : undefined}
			onClick={() => navigate("/login")}
		>
			{check ? null : "Login"}
		</Button>
	);
}
