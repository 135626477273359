import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const BubbleRight = styled(Paper)(({ theme, message, img }) => ({
	backgroundColor: theme.palette.teal.background,
	padding: theme.spacing(1),
	display: "flex",
	flexDirection: "column",
	margin: "10px 10px 10px 0px",
	justifyContent: "flex-end",
	maxWidth: "90%",

	//! explanation if message have imgage with or without text
	//!	boreder radius will be 15px, if not it will be 30px

	//! also if message have image and text the bottom right
	//! border radius will be 0px

	borderRadius:
		img && !message
			? "15px"
			: img && message
				? "15px 15px 0px 15px"
				: "30px 30px 0px 30px",

	boxShadow: "0px 5px 10px #EEEEEE",
}));

export const BubbleLeft = styled(Paper)(({ theme, message, img }) => ({
	backgroundColor: theme.palette.electricBlue.background,
	padding: theme.spacing(1),
	display: "grid",
	flexDirection: "column",
	margin: "10px 0px 10px 10px",
	alignSelf: "flex-end",
	maxWidth: "calc(90% - 10px)",
	//! same as BubbleRight, but this is for left side
	//! if message have image and text the bottom left border radius will be 0px

	borderRadius:
		img && !message
			? "15px"
			: img && message
				? "15px 15px 15px 0px"
				: "15px 15px 15px 0px",
	boxShadow: "0px 5px 10px #EEEEEE",
}));

export const EmptyChat = styled(Paper)(({ theme }) => ({
	background: `linear-gradient(99.83deg,  ${theme.palette.teal.main} 16.16%, ${theme.palette.lightBlue.main} 101.31%, ${theme.palette.electricBlue.main} 116%)`,

	...theme.typography.body2,
	padding: theme.spacing(0),
	textAlign: "center",
	display: "grid",
	color: "#fff",
	margin: "20px auto",
	width: "100%",
	height: "100%",
	alignItems: "center",
}));

export const ChatBox = styled(Paper)(({ theme }) => ({
	backgroundColor: "#fff",
	padding: theme.spacing(0),
	textAlign: "center",
	color: theme.palette.text.secondary,
	height: "50vh",
	borderRadius: "0px",
	overflowY: "auto",
	marginLeft: "8px",
	marginRight: "8px",
}));

export const InputContainer = styled(Paper)(({ theme }) => ({
	boxShadow: "none",
	backgroundColor: "rgba(255, 255, 255, 0.8)",
	textAlign: "center",
	display: "flex",
	flexDirection: "row",
	color: theme.palette.text.secondary,
	width: "100%",
	alignItems: "center",
	borderRadius: "9px",
	border: "1px solid #C0C0C0",
	backdropFilter: "blur(10px)",
	height: "100%",
}));

export const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, Hidden }) => ({
	[theme.breakpoints.down("xl")]: {
		width: "305px",
	},
	[theme.breakpoints.up("xl")]: {
		width: "330px",
	},
	borderRadius: "0 0 30px 30px",
	background: "none",
	zIndex: theme.zIndex.appBar,
	position: "fixed",
	bottom: 0,
	display: Hidden ? "flex" : "none",
	flexDirection: "column",
	right: "10px",
	"&	.MuiAccordionSummary-root": {
		"& .MuiPaper-root": {
			borderRadius: "30px 30px 0 0",
		},
	},
	"& .MuiAccordionDetails-root": {
		padding: 0,
		margin: 0,
		backgroundColor: "whitesmoke",
		boxSizing: "border-box",
	},
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary {...props} />
))(({ theme }) => ({
	borderRadius: "30px 30px 0 0",
	background: `linear-gradient(99.83deg,  ${theme.palette.teal.main} 16.16%, ${theme.palette.lightBlue.main} 101.31%, ${theme.palette.electricBlue.main} 116%)`,
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	background: `linear-gradient(99.83deg,  ${theme.palette.teal.main} 16.16%, ${theme.palette.lightBlue.main} 101.31%, ${theme.palette.electricBlue.main} 116%)`,
	borderRadius: "0 0 15px 15px",
}));
