import { Avatar, Box, CircularProgress, Stack } from "@mui/material";

import DigitalTuneup from "../../Sources/images/AdvanteBannerImg-2.svg";

export default function LoadingScreen() {
	return (
		<Box
			sx={{
				display: "grid",
				width: "100vw",
				height: "100vh",
				backgroundColor: "#264471",
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 4,
			}}
		>
			<Stack
				sx={{
					placeSelf: "center",
					alignItems: "center",
					width: "100%",
					height: "100%",
					position: "relative",
				}}
			>
				<CircularProgress
					sx={{
						position: "absolute",
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						margin: "auto",
						color: "#C6EFF5",
					}}
					thickness={3}
					size={100}
				/>
				<Avatar
					sx={{
						marginTop: "auto",
						width: "250px",
						height: "75px",
						paddingBottom: "20px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					}}
					imgProps={{ draggable: "false", referpolicy: "no-referrer" }}
					src={DigitalTuneup}
				/>
			</Stack>
		</Box>
	);
}
