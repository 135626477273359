import Router from "./router";

import { MyTheme } from "./MyTheme";

import { MyAccountTabsContextProvider } from "../Context/MyAccountContext";

//! MUI IMPORTS
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//! FONT IMPORTS

//! MUI ICONS

import "./App.css";
import { CssBaseline } from "@mui/material";
import DotsBackground from "../Sources/images/DotBackground.svg";

function App() {
	const theme = createTheme(MyTheme);

	// const { projects, setActiveStep } = UserAuth();

	// console.log(process.env.REACT_APP_STRIPE_PK_KEY);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<MyAccountTabsContextProvider>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						height: "100%",
						backgroundImage: `url(${DotsBackground})`,
						backgroundRepeat: "repeat",
					}}
				>
					<Router />
					
				</Box>
			</MyAccountTabsContextProvider>
		</ThemeProvider>
	);
}

export { App };
