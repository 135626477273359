import React from "react";

export const RequierementsContext = React.createContext();
export const RequierementsContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		attachments: [],
		total: 0,
		progress: 0,
	};
	const requierementsReducer = (state, action) => {
		switch (action.type) {
		case "ADD_FILE": {
			const { files } = action.payload;
			return {
				...state,
				attachments: [...state.attachments, ...files],
			};
		}
		case "REMOVE_FILE": {
			const { file } = action.payload;
			return {
				...state,
				attachments: state.attachments.filter((f) => f.id !== file.id),
			};
		}
		case "REMOVE_ALL_FILES_FROM_TASK": {
			const { uuid } = action.payload;
			return {
				...state,
				attachments: state.attachments.filter((f) => f.taskUuid !== uuid),
			};
		}
		case "UPDATE_TOTAL": {
			const { total } = action.payload;
			return {
				...state,
				total,
			};
		}
		case "UPDATE_PROGRESS_BY_ID": {
			const { id, progress } = action.payload;
			const attachments = state.attachments.map((f) => {
				if (f.id === id) {
					return { ...f, progress };
				}
				return f;
			});
			return {
				...state,
				attachments,
				progress: attachments.reduce((acc, curr) => acc + curr.progress, 0),
			};
		}
		case "RESET": {
			return INITIAL_STATE;
		}
		default:
			return state;
		}
	};
	const [state, dispatchRequierements] = React.useReducer(
		requierementsReducer,
		INITIAL_STATE
	);

	function addFile(files) {
		dispatchRequierements({
			type: "ADD_FILE",
			payload: {
				files,
			},
		});
	}

	function removeFile(file) {
		dispatchRequierements({
			type: "REMOVE_FILE",
			payload: {
				file,
			},
		});
	}

	function removeAllFilesFromTask(uuid) {
		dispatchRequierements({
			type: "REMOVE_ALL_FILES_FROM_TASK",
			payload: {
				uuid,
			},
		});
	}

	function setTotal(total) {
		dispatchRequierements({
			type: "UPDATE_TOTAL",
			payload: {
				total,
			},
		});
	}

	function setProgressById(id, progress) {
		dispatchRequierements({
			type: "UPDATE_PROGRESS_BY_ID",
			payload: {
				id,
				progress,
			},
		});
	}

	function reset() {
		dispatchRequierements({
			type: "RESET",
		});
	}

	return (
		<RequierementsContext.Provider
			value={{
				requierementsData: state,
				dispatchRequierements,
				addFile,
				removeFile,
				removeAllFilesFromTask,
				setTotal,
				setProgressById,
				reset,
			}}
		>
			{children}
		</RequierementsContext.Provider>
	);
};
