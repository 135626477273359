import React from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import person from "../../../Sources/Icons/Person.svg";
import Email from "../../../Sources/Icons/Email.svg";
import Company from "../../../Sources/Icons/Company.svg";
import { BillingMobileText } from "../../templates/texts";
import { getPayments } from "../../../hooks/auth";

export function BillingMobile() {
	const [address, setAddress] = React.useState("");

	const [loading, setLoading] = React.useState(true);

	const [payments, setPayments] = React.useState([]);
	// const { projectsData } = React.useContext(ProjectsContext);

	const auth = useSelector((state) => state.auth);
	const projects = useSelector((state) => state.projectsRedux);

	React.useEffect(() => {
		if (projects?.project?.uid !== "" && projects?.project?.uid !== undefined) {
			loadPaymentsFromApi();
		}
	}, [projects?.project?.uid]);

	function convertPaymentStatus(payment) {
		const { status } = payment;
		switch (status) {
		case -1:
			return "Not Started";
		case 0:
			return "developing";
		case 1:
			return "Testing";
		case 2:
			return "In aproval";
		case 3:
			return "Completed";
		default:
			return "Unknown";
		}
	}

	async function loadPaymentsFromApi() {
		try {
			// really are all tickets from all projects, we need to filter only payed tickets
			const P = await getPayments(
				auth.user.uid,
				projects.project.uid,
				auth.token
			);
			const PF = P.filter((p) => p.payment.status === "completed");
			setPayments(PF);
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	}

	async function handleSelect(address) {
		try {
			const results = await geocodeByAddress(address);

			const state = results[0].address_components.filter((component) =>
				component.types.includes("administrative_area_level_1")
			);
			const city = results[0].address_components.filter((component) =>
				component.types.includes("locality")
			);
			const zip = results[0].address_components.filter((component) =>
				component.types.includes("postal_code")
			);

			document.getElementById("inputCity").value = city[0].long_name;
			document.getElementById("inputZip").value = zip[0].long_name;
			document.getElementById("inputState").value = state[0].long_name;

			setAddress(address);
		} catch (e) {
			console.error(e);
		}
	}

	function PaymentsMobile({ payments }) {
		return (
			Object.keys(auth.user).length > 0 && (
				<Box>
					<Typography
						display={"block"}
						marginBottom={2}
						variant="taskCollapsedTitle"
					>
						past paymets
					</Typography>
					{payments.map((payment, index) => {
						const createdAt = new Date(payment.createdAt._seconds * 1000);
						const amount = (payment.price / 100).toLocaleString("en-US", {
							style: "currency",
							currency: "USD",
						});
						const date = createdAt.toLocaleDateString("en-US", {
							month: "2-digit",
							day: "2-digit",
							year: "numeric",
						});
						const hour = createdAt.toLocaleTimeString("en-US", {
							hour: "2-digit",
							minute: "2-digit",
						});
						return (
							<Box key={index}>
								<BillingMobileText
									title="Project name:"
									value={payment.project.projectName}
								/>
								<BillingMobileText title="Task name:" value={payment.title} />
								<BillingMobileText title="Amount" value={amount} />
								<BillingMobileText
									title="Payment Date:"
									value={`${date} ${hour}`}
								/>
								<BillingMobileText
									title="Task status:"
									value={convertPaymentStatus(payment)}
								/>

								{payments.length > 0 && (
									<>
										<Divider
											sx={{
												marginY: "20px",
												display: payments.length === index + 1 ? "none" : "",
											}}
										/>
									</>
								)}
							</Box>
						);
					})}
				</Box>
			)
		);
	}
	return (
		<Box className="billing-container">
			<form>
				<TextField
					inputProps={{
						style: {
							padding: "7px",
						},
					}}
					name="firstName"
					size="41px"
					id="firstName"
					placeholder="First Name"
					variant="outlined"
					helperText=" "
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={person} />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="lastName"
					id="lastName"
					placeholder="Last name"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={person} />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					id="email"
					placeholder="Email"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Email} />
							</InputAdornment>
						),
					}}
					disabled
				/>
				<TextField
					name="company"
					id="company"
					placeholder="Website (optional)"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Company} />
							</InputAdornment>
						),
					}}
				/>
				<PlacesAutocomplete
					value={address}
					onChange={setAddress}
					onSelect={handleSelect}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div>
							{/* <input
                                            {...getInputProps({
                                                placeholder: "Address ...",
                                                className: "location-search-input",
                                            })}
                                        /> */}

							<TextField
								name="reference"
								id="reference"
								placeholder="Office, Apt or Suite number"
								variant="outlined"
								fullWidth
								size="small"
								helperText=" "
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="iconAvatar" src={Company} />
										</InputAdornment>
									),
								}}
								{...getInputProps({
									placeholder: "Address ...",
									className: "location-search-input",
								})}
							/>
							<div className="autocomplete-dropdown-container">
								{loading && <div>Loading...</div>}
								{suggestions.map((suggestion, index) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item";
									// inline style for demonstration purpose
									const style = suggestion.active
										? { backgroundColor: "#fafafa", cursor: "pointer" }
										: { backgroundColor: "#ffffff", cursor: "pointer" };
									return (
										<div
											key={index}
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
				<TextField
					name="reference"
					id="reference"
					placeholder="Office, Apt or Suite number"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="iconAvatar" src={Company} />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="inputCity"
					id="inputCity"
					placeholder="City"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="inputState"
					id="inputState"
					placeholder="State"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="inputZip"
					id="inputZip"
					placeholder="ZIP"
					variant="outlined"
					fullWidth
					size="small"
					helperText=" "
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocationOnIcon />
							</InputAdornment>
						),
					}}
				/>
			</form>
			{loading ? (
				<>Loading Payments plase wait</>
			) : (
				<PaymentsMobile payments={payments} />
			)}
		</Box>
	);
}
