import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	IconButton,
	Box,
	// Grid,
	Typography,
	// Button,
	// Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postMethodAuthed } from "../../../backend/services";
import { useSelector } from "react-redux";

export const ModalResign = ({
	open,
	setOpen,
	scroll,
	agreement,
	loadingAgreement,
}) => {
	// why use useState instead const?
	// const [maxWidth, setMaxWidth] = React.useState("md");

	const maxWidth = "md";

	const auth = useSelector((state) => state.auth);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const formSchema = yup.object().shape({
		imageUrl_: yup
			.string()
			.required()
			.matches(
				/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
				"Signature must be png"
			),
	});

	const { handleSubmit } = useForm({
		mode: "onBlur",
		resolver: yupResolver(formSchema),
	});

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = async (data) => {
		try {
			await postMethodAuthed({
				route: "agreement/updateSign",
				body: {
					clientId: auth.user.uid,
					imgUrlSigned: data.imageUrl_,
					accessToken: auth.token,
				},
			}).then(() => {
				handleClose();
				setOpen(false);
				alert("You have successfully resigned from TuneUp");
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Dialog
				maxWidth={maxWidth}
				fullScreen={fullScreen}
				open={open}
				scroll={scroll}
			>
				<DialogTitle>
					Non Disclosure Agreement
					<IconButton
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
						onClick={() => handleClose()}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					style={{ padding: "1% 8% 1% 8%" }}
					className="agreementsContainer"
					dividers={scroll === "paper"}
				>
					<DialogContentText
						component={"form"}
						onSubmit={handleSubmit(onSubmit)}
						textAlign={"justify"}
						sx={{ whiteSpace: "break-spaces", wordWrap: "break-word" }}
					>
						{loadingAgreement ? (
							<Box>
								<Box
									sx={{
										background: "#fff",
										minWidth: "100%",
										[theme.breakpoints.down("md")]: {
											width: "100%",
										},
									}}
								>
									<Typography
										variant="body1"
										sx={{
											paddingLeft: 1,
											minWidth: "100%",
											[theme.breakpoints.down("md")]: {
												paddingLeft: 0,
											},
										}}
										dangerouslySetInnerHTML={{ __html: agreement.content }}
									/>
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<img src={agreement.sign} alt="sign" />
									</Box>
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<Typography variant="subtitle1">
											Name of signatory: {agreement.fullName}
										</Typography>
									</Box>
								</Box>
							</Box>
						) : (
							<Box></Box>
						)}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};
