import React from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";

import { PlatformCard } from "./platformcard";
import { getPlatforms } from "../../hooks/platforms";
import { useDispatch, useSelector } from "react-redux";
import {
	createProject,
	getProjectById,
	getProjectsByUserId,
} from "../../hooks/projects";
import {
	setActiveProjectId,
	setProject,
	setProjects,
	changeNewProjectsFallback,
} from "../../features/project/projectSlice";
export default function ModalPlatforms({ handleOpen }) {
	const auth = useSelector((state) => state.auth);

	const cardRef = React.useRef();
	const dispatch = useDispatch();
	const [platforms, setPlatforms] = React.useState([]);

	const [selectedPlatforms, setSelectedPlatforms] = React.useState([]);
	const validationSchema = yup.object({
		projectName: yup
			.string("Enter your project name")
			.min(1, "project name should be of minimum 1 character length")
			.required("project name is required"),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: "onTouched",
	});

	function handlePlatformSelection(platform, add) {
		if (add) {
			Object.assign(platform, {
				requested: true,
				sharedCredentials: false,
				credentials: {},
			});
			setSelectedPlatforms([...selectedPlatforms, platform]);
		} else {
			setSelectedPlatforms(
				selectedPlatforms.filter((p) => p.id !== platform.id)
			);
		}
	}

	React.useEffect(() => {
		getPlatforms().then((res) => {
			setPlatforms(res);
		});
	}, []);

	// const auth = useSelector((state) => state.auth);

	async function localCreateProject(data) {
		if (selectedPlatforms.length === 0) {
			enqueueSnackbar("select at least one platform", { variant: "error" });
			return;
		}

		const createdProject = await createProject(
			{
				platforms: selectedPlatforms,
				clientUid: auth.user.uid,
				clientName: auth.user.displayName,
				projectName: data.projectName,
				email: auth.user.email,
				activeStep: 3,
				completedSteps: [
					true,
					true,
					true,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				],
				lastCompletedStep: 1,
			},
			auth.token
		);
		const project = await getProjectById(createdProject.uid, auth.token);
		const projects = await getProjectsByUserId(auth.user.uid, auth.token);

		// then set project in redux store
		dispatch(setProject(project));
		dispatch(setActiveProjectId(project.uid));
		dispatch(setProjects(projects));
		dispatch(changeNewProjectsFallback(false));
	}

	return (
		<Box
			sx={{
				width: "50%",
				margin: "0 auto",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "8px",
			}}
			component="form"
			onSubmit={handleSubmit(localCreateProject)}
		>
			<Stack>
				<Typography>The name of your new project</Typography>
				<TextField
					{...register("projectName")}
					name="projectName"
					error={errors.projectName}
					helperText={errors.projectName ? errors.projectName.message : " "}
				/>
			</Stack>
			<Typography alignSelf="flex-start">
				Select the platforms for your project
			</Typography>
			<Grid
				container
				sx={{
					justifyContent: "center",
					paddingLeft: 1,
					paddingRight: 3,
					paddingBottom: 3,
				}}
				spacing={{ xs: 2, sm: 2, md: 5 }}
			>
				{platforms.length > 0 &&
					platforms.map((p) => (
						<PlatformCard
							ref={cardRef}
							key={p.id}
							platform={p}
							handlePlatformSelection={handlePlatformSelection}
						/>
					))}
			</Grid>
			<Stack direction="row" spacing={2}>
				<Button variant="primaryButton" onClick={() => handleOpen(false)}>
					cancel
				</Button>
				<Button variant="primaryButton" type="submit">
					Continue
				</Button>
			</Stack>
		</Box>
	);
}
