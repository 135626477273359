import { useSelector } from "react-redux";
import { Questionnarie } from "../../RequirementsDescription/Questionnarie";
import { ChatBox, EmptyChat } from "../../templates/chatComponents";
import { ChatBubbleLeft, ChatBubbleRight } from "./chatBubble";
// import DisplayAnswers from "./displayAnswers";

export default function DisplayMessages({ messages, questionnarie }) {
	const auth = useSelector((state) => state.auth);
	// const { answers } = useSelector((state) => state.projectsRedux.project);

	return (
		<ChatBox
			elevation={0}
			sx={
				questionnarie && {
					borderRadius: "0px",
					marginBottom: "112px",
				}
			}
		>
			{/*
			{answers?.map((answer) => (
				<DisplayAnswers key={answer} obj={answer} />
			))}
			*/}
			{/*
			<ChatBubbleLeft
				key={""}
				message={`Hi ${auth.user.displayName}, we are working on your quote, you will be notified by email when it is ready or if we need more information. Thank you for your patience.`}
				uid=""
				timestamp={Date.now()}
				name=""
			/>
			 */}
			{!questionnarie ? (
				messages ? (
					Object?.entries(messages)
						?.sort((a, b) => a[1].timestamp - b[1].timestamp)
						?.map((message) => {
							console.log(message[1]);
							return message[1].senderId === auth.user?.uid ? (
								<ChatBubbleRight
									key={message[0]}
									message={message[1].text}
									uid={message[1].senderId}
									timestamp={message[1].timestamp}
									name={auth.user?.displayName?.split(" ")[0]}
									img={message[1].img}
									pfp={auth.user?.photoURL}
								/>
							) : (
								<ChatBubbleLeft
									key={message[0]}
									message={message[1].text}
									uid={message[1].senderId}
									timestamp={message[1].date}
									name={message[1].senderName?.split(" ")[0]}
									img={message[1].img}
									pfp={message[1].photoURL}
									type={message[1]?.type}
								/>
							);
						})
				) : (
					<EmptyChat>
						Await while we connect you with an asociate
						<circularProgress />
					</EmptyChat>
				)
			) : (
				<>
					<Questionnarie />
				</>
			)}
		</ChatBox>
	);
}
