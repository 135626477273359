import React from "react";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { MediaDialog } from "./mediaDialog";
import { BubbleRight } from "../../templates/chatComponents";

export function MessageRight({ message, name }) {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current?.scrollIntoView({
			behavior: "smooth",
		});
	}, [message]);
	return (
		<>
			<BubbleRight>
				<Typography
					sx={{
						wordBreak: "break-word",
						whiteSpace: "break-spaces",
						marginLeft: "5px",
						placeSelf: "center",
					}}
					variant="chatRight"
				>
					{message}
				</Typography>
			</BubbleRight>
			<Stack alignSelf={"flex-end"} alignItems={"center"}>
				{/* <Avatar src={props.pfp} /> */}
				<Typography
					sx={{
						fontSize: "10px",
					}}
					flexWrap={true}
					gutterBottom
					variant="chatRight"
					ref={ref}
				>
					{name}
				</Typography>
			</Stack>
		</>
	);
}
export function MediaMessageRight({ message, img, name }) {
	const [open, setOpen] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);

	function handleOpen() {
		setOpen(true);
	}
	function handleClose() {
		setOpen(false);
	}

	const ref = React.useRef();

	React.useEffect(() => {
		ref.current?.scrollIntoView({
			behavior: "smooth",
		});
		setLoaded(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message && loaded]);
	return (
		<>
			<Dialog
				elevation={0}
				PaperProps={{
					elevation: 0,
					sx: {
						"&.MuiDialog-paper": {
							backgroundColor: "transparent",
						},
					},
				}}
				sx={{
					"& .MuiDialog-container": {
						marginLeft: "18%",
					},
				}}
				open={open}
				onClose={handleClose}
			>
				<MediaDialog img={img} />
			</Dialog>
			<BubbleRight
				sx={{
					marginLeft: "10px",
				}}
				message={message}
				img={img}
			>
				<Card
					elevation={0}
					sx={{
						backgroundColor: "#fff",
						margin: "1px",
						borderRadius: "5px",
					}}
				>
					<CardActionArea onClick={handleOpen}>
						<CardMedia
							onLoad={() => setLoaded(true)}
							component={"img"}
							src={img}
							alt={""}
						/>
					</CardActionArea>
				</Card>
				<Typography
					sx={{
						wordBreak: "break-word",
						whiteSpace: "break-spaces",
						paddingLeft: "5px",
						marginTop: message ? "5px" : 0,
					}}
					variant="chatRight"
					ref={ref}
				>
					{message}
				</Typography>
			</BubbleRight>
			<Stack alignSelf={"flex-end"} alignItems={"center"}>
				{/* <Avatar src={props.pfp} /> */}
				<Typography
					sx={{
						fontSize: "10px",
					}}
					flexWrap={true}
					gutterBottom
					variant="chatRight"
					ref={ref}
				>
					{name}
				</Typography>
			</Stack>
		</>
	);
}
