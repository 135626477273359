import React, { useEffect, useState } from "react";
import { StepperContext } from "../../Context/StepperContext";
import { BackdropContext } from "../../Context/BackdropContext";
// import { useNavigate } from "react-router-dom";
import { db } from "../../firebase.js";
import {
	doc,
	getDoc,
	collection,
	query,
	where,
	getDocs,
} from "firebase/firestore";

import "./Schedule.css";

// import api from "../../config/apiConfig";

//! MUI IMPORTS
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

//! MUI ICONS

//! ICONS
import LeftArrow from "../../Sources/Icons/LeftArrow.svg";
import RightArrow from "../../Sources/Icons/RightArrow.svg";
import { MainPage } from "../templates/MainPage";
import { ChatContext } from "../../Context/ChatContext";
import { useProjects } from "../../hooks/useProjects";
import { ProjectsContext } from "../../Context/ProjectsContext";

import { isMobile } from "../../hooks/utils";

export function ScheduleOld() {
	const { handleComplete, SetActiveStep } = useProjects();
	const { projectsData } = React.useContext(ProjectsContext);
	const { dispatchStepper } = React.useContext(StepperContext);
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);
	// let date = new Date();
	const [scheduledDate, setScheduledDate] = React.useState({
		day: "",
		month: "",
		year: "",
	});
	const [scheduledHour, setScheduledHour] = React.useState("");
	const [linkCalendar, setLinkCalendar] = React.useState("");
	const [ocupatedDates, setOcupatedDates] = React.useState([]);
	// why use a state instead a const, if setUsersAvailability is not used anywhere else?
	// const [usersAvailability, setUsersAvailability] = React.useState(true);
	// const usersAvailability = true;
	const [tasks, setTasks] = React.useState([]);
	const check = isMobile();

	// const navigate = useNavigate();

	const theme = useTheme();

	const s = {
		activeStep: 6,
	};
	const daysOfWeek = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	const daysOfWeekAb = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const { dispatchChat } = React.useContext(ChatContext);

	useEffect(() => {
		dispatchStepper({ type: "SET_STEPS", payload: s });
		dispatchStepper({ type: "HIDE_STEPPER", payload: false });
		dispatchChat({ type: "HIDE_CHAT" });

		getOcupatedHours();
		// getTickets();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		scheduledHour !== "" && generarLinkGoogleCalendar();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduledHour]);

	useEffect(() => {
		scheduledHour !== "" && generarLinkGoogleCalendar();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduledDate]);

	/* 	async function getTickets() {
		const tasks = [];
		const q = query(
			collection(db, "tickets"),
			// CAMBIAR POR EL CURRENT PROJECT
			where(
				"projectId",
				"==",
				JSON.parse(localStorage.getItem("localStorageProjects"))[0].projectId
			)
		);

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			tasks.push({ ...doc.data(), ticketId: doc.id });
		});

		setTasks(tasks);
	} */

	// async function getTasksFromFirestore() {
	// 	const getTasks = {
	// 		method: "GET",
	// 		headers: { "Content-Type": "application/json" },
	// 	};

	// 	try {
	// 		const request = await fetch(
	// 			// REMPLAZAR POR EL ID DEL TICKET
	// 			`${api.url}/ticket/getTicket/3X1FObnt73ZInc1nHQFD3gNjMS93`,
	// 			getTasks
	// 		);
	// 		const request2Json = await request.json();
	// 		getUsersByPlaform(request2Json.data[0].nodes);
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// }

	async function getOcupatedHours() {
		// TOMAR TODOS LOS TASK DESDE FIRESTORE QUE SU DEADLINE SEA MAYOR A LA FECHA ACTUAL
		const tasks = [];
		let now = new Date();

		const q = query(collection(db, "tickets"), where("deadLine", ">", now));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			tasks.push(doc.data());
		});

		// CALCUALRT LOS DIAS Y HORAS QUE VAN A ESTR OCUPADOS
		let ocupatedDates = [];
		tasks.forEach((task) => {
			let deadLine = new Date(task.deadLine.seconds);
			let estimatedTime = task.estimatedTime;
			const ocupatedDateTask = [];
			for (let index = 0; index < estimatedTime; index++) {
				ocupatedDateTask.push({
					day: new Date(deadLine * 1000).getDate(),
					hour: new Date(deadLine * 1000 - index * 3600000).getHours() - 1,
					month: new Date(deadLine * 1000).getMonth(),
					year: new Date(deadLine * 1000).getFullYear(),
					unix: deadLine * 1000 - index * 3600000,
				});
			}
			ocupatedDates = [...ocupatedDates, ocupatedDateTask];
		});
		setOcupatedDates(ocupatedDates);
	}
	// async function getUsersByPlaform(tasks) {
	// 	const platforms = [];
	// 	tasks.forEach((task) => {
	// 		if (task.platforms != null) {
	// 			platforms.push(task.platforms);
	// 		}
	// 	});
	// 	const allPlatformsWithInfo = platforms.map(async (platformConj) => {
	// 		const platformsConjWithInfo = platformConj.map(async (platformId) => {
	// 			const docRef = doc(db, "platforms", platformId);
	// 			const docSnap = await getDoc(docRef);

	// 			if (docSnap.exists()) {
	// 				return docSnap.data();
	// 			}
	// 		});
	// 		return await Promise.all(platformsConjWithInfo);
	// 	});
	// 	const flatPlatforms = await Promise.all(allPlatformsWithInfo.flat());

	// 	const allUsers = await Promise.all(
	// 		flatPlatforms.map(async (platform) => {
	// 			const developers = await Promise.all(
	// 				platform[0].developers
	// 					? platform[0].developers.filter(async (developer) => {
	// 							const docRef = doc(db, "users", developer.id);
	// 							const docSnap = await getDoc(docRef);
	// 							if (docSnap.exists() && docSnap.data().status === "idle") {
	// 								return docSnap.data();
	// 							}
	// 					  })
	// 					: []
	// 			);

	// 			return { ...platform[0], developers: developers };
	// 		})
	// 	);

	// 	let usersAvailableForAllPlatforms = true;

	// 	allUsers.forEach((user) => {
	// 		if (
	// 			user.developers.length > 0 &&
	// 			usersAvailableForAllPlatforms != false
	// 		) {
	// 			usersAvailableForAllPlatforms = true;
	// 		} else {
	// 			usersAvailableForAllPlatforms = false;
	// 		}
	// 	});
	// 	setUsersAvailability(usersAvailableForAllPlatforms);
	// }

	async function sendSelectedDateTime() {
		changeLoadingBackdrop(true);
		// if (
		// 	document.querySelector(".selected") == null ||
		// 	document.querySelector(".selected-hour") == null
		// ) {
		// 	alert("No date or time selected");
		// 	dispatchBackdrop({ type: "CHANGE_LOADING", payload: false });

		// 	return;
		// }

		const scheduledDateTime = {
			day: scheduledDate.day,
			month: scheduledDate.month,
			year: scheduledDate.year,
			hour: scheduledHour,
		};

		const postSelectedDateTime = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(scheduledDateTime),
		};

		try {
			const request = await fetch(
				"https://apimocha.com/advantequotes/scheduled-date-time",
				postSelectedDateTime
			);
			await request.json();
			changeLoadingBackdrop(false);

			handleComplete(projectsData.projectId);
		} catch (e) {
			console.error(e);
			alert(e);
			handleComplete(projectsData.projectId);
			changeLoadingBackdrop(false);
		}
	}
	// async function sendSelectedDateTimeNow() {
	// 	dispatchBackdrop({ type: "CHANGE_LOADING", payload: true });

	// 	const scheduledDateTime = {
	// 		day: scheduledDate.day,
	// 		month: scheduledDate.month,
	// 		year: scheduledDate.year,
	// 		hour: date.getHours() + ":" + date.getMinutes(),
	// 	};

	// 	const postSelectedDateTime = {
	// 		method: "POST",
	// 		headers: { "Content-Type": "application/json" },
	// 		body: JSON.stringify(scheduledDateTime),
	// 	};

	// 	try {
	// 		const request = await fetch(
	// 			"https://apimocha.com/advantequotes/scheduled-date-time",
	// 			postSelectedDateTime
	// 		);
	// 		const request2Json = await request.json();
	// 		dispatchBackdrop({ type: "CHANGE_LOADING", payload: false });
	// 		handleComplete();
	// 		navigate("/meetTheTeam");
	// 	} catch (e) {
	// 		console.error(e);
	// 		alert(e);
	// 		handleComplete();
	// 		dispatchBackdrop({ type: "CHANGE_LOADING", payload: false });
	// 		navigate("/meetTheTeam");
	// 	}
	// }

	function generarLinkGoogleCalendar() {
		// TOTAL DE HORAS QUE DURARAN LOS TASK
		var quantityOfHours = 5;

		const startHourUTC = new Date(
			scheduledDate.year,
			scheduledDate.month,
			scheduledDate.day,
			parseInt(scheduledHour.split(":")[0])
		); // parseInt(scheduledHour.split(":")[0])-(offset/60);
		const endHourUTC = new Date(
			scheduledDate.year,
			scheduledDate.month,
			scheduledDate.day,
			parseInt(scheduledHour.split(":")[0]) + quantityOfHours
		);

		const link =
			"https://www.google.com/calendar/render?action=TEMPLATE&text=Advante+Digital+-+My+First+Tune+Up&details=My+First+Tuneup+starting+time&dates=" +
			padTo2Digits(startHourUTC.getUTCFullYear()) +
			"" +
			padTo2Digits(startHourUTC.getUTCMonth()) +
			"" +
			padTo2Digits(startHourUTC.getUTCDate()) +
			"T" +
			padTo2Digits(startHourUTC.getUTCHours()) +
			padTo2Digits(startHourUTC.getUTCMinutes()) +
			padTo2Digits(startHourUTC.getUTCSeconds()) +
			"Z%2F" +
			padTo2Digits(startHourUTC.getUTCFullYear()) +
			"" +
			padTo2Digits(startHourUTC.getUTCMonth()) +
			"" +
			padTo2Digits(startHourUTC.getUTCDate()) +
			"T" +
			padTo2Digits(endHourUTC.getUTCHours()) +
			padTo2Digits(endHourUTC.getUTCMinutes()) +
			padTo2Digits(endHourUTC.getUTCSeconds()) +
			"Z";
		setLinkCalendar(link);
	}
	function padTo2Digits(num) {
		return num.toString().padStart(2, "0");
	}

	// Schedule your work
	// Choose a date and we will start. We will send you a link to add it
	// 						to your calendar and joint this page so you can share the
	// 						credentials with us.

	useEffect(() => {
		if (projectsData.projectId !== "" && projectsData.projectId !== undefined) {
			SetActiveStep(6);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectsData.projectId]);

	return (
		<MainPage
			header={true}
			title="Schedule your work"
			subtitle="Choose a date and we will start. We will send you a link to add it to your calendar and joint this page so you can share the credentials with us."
			width="55vw"
			show={true}
		>
			{check ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						minHeight: "100vh",
					}}
				>
					<Calendar
						setScheduledDate={setScheduledDate}
						months={months}
						//daysOfWeekAb={daysOfWeekAb} <- unused https://media.discordapp.net/stickers/1040631577555775579.webp?size=240
						scheduledDate={scheduledDate}
						ocupatedDates={ocupatedDates}
					/>
					{scheduledDate.day !== "" && (
						<Box
							sx={{
								height: "100%",
							}}
						>
							<HoursSelector
								setScheduledHour={setScheduledHour}
								scheduledHour={scheduledHour}
								scheduledDate={scheduledDate}
								daysOfWeek={daysOfWeek}
								ocupatedDates={ocupatedDates}
								tasks={tasks}
								setTasks={setTasks}
							/>
							<Stack
								sx={{
									alignItems: "flex-end",
									[theme.breakpoints.down("md")]: { alignItems: "center" },
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										marginTop: "20px, 0px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
											marginLeft: "20px",
											height: "min-content",

											fontFamily: "Ulagadi Sans",
											fontStyle: "normal",
											fontWeight: "400",
											fontSize: "20px",
											lineHeight: "27px",
											color: "#112031",
											marginTop: "30px",
										}}
									>
										{new Date(
											scheduledDate.year,
											scheduledDate.month,
											scheduledDate.day
										).toLocaleDateString("en-US", {
											year: "numeric",
											month: "long",
											day: "numeric",
											weekday: "long",
										})}
									</Box>
								</Box>
								<a
									className="google-calendar-link"
									target="_blank"
									rel="noopener noreferrer"
									href={linkCalendar}
								>
									Save to my Google Calendar
								</a>
								<Stack spacing={3} direction={"row"}>
									{/* {usersAvailability && (
									<Button
										onClick={sendSelectedDateTimeNow}
										variant={"primaryButton"}
									>
										Start now
									</Button>
								)} */}
									<Button
										onClick={sendSelectedDateTime}
										variant={"primaryButton"}
										sx={{ marginBottom: "50px" }}
									>
										Confirm
									</Button>
								</Stack>
							</Stack>
						</Box>
					)}
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						gap: "50px",
						marginTop: "0px",
						alignItems: "stretch",
					}}
				>
					<Box
						sx={{
							width: "60%",
							display: "flex",
							flexWrap: "wrap",
						}}
					>
						<Calendar
							setScheduledDate={setScheduledDate}
							months={months}
							daysOfWeekAb={daysOfWeekAb}
							scheduledDate={scheduledDate}
							ocupatedDates={ocupatedDates}
						/>
					</Box>
					<Box
						sx={{
							width: "40%",
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "flex-end",
						}}
					>
						{scheduledDate.day !== "" && (
							<>
								<HoursSelector
									setScheduledHour={setScheduledHour}
									scheduledHour={scheduledHour}
									scheduledDate={scheduledDate}
									daysOfWeek={daysOfWeek}
									ocupatedDates={ocupatedDates}
									setTasks={setTasks}
									tasks={tasks}
								/>
								<Stack
									sx={{
										alignItems: "flex-end",
										[theme.breakpoints.down("md")]: { alignItems: "center" },
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "flex-end",
											marginTop: "20px, 0px",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-end",
												marginLeft: "20px",
												height: "min-content",

												fontFamily: "Ulagadi Sans",
												fontStyle: "normal",
												fontWeight: "400",
												fontSize: "20px",
												lineHeight: "27px",
												color: "#112031",
												marginTop: "30px",
											}}
										>
											{new Date(
												scheduledDate.year,
												scheduledDate.month,
												scheduledDate.day
											).toLocaleDateString("en-US", {
												year: "numeric",
												month: "long",
												day: "numeric",
												weekday: "long",
											})}
										</Box>
									</Box>
									<a
										className="google-calendar-link"
										target="_blank"
										rel="noopener noreferrer"
										href={linkCalendar}
									>
										Save to my Google Calendar
									</a>
									<Stack spacing={3} direction={"row"}>
										{/* {usersAvailability && (
									<Button
										onClick={sendSelectedDateTimeNow}
										variant={"primaryButton"}
									>
										Start now
									</Button>
								)} */}
										<Button
											onClick={sendSelectedDateTime}
											variant={"primaryButton"}
											sx={{ marginBottom: "50px" }}
										>
											Confirm
										</Button>
									</Stack>
								</Stack>
							</>
						)}
					</Box>
				</Box>
			)}
			{/* {scheduledDate.day !== "" && (
				
			)} */}
		</MainPage>
	);
}

function HoursSelector({
	scheduledHour,
	setScheduledHour,
	scheduledDate,
	ocupatedDates,
	tasks,
}) {
	const [hoursAviability, setHoursAviability] = useState([]);
	const [nodes, setNodes] = useState([]);
	// const { projectsData } = React.useContext(ProjectsContext);

	useEffect(() => {
		calcHoursAviability();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduledDate]);

	useEffect(() => {
		calcHoursAviability();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ocupatedDates]);

	async function calcHoursAviability() {
		const hours = [
			"09:00",
			"09:30",
			"10:00",
			"10:30",
			"11:00",
			"11:30",
			"12:00",
			"12:30",
			"13:00",
			"13:30",
			"14:00",
			"14:30",
			"15:00",
			"15:30",
			"16:00",
			"16:30",
			"17:00",
			"17:30",
		];

		const allTasks = await getAllTickets(new Date());
		// const dateSelected = new Date(
		// 	scheduledDate.year,
		// 	scheduledDate.month,
		// 	scheduledDate.day
		// 	// FALTA CALCULAR QUE HORA, SI ES UN DI DESPUES EMPIEZA DESDE LAS 9, SI ES EL MISMO DIA ES DESDE LA HORA ACTUAL
		// );

		const devsOcupatedHours = [];
		hours.forEach((hour) => {
			const dateToCompare = new Date(
				scheduledDate.year,
				scheduledDate.month,
				scheduledDate.day,
				parseInt(hour.split(":")[0]),
				parseInt(hour.split(":")[1])
			);
			devsOcupatedHours.push(
				getUsersOcupatedHours(new Date(), dateToCompare, allTasks)
			);
		});

		let ocupatedHoursByPlatforms = [];
		const ocupatedPromises = Promise.all(devsOcupatedHours).then(
			async (devsOcupatedHours) => {
				const ocupatedHoursByPlatformsPromises = [];
				const devsHours = groupByUserId(devsOcupatedHours.flat());

				// GET ALL PLATFORMS FROM TICKETS
				const allPlatforms = await getPlatformsFromTickets(allTasks);

				// GET THE COMPLETE PLATFORMS WITH DEV
				const platformsWithDev = await getPlatformsByTicketPlatforms(
					allPlatforms
				);

				platformsWithDev.forEach((platform) => {
					if (platform.developers) {
						ocupatedHoursByPlatformsPromises.push(
							getOcupatedHoursByPlatform(platform, devsHours)
						);
					}
				});
				return ocupatedHoursByPlatformsPromises;
			}
		);
		ocupatedHoursByPlatforms = await ocupatedPromises;

		const allNodes = await takeAllNodesFromTickets(tasks);
		const allPlatformsFromNodes = await takeAllPlatformsFromNodes(allNodes);
		const hoursFromAllNodes = await takeAllHoursFromPlatfromsAndNodes(
			allNodes,
			allPlatformsFromNodes
		);
		setNodes(allNodes);
		const maxHourOfNodes = findMaxValue(hoursFromAllNodes);

		const hoursAvailable = hours.map(async (hour) => {
			let hourOcupated =
				ocupatedDates[0] &&
				ocupatedDates[0].find((date) => {
					return (
						date.day === scheduledDate.day &&
						date.month === scheduledDate.month &&
						date.year === scheduledDate.year &&
						date.hour === parseInt(hour.split(":")[0])
					);
				});
			if (hourOcupated) {
				return {
					available: false,
					textHour: hour,
					...hourOcupated,
				};
			} else if (
				ocupatedHoursByPlatforms[0] &&
				ocupatedHoursByPlatforms[0].hours.find(
					(hourOcupated) => hourOcupated.hour === hour
				)
			) {
				return {
					available: false,
					textHour: hour,
					hour: parseInt(hour.split(":")[0]),
				};
			} else {
				return {
					available: true,
					textHour: hour,
					hour: parseInt(hour.split(":")[0]),
				};
			}
		});
		const availableTimeSlots = getAvailableTimeSlots(
			removeDuplicateHours(await Promise.all(hoursAvailable)),
			maxHourOfNodes
		);

		setHoursAviability(updateAvailabilityByNow(availableTimeSlots));
	}

	// function addEstimatedTimeToTasksByNodes(nodes) {
	// 	const tasksWithEstimatedTime = tasks.map((task) => {
	// 		const platforms = task.platforms;
	// 		const estimatedTime = nodes.reduce((acc, node) => {
	// 			if (node.taskId !== task.taskId) return acc + node.estimatedTime;
	// 		}, 0);
	// 		return { ...task, estimatedTime };
	// 	});
	// 	setTasks(tasksWithEstimatedTime);
	// }

	function updateAvailabilityByNow(array) {
		const currentTime = new Date();
		const currentHour = currentTime.getHours();
		const currentMinutes = currentTime.getMinutes();

		for (const item of array) {
			const [hour, minutes] = item.textHour.split(":").map((x) => parseInt(x));
			if (
				hour < currentHour ||
				(hour === currentHour && minutes < currentMinutes)
			) {
				item.available = false;
			}
		}

		return array;
	}

	function getAvailableTimeSlots(array, duration) {
		// QUE TOME LOS PERIODOS DE TIEMPO LIBES QUE EXISTEN, Y QUE SI EL PERIODO ES MAYOR AL TIEMPO QUE TARDA EN RESOLVERSE EL TICKET, QUE LO AGREGUE A LA LISTA DE HORAS DISPONIBLES
		// RESTAR LA CANTIDAD DE HORAS MAYOR A LA CANTIDAD DE HORAS QUE TARDA EN RESOLVERSE EL TICKET, ESAS HORAS RESTANTES SE AGREGAN A LA LISTA DE HORAS DISPONIBLES

		let availableSlots = [];
		let currentSlot = [];
		let currentHour = array[0];
		for (let i = 0; i < array.length; i++) {
			if (array[i].available === true) {
				if (array[i].hour === currentHour.hour + 1) {
					currentSlot.push(array[i]);
					currentHour = array[i];
				} else {
					if (currentSlot.length > 0) {
						availableSlots.push(currentSlot);
					}
					currentSlot = [array[i]];
					currentHour = array[i];
				}
			} else {
				if (currentSlot.length > 0) {
					availableSlots.push(currentSlot);
				}
				currentSlot = [];
				currentHour = array[i];
			}
		}
		if (currentSlot.length > 0) {
			availableSlots.push(currentSlot);
		}

		let hoursAvailable = [];
		let hoursOfset = duration - hoursAvailable.length;

		availableSlots.forEach((slot) => {
			if (slot.length < duration) {
				availableSlots = availableSlots.filter((item) => item !== slot);
			}
		});
		array.forEach((hour, index) => {
			if (index <= hoursOfset) {
				hoursAvailable.push(hour);
			} else {
				hoursAvailable.push({ ...hour, available: false });
			}
		});

		hoursAvailable.forEach((hour) => {
			hoursAvailable.push({
				...hour,
				textHour: hour.hour === 9 ? "09:30" : hour.hour + ":30",
				available: hour.available,
			});
		});

		return hoursAvailable.sort((a, b) => a.textHour.localeCompare(b.textHour));
	}

	function removeDuplicateHours(array) {
		let newArray = [];
		let currentHour = -1;

		for (let i = 0; i < array.length; i++) {
			if (array[i].hour !== currentHour) {
				newArray.push(array[i]);
				currentHour = array[i].hour;
			}
		}
		return newArray;
	}

	async function getUsersOcupatedHours(dateSelected, dateToCompare, tasks) {
		// GET ALL PLATFORMS FROM TICKETS
		const allPlatforms = await getPlatformsFromTickets(tasks);

		// GET ALL TICKETS WICH SHCEDULED DATE IS GRATTER THAN TODAY
		const tasksWithScheduledDate = [];
		const qTickets = query(
			collection(db, "tickets/"),
			where("scheduledDate", ">=", dateSelected)
		);
		const querySnapshotTickets = await getDocs(qTickets);
		querySnapshotTickets.forEach((doc) => {
			tasksWithScheduledDate.push(doc.data());
		});

		// if (tasksWithScheduledDate.length === 0) {
		// }

		const platformsScheduleTickets = await getPlatformsFromTickets(
			tasksWithScheduledDate
		);
		const nodesScheduleTickets = await getNodesFromsTickets(
			tasksWithScheduledDate
		);
		const duplicatedPlatforms = getDuplicatedPLatforms(
			platformsScheduleTickets,
			allPlatforms
		);
		const platformsWithDevDuplicated = await getPlatformsByTicketPlatforms(
			duplicatedPlatforms
		);
		const nodesWichHaveDevelopersForClientSelectedPlatforms =
			getIfDevIsWorkingInNode(platformsWithDevDuplicated, nodesScheduleTickets);

		const devsOcupatedHours = [];
		nodesWichHaveDevelopersForClientSelectedPlatforms.forEach((node) => {
			let deadLine = new Date(node[0].deadLine.seconds * 1000);
			let scheduledDate = new Date(node[0].scheduledDate.seconds * 1000);

			if (
				dateToCompare.getHours() >= scheduledDate.getHours() &&
				dateToCompare.getHours() <= deadLine.getHours()
			) {
				devsOcupatedHours.push({
					hour:
						dateToCompare.getMinutes() === 0
							? dateToCompare.getHours() + ":00"
							: dateToCompare.getHours() + ":" + dateToCompare.getMinutes(),
					userId: node[0].userId,
					username: node[0].username,
				});
			}
		});

		return devsOcupatedHours;
	}

	function getOcupatedHoursByPlatform(platform, devsHours) {
		const devsOcupatedHours = [];
		platform.developers.forEach((dev) => {
			devsHours[dev.id] &&
				devsHours[dev.id].hours.forEach((hour) => {
					devsOcupatedHours.push({
						hour: hour,
						userId: dev.id,
						username: dev.name,
					});
				});
		});
		return {
			hours: devsOcupatedHours,
			platformId: platform.platformId,
			platformName: platform.name,
		};
	}

	function groupByUserId(arr) {
		return arr.reduce((acc, curr) => {
			if (!acc[curr.userId]) {
				acc[curr.userId] = {
					username: curr.username,
					hours: [],
				};
			}
			acc[curr.userId].hours.push(curr.hour);
			return acc;
		}, {});
	}

	async function getPlatformsFromTickets(tickets) {
		const allPlatforms = [];

		const promises = tickets.map(async (task) => {
			const platforms = [];
			const q = query(
				collection(db, "tickets/" + task.ticketId + "/platforms")
			);

			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				platforms.push(doc.data());
			});
			allPlatforms.push(platforms);
			return platforms;
		});

		await Promise.all(promises);
		return allPlatforms.flat();
	}

	async function getNodesFromsTickets(tickets) {
		const allNodes = [];

		const promises = tickets.map(async (task) => {
			const nodes = [];
			const q = query(collection(db, "tickets/" + task.ticketId + "/nodes"));

			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				nodes.push({
					...doc.data(),
					taskId: task.ticketId,
					deadLine: task.deadLine,
					scheduledDate: task.scheduledDate,
				});
			});
			allNodes.push(nodes);
			return nodes;
		});

		await Promise.all(promises);
		return allNodes.flat();
	}

	async function getPlatformsByTicketPlatforms(allPlatforms) {
		const platformsWithDev = [];
		const promises2 = allPlatforms.flat().map(async (platform) => {
			try {
				const docRef = doc(db, "platforms", platform.platformId.id);
				const docSnap = await getDoc(docRef);

				platformsWithDev.push(docSnap.data());
				return docSnap.data();
			} catch (e) {
				return [];
			}
		});
		await Promise.all(promises2);
		return platformsWithDev;
	}

	function getDuplicatedPLatforms(array1, array2) {
		let duplicates = [];

		array1.forEach((item) => {
			if (array2.find((item2) => item2.platformId.id === item.platformId.id)) {
				duplicates.push(item);
			}
		});

		return duplicates;
	}

	function getIfDevIsWorkingInNode(platforms, nodesToAnalyze) {
		let allNodes = [];

		platforms.forEach((platform) => {
			let nodes = [];

			nodesToAnalyze.forEach((node) => {
				if (platform.developers.find((dev) => dev.id === node.userId)) {
					nodes.push({
						...node,
						platformName: platform.name,
						platformId: platform.platformId,
					});
				}
			});
			allNodes.push(nodes);
		});

		return allNodes;
	}

	function findMaxValue(data) {
		let maxValue = 0;
		data.forEach((item) => {
			if (item.hours > maxValue) {
				maxValue = item.hours;
			}
		});
		return maxValue;
	}

	async function getAllTickets(dateSelected) {
		const tasks = [];
		const q = query(
			collection(db, "tickets/"),
			where("scheduledDate", ">=", dateSelected)
		);

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			tasks.push(doc.data());
		});
		return tasks;
	}

	async function takeAllNodesFromTickets(tickets) {
		const allNodes = [];
		const promises = tickets.map(async (task) => {
			const nodes = [];
			const q = query(collection(db, "tickets/" + task.ticketId + "/nodes"));

			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				nodes.push({
					...doc.data(),
					deadLine: task.deadLine,
					scheduledDate: task.scheduledDate,
				});
			});
			allNodes.push(nodes);
			return nodes;
		});

		await Promise.all(promises);
		return allNodes.flat();
	}

	async function takeAllPlatformsFromNodes(nodes) {
		const allPlatforms = [];

		nodes.forEach((node) => {
			node.platforms &&
				node.platforms.forEach((platform) => {
					allPlatforms.push(platform);
				});
		});
		return allPlatforms;
	}
	async function takeAllHoursFromPlatfromsAndNodes(nodes) {
		let allHoursByPlatform = [];
		nodes.forEach((node) => {
			// allHoursByPlatform.push(
			// 	allHoursByPlatform.find((platform)=>{
			// 		return platform.id === node.platforms[0].id
			// 	}) ? allHoursByPlatform.find((platform)=>{
			// 		return platform.id === node.platforms[0].id
			// 	}).hours += node.estimatedTime : {id: node.platforms[0].id, hours: node.estimatedTime}
			// )

			if (node.platforms[0]) {
				allHoursByPlatform.find((platform) => {
					return platform.id === node.platforms[0].id;
				})
					? allHoursByPlatform.forEach((platform, index) => {
						if (platform.id === node.platforms[0].id) {
							allHoursByPlatform[index].estimatedTime = platform.hours +=
									parseInt(node.estimatedTime);
						}
					  })
					: allHoursByPlatform.push({
						id: node.platforms[0].id,
						hours: parseInt(node.estimatedTime),
					  });
			}
			// allHoursByPlatform[node.platforms[0].id] ? (allHoursByPlatform[node.platforms[0].id] += node.estimatedTime) : (allHoursByPlatform[node.platforms[0].id] = node.estimatedTime)
		});
		return allHoursByPlatform;
	}

	function handleSelectHour(hour, disable) {
		if (!disable) {
			setScheduledHour(hour);

			// try {
			// 	document
			// 		.querySelector(".selected-hour")
			// 		.classList.remove("selected-hour");
			// } catch (e) {
			// 	console.error(e);
			// }
			// document
			// 	.querySelector("[id='" + hour + "hour']")
			// 	.classList.add("selected-hour");

			setScheduledHour(hour);
		}
	}
	const check = isMobile();

	return (
		<Box
			sx={{
				padding: "30px",
				borderRadius: "20px",
				maxHeight: "100%",
				width: check ? "100%" : "587px",
				backgroundColor: "rgba(255, 255, 255, 0.2)",
				backdropFilter: "blur(20px)",
				boxShadow: "8px 8px 20px rgba(192, 192, 192, 0.25)",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography
					variant="calendarMonth"
					sx={{
						width: "100%",
						textAlign: "center",
					}}
				>
					{new Date(
						scheduledDate.year,
						scheduledDate.month,
						scheduledDate.day
					).toLocaleDateString("en-US", {
						year: "numeric",
						month: "long",
						day: "numeric",
						weekday: "long",
					})}
				</Typography>
			</Box>
			<Box
				sx={{
					maxHeight: check ? "calc(100% - 20px)" : "45vh",
					overflowY: "auto",
					paddingRight: "20px",
					paddingBottom: "20px",
				}}
			>
				<List variant="hourList" spacing={0}>
					{hoursAviability &&
						hoursAviability.map((hour, index) => (
							<ListItemButton
								key={index}
								variant="hourItem"
								onClick={() => handleSelectHour(hour.textHour, false)}
								id={hour.textHour + "hour"}
								selected={scheduledHour === hour.textHour}
								disabled={!hour.available}
								className="hour-container"
							>
								<Typography variant="calendarDay">
									{hour.textHour}
									{!hour.available && " Not available"}
									{scheduledHour === hour.textHour && (
										<>
											<p>
												{" "}
												{nodes.length} tickets{" "}
												{nodes.reduce(
													(acc, task) => acc + parseInt(task.estimatedTime),
													0
												)}{" "}
												hours
											</p>
											<div className="tasks-container">
												<ul>
													{nodes.map((task, index) => (
														<li key={index}>
															{task.title} - {parseInt(task.estimatedTime)}{" "}
															hours
														</li>
													))}
												</ul>
											</div>
										</>
									)}
								</Typography>
							</ListItemButton>
						))}
				</List>
			</Box>
		</Box>
	);
}

function Calendar({ scheduledDate, setScheduledDate, months, ocupatedDates }) {
	let date = new Date();
	const [month, setMonth] = useState(date.getMonth());
	const [year, setYear] = useState(date.getFullYear());
	const [days, setDays] = useState([]);

	// const [daySelected, setDaySelected] = useState(false);

	const [lastDateOfMonth, setLastDateOfMonth] = useState(
		new Date(date.getFullYear(), date.getMonth() + 1, 1).getDate()
	);

	const theme = useTheme();

	useEffect(() => {
		setUpCalendar();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		buildDays();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ocupatedDates]);

	useEffect(() => {
		setLastDateOfMonth(new Date(year, month + 1, 0).getDate());
		buildDays();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month]);

	useEffect(() => {
		setLastDateOfMonth(new Date(year, month + 1, 0).getDate());

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year]);

	useEffect(() => {
		buildDays();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastDateOfMonth]);

	function setUpCalendar() {
		setMonth(date.getMonth());
	}

	function buildDays() {
		let preDays = [];

		let lastDateOfLastMonth = new Date(year, month, 0).getDate();
		let dayOfFirstWeek = new Date(year, month, 1).getDay();
		let dayOfLastWeek = new Date(year, month, lastDateOfMonth).getDay();

		for (
			let i = lastDateOfLastMonth - (dayOfFirstWeek - 1);
			i <= lastDateOfLastMonth;
			i++
		) {
			preDays.push({
				day: i,
				current: false,
				disable: true,
				dayOfWeek: new Date(year, month - 1, i).getDay(),
				currentMonth: false,
			});
		}

		for (let i = 1; i <= lastDateOfMonth; i++) {
			let currentDay = false;
			let disable = false;

			if (
				i === date.getDate() &&
				month === date.getMonth() &&
				year === date.getFullYear()
			) {
				currentDay = true;
			}
			if (
				(i < date.getDate() && month === date.getMonth()) ||
				(month < date.getMonth() && year <= date.getFullYear()) ||
				year < date.getFullYear()
			) {
				disable = true;
			}
			if (
				new Date(year, month, i).getDay() === 6 ||
				new Date(year, month, i).getDay() === 0
			) {
				disable = true;
			}
			if (
				calcHoursAviability({
					day: i,
					month: date.getMonth(),
					year: date.getFullYear(),
				}).filter((item) => item.available === true).length === 0
			) {
				disable = true;
			}

			preDays.push({
				day: i,
				current: currentDay,
				disable: disable,
				dayOfWeek: new Date(year, month, i).getDay(),
				currentMonth: true,
			});
		}

		for (let i = 1; i < 7 - dayOfLastWeek; i++) {
			preDays.push({
				day: i,
				current: false,
				disable: true,
				dayOfWeek: new Date(year, month + 1, i).getDay(),
				currentMonth: false,
			});
		}
		setDays(preDays);

		// try {
		// 	document.querySelector(".selected").classList.remove("selected");
		// } catch (e) {
		// 	console.error(e);
		// }
	}

	function calcHoursAviability(dateToCheck) {
		const hours = [
			"09:00",
			"09:30",
			"10:00",
			"10:30",
			"11:00",
			"11:30",
			"12:00",
			"12:30",
			"13:00",
			"13:30",
			"14:00",
			"14:30",
			"15:00",
			"15:30",
			"16:00",
			"16:30",
			"17:00",
			"17:30",
		];
		const hoursAvailable = hours.map((hour) => {
			const hourOcupated =
				ocupatedDates[0] &&
				ocupatedDates[0].find((date) => {
					return (
						date.day === dateToCheck.day &&
						date.month === dateToCheck.month &&
						date.year === dateToCheck.year &&
						date.hour === parseInt(hour.split(":")[0])
					);
				});
			if (hourOcupated) {
				return {
					available: false,
					textHour: hour,
					...hourOcupated,
				};
			} else {
				return {
					available: true,
					textHour: hour,
					hour: parseInt(hour.split(":")[0]),
				};
			}
		});
		return hoursAvailable;
	}

	function changeMonth(cal) {
		if (month + cal === 12) {
			setMonth(0);
			setYear(year + 1);
		} else if (month + cal === -1) {
			setMonth(11);
			setYear(year - 1);
		} else {
			setMonth(month + cal);
		}
	}

	function handleDayClick(day, disable) {
		if (!disable) {
			const date = {
				day: day,
				month: month,
				year: year,
			};
			setScheduledDate(date);
			// return true;

			// try {
			// 	document.querySelector(".selected").classList.remove("selected");
			// } catch (e) {
			// 	console.error(e);
			// }
			// document
			// 	.querySelector("[id='" + day + "dayOfMonth']")
			// 	.classList.add("selected");
		}
	}

	return (
		<Box
			sx={{
				marginTop: "30px",
			}}
		>
			<Stack
				direction={"row"}
				sx={{ justifyContent: "space-between", alignItems: "center" }}
			>
				<IconButton
					variant="iconVisibility"
					onClick={() => {
						changeMonth(-1);
					}}
				>
					<Avatar variant="iconArrows" src={LeftArrow} />
				</IconButton>
				<Typography variant="calendarMonth">{months[month]}</Typography>
				<IconButton
					variant="iconVisibility"
					onClick={() => {
						changeMonth(1);
					}}
				>
					<Avatar variant="iconArrows" src={RightArrow} />
				</IconButton>
			</Stack>

			<List variant="weekHeader">
				<ListItem variant="weekHeaderItem">Sun</ListItem>
				<ListItem variant="weekHeaderItem">Mon</ListItem>
				<ListItem variant="weekHeaderItem">Tue</ListItem>
				<ListItem variant="weekHeaderItem">Wed</ListItem>
				<ListItem variant="weekHeaderItem">Thu</ListItem>
				<ListItem variant="weekHeaderItem">Fri</ListItem>
				<ListItem variant="weekHeaderItem">Sat</ListItem>
			</List>
			<List variant="dayList">
				{/* //! Im not sure this is the correct way to do this (styles) */}
				{days.map((day, index) => (
					<ListItemButton
						disableRipple
						variant="dayItem"
						onClick={() => handleDayClick(day.day, day.disable)}
						key={index}
						sx={{
							[theme.breakpoints.between("md", "lg")]: {
								paddingBottom: "5px",
								paddingRight: "5px",
							},
						}}
						disabled={day.disable}
						selected={day.day === scheduledDate.day}
					>
						{day.currentMonth && (
							<Typography variant={"calendarDay"}>{day.day}</Typography>
						)}
					</ListItemButton>
				))}
			</List>
		</Box>
	);
}
