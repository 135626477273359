import React from "react";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MyAccountMobileIcon from "../../Sources/Icons/MyAccountMobileIcon.svg";
import { MenuComponent } from "./menu";
import { useNavigate } from "react-router-dom";
import { MyAccountContext } from "../../Context/MyAccountContext";
import { useSelector } from "react-redux";

export function MyAccountMobile() {
	const { dispatchMyAccount } = React.useContext(MyAccountContext);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const auth = useSelector((state) => state.auth);

	function handleRoute(e) {
		dispatchMyAccount({ type: "GO_TAB", payload: e.target.value });
		navigate("/MyAccount");
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			{auth.user === null || Object.keys(auth.user).length === 0 ? (
				<IconButton
					onClick={() => navigate("/login")}
					size="small"
					disableRipple
				>
					<Avatar variant="MyAccountMobile" src={MyAccountMobileIcon} />
				</IconButton>
			) : (
				<IconButton onClick={handleClick} size="small" disableRipple>
					<Avatar variant="MyAccountMobile" src={MyAccountMobileIcon} />
				</IconButton>
			)}
			<MenuComponent
				anchorEl={anchorEl}
				open={open}
				handleRoute={handleRoute}
				setAnchorEl={setAnchorEl}
			/>
		</>
	);
}
