import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BackdropContextProvider } from "./Context/BackdropContext";
import { BrowserRouter } from "react-router-dom";
import { ChatContextProvider } from "./Context/ChatContext";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./Store";
// import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
// import LoadingScreen from "./components/persistorLoadingScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const persistor = persistStore(store);
root.render(
	<>
		<BrowserRouter>
			<Provider store={store}>
				{/* <PersistGate loading={<LoadingScreen />} persistor={persistor}> */}
				<SnackbarProvider
					autoHideDuration={2500}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					<ChatContextProvider>
						<BackdropContextProvider>
							<App />
						</BackdropContextProvider>
					</ChatContextProvider>
				</SnackbarProvider>
				{/* </PersistGate> */}
			</Provider>
		</BrowserRouter>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
