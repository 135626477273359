import React from "react";

export const FileContext = React.createContext();
export const FileContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		files: [],
		prevFiles: [],
	};
	const fileReducer = (state, action) => {
		switch (action.type) {
		case "ADD_FILES":
			return {
				...state,
				files: state.files.concat(action.payload.fle),
				prevFiles: state.prevFiles.concat(action.payload.pfle),
			};
		case "REMOVE_FILE":
			return {
				...state,
				files: action.newFiles,
				prevFiles: action.newPrevFiles,
			};
		case "REMOVE_ALL_FILES":
			return {
				files: [],
				prevFiles: [],
			};
		case "UPDATE_PROGRESS":
			return {
				...state,
				prevFiles: action.newFiles.prevFiles,
			};
		default:
			return state;
		}
	};
	const [state, dispatchFile] = React.useReducer(fileReducer, INITIAL_STATE);
	return (
		<FileContext.Provider value={{ fileData: state, dispatchFile }}>
			{children}
		</FileContext.Provider>
	);
};
