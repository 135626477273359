function Loader(props) {
	return (
		<div className="loading-box">
			<div className="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<p>{props.text}</p>
		</div>
	);
}

export { Loader };
