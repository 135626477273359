// import React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Zoom from "@mui/material/Zoom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Logout } from "../../hooks/auth";
import { restartProjects } from "../../features/project/projectSlice";
import { logout } from "../../features/auth/authSlice";
import { RequierementsContext } from "../../Context/RequierementsContext";
import React from "react";
// import { MyAccountContext } from "../../Context/MyAccountContext";

export function MenuComponent({ anchorEl, open, handleRoute, setAnchorEl }) {
	const projects = useSelector((state) => state.projectsRedux);
	const auth = useSelector((state) => state.auth);

	const { dispatchRequierements } = React.useContext(RequierementsContext);

	const dispatch = useDispatch();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const someProjectHasPayed = projects?.projects?.some(
		(project) => project.completedSteps[5] === true
	);

	return (
		<Menu
			id="profile-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			MenuListProps={{
				"aria-labelledby": "account-button",
			}}
			sx={{
				"& .MuiPopover-paper": {
					marginTop: "1px",
					backgroundColor: "#ffffff80",
					backdropFilter: "blur(10px)",
					borderRadius: "15px",
					width: "200px",
					boxShadow: "8px 8px 20px #c0c0c040",
				},
			}}
			TransitionComponent={Zoom}
		>
			<Box>
				{someProjectHasPayed && (
					<>
						<Stack>
							<Typography
								variant="myAccountMenuTitle"
								sx={{
									textAlign: "center",
								}}
							>
								{auth.user.displayName}
							</Typography>

							<Typography variant="myAccountMenuSubtitle" textAlign={"center"}>
								{auth.user?.company ?? ""}
							</Typography>
						</Stack>
						<MenuList
							sx={{
								"& .MuiMenuItem-root": {
									paddingLeft: "10%",
								},
							}}
						>
							<MenuItem
								variant="myAccountMenuItem"
								onClick={handleRoute}
								value={0}
							>
								My Profile
							</MenuItem>
							{/*
							<MenuItem
								variant="myAccountMenuItem"
								onClick={handleRoute}
								value={1}
							>
								My settings
							</MenuItem>
							*/}
							<MenuItem
								variant="myAccountMenuItem"
								onClick={handleRoute}
								value={2}
							>
								Privacy Policy
							</MenuItem>
							<MenuItem
								variant="myAccountMenuItem"
								onClick={handleRoute}
								value={3}
							>
								Billing and Payments
							</MenuItem>
						</MenuList>
					</>
				)}

				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Link
						color={"#C0C0C0"}
						sx={{
							":hover": { cursor: "pointer" },
							my: 1,
							fontSize: "15px",
							fontWeight: "400",
						}}
						textAlign={"center"}
						onClick={() => {
							Logout();
							dispatch(logout());
							dispatch(restartProjects());
							dispatchRequierements({ type: "RESET_TASKS" });
							handleClose();
						}}
					>
						Sign out
					</Link>
				</Box>
			</Box>
		</Menu>
	);
}
