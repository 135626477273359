import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
// import { postMethod } from "../../../backend/services";
import { PlatformCard } from "./platformCard";
import { getPlatforms } from "../../../hooks/platforms";
import { useDispatch, useSelector } from "react-redux";
import { createProject, getProjectById } from "../../../hooks/projects";
import {
	setActiveProjectId,
	setProject,
	setProjects,
	changeNewProjectsFallback,
} from "../../../features/project/projectSlice";
export function ModalPlatforms() {
	const [platforms, setPlatforms] = React.useState([]);

	// const {
	// 	AuthData,
	// 	changeHideStepper,
	// 	setSelectedProject,
	// 	setProjects,
	// 	handleComplete,
	// } = UserAuth();

	React.useEffect(() => {
		getPlatforms().then((res) => {
			setPlatforms(res);
		});
	}, []);

	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth);
	const projectStore = useSelector((state) => state.projectsRedux.project);

	async function localCreateProject() {
		const createdProject = await createProject(
			{
				platforms: projectStore.platforms,
				clientUid: auth.user.uid,
				clientName: auth.user.displayName,
				email: auth.user.email,
				activeStep: 2,
				completedSteps: [
					true,
					true,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				],
				lastCompletedStep: 1,
			},
			auth.token
		);
		const project = await getProjectById(createdProject.uid, auth.token);
		// then set project in redux store
		dispatch(setProject(project));
		dispatch(setActiveProjectId(project.uid));
		dispatch(setProjects([project]));
		dispatch(changeNewProjectsFallback(false));
	}

	return (
		<Box
			sx={{
				width: "50%",
				margin: "0 auto",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "8px",
			}}
		>
			<Typography alignSelf={"flex-start"}>
				Looks like you have an account, but not any project, please select the
				platforms to continue
			</Typography>
			<Grid
				container
				sx={{
					justifyContent: "center",
					paddingLeft: 1,
					paddingRight: 3,
					paddingBottom: 3,
				}}
				spacing={{ xs: 2, sm: 2, md: 5 }}
			>
				{platforms.length > 0 &&
					platforms.map((p) => <PlatformCard key={p.id} platform={p} />)}
			</Grid>
			<Button
				sx={{
					visibility:
						projectStore?.platforms?.length > 0 ? "visible" : "hidden",
					opacity: projectStore?.platforms?.length > 0 ? 1 : 0,
					transition: "opacity 0.25s ease-in-out",
				}}
				variant="primaryButton"
				width="60%"
				onClick={localCreateProject}
			>
				Continue
			</Button>
		</Box>
	);
}
