import { Accordion, AccordionSummary, Avatar } from "@mui/material";

import React from "react";

import ExpandIcon from "../../Sources/Icons/ExpandIcon.svg";

export function ReusableAccordion({
	expandedheader,
	colapsedHeader,
	details,
	hideIcon,
	transformOpacity,
	accordionProps,
}) {
	const [extended, setExtended] = React.useState(false);
	function handleChange(extended) {
		setExtended(extended);
	}

	return (
		<Accordion
			{...accordionProps}
			elevation={0}
			sx={{
				backgroundColor: "transparent",
				boxShadow: "8px 8px 20px #c0c0c040",
				borderRadius: "15px",
				"&.MuiAccordion-gutters": {
					marginY: "10px",
				},

				"&:before": { display: "none" },
				"& .MuiAccordionSummary-root.Mui-expanded": {
					backgroundColor: "#ffffffcc",
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
				"& .MuiPaper-root-MuiAccordion-root": {
					borderRadius: "15px",
				},
				"& .MuiAccordionSummary-root": {
					borderRadius: "15px",
					backgroundColor: "#ffffffcc",
				},
				"&.MuiPaper-root.MuiAccordion-root:last-of-type": {
					borderBottomLeftRadius: "15px",
					borderBottomRightRadius: "15px",
				},
				"&.MuiPaper-root.MuiAccordion-root:first-of-type": {
					borderTopLeftRadius: "15px",
					borderTopRightRadius: "15px",
				},
				"&	.MuiAccordionSummary-gutters": {
					paddingX: 4,
				},
			}}
			onChange={(e, extended) => handleChange(extended)}
		>
			<AccordionSummary
				expandIcon={
					hideIcon ? (
						extended ? (
							<></>
						) : (
							<Avatar variant="sidebarProjectIcon" src={ExpandIcon} />
						)
					) : (
						<Avatar
							sx={{
								"& .MuiAvatar-img": {
									filter: transformOpacity
										? "grayscale(1) opacity(0.5)"
										: "none",
								},
							}}
							variant="sidebarProjectIcon"
							src={ExpandIcon}
						/>
					)
				}
			>
				{extended ? expandedheader : colapsedHeader}
			</AccordionSummary>
			{details}
		</Accordion>
	);
}
