import React from "react";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";

import { enqueueSnackbar } from "notistack";
// import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";

// import { db } from "../../firebase";
import SendIconImage from "../../Sources/Icons/SendIcon.svg";
import { Loader } from "./Loader";

// import { ChatContext } from "../../Context/ChatContext";

import "./ChatQuestionnarie.css";
import "./Questionnarie.css";

import { Question } from "./Question";
import { sendAnswersApi } from "../../hooks/projects";
import { ChatBubbleLeft } from "../Chat/components/chatBubble";
import { getQuestions } from "../../hooks/chat";
import { sendAnswer } from "../../features/project/projectSlice";
import DisplayAnswers from "../Chat/components/displayAnswers";

const Container = styled(Paper)(({ theme }) => ({
	backgroundColor: "transparent",
	...theme.typography.body2,
	textAlign: "center",
	display: "flex",
	flexDirection: "row",
	color: theme.palette.text.secondary,
	alignItems: "center",
	margin: theme.spacing(2),
}));

export function Questionnarie() {
	const [questions, setQuestions] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [idQuestion, setIdQuestion] = React.useState("Q");
	const [idPrevQuestion, setIdPrevQuestion] = React.useState("Q");
	const [answers, setAnswers] = React.useState([]);
	const [nextQuestionId, setNextQuestionId] = React.useState("Q");
	const [currentQuestion, setCurrentQuestion] = React.useState(null);
	const [sended, setSended] = React.useState(false);

	const auth = useSelector((state) => state.auth);

	const projects = useSelector((state) => state.projectsRedux);

	const dispatch = useDispatch();

	React.useEffect(() => {
		loadQuestions();
	}, []);

	React.useEffect(() => {
		if (!loading) {
			setCurrentQuestion(questions.filter(getQuestionById)[0]);
		}
	}, [idQuestion]);

	React.useEffect(() => {
		unchequedRadios();
	}, [currentQuestion]);

	// const { dispatchChat } = React.useContext(ChatContext);

	React.useEffect(() => {
		if (nextQuestionId === "END" && projects.project.answeredQuestionnaire) {
			setSended(true);
			return;
		}
		if (nextQuestionId === "END" && !projects.project.answeredQuestionnaire) {
			sendAnswers();
			setSended(true);
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers]);

	// this use effect is not necessary

	async function loadQuestions() {
		const questionnaire = await getQuestions(auth.token);
		setQuestions(questionnaire);

		if (projects.project.answers.length === questionnaire.length) {
			setAnswers(projects.project.answers);
			setCurrentQuestion(questionnaire[projects.project.answers.length - 1]);
			setNextQuestionId("END");
			setLoading(false);
			return;
		}

		if (projects.project.answers.length > 0) {
			setAnswers(projects.project.answers);
			setCurrentQuestion(questionnaire[projects.project.answers.length]);
			setNextQuestionId(
				projects.project.answers[projects.project.answers.length - 1].next
			);
			setLoading(false);
			return;
		}
		setCurrentQuestion(questionnaire[0]);
		setNextQuestionId(questionnaire[0].next);
		setLoading(false);
	}

	function handelPrevQuestionBtn() {
		setIdQuestion(idPrevQuestion);
		// setIdPrevQuestion(indexPrevQuestion - 1);
	}

	function handelNextQuestionBtn(next) {
		if (next === "END") {
			return;
		}
		setIdPrevQuestion(idQuestion);
		setIdQuestion(next);
	}

	async function sendAnswers() {
		const data = {
			uid: projects.project.uid,
		};

		try {
			await sendAnswersApi(data, auth.token);
		} catch (error) {
			console.error(error);
			enqueueSnackbar("somethingWentWrong", {
				variant: "error",
			});
		}
	}

	function handleInput(e, next) {
		setNextQuestionId(next);

		if (e.target.type === "radio") {
			const btnSendQuestion = document.getElementById("btnSendQuestion");
			btnSendQuestion.disabled = false;
			btnSendQuestion.click();
		}
	}

	function handleSubmit(e) {
		e.preventDefault();
		const value = JSON.parse(e.target.options.value);

		let answerBringed = "";
		let next = "";
		const id = value.id;

		if (e.target.options) {
			answerBringed = value.option;
			next = value.next;
		} else if (e.target.respuestaAbierta.value !== "") {
			answerBringed = e.target.respuestaAbierta.value;
		} else {
			alert("Your answer is empty");
			return;
		}
		if (e.target.nextQuestion) {
			dispatch(
				sendAnswer({
					question: e.target.question.value,
					answer: answerBringed,
					id,
				})
			);
			setAnswers([
				...answers,
				{ question: e.target.question.value, answer: answerBringed, id },
			]);
		}

		handelNextQuestionBtn(next);
	}

	function unchequedRadios() {
		let radios = document.querySelectorAll(".radio");
		for (let index = 0; index < radios.length; index++) {
			radios[index].checked = false;
		}
	}

	function getQuestionById(question) {
		if (question["id"] === idQuestion) {
			return true;
		}
		return false;
	}
	//todo[] change all dom manipulation by ids to react refs
	return (
		<>
			{!loading && auth.user !== null && (
				<form className="form-questionnarie" onSubmit={handleSubmit}>
					<div className="message-container-left">
						{/* <img src={ProjectIcon} alt="" /> */}
						<ChatBubbleLeft
							key={""}
							message={
								"Hello " +
								auth.user.displayName +
								", one of our associates is taking a look at your requirements. In order to have all the information we need, please answer the following:"
							}
							uid=""
							timestamp={Date.now()}
							name=""
						/>
					</div>
					{/*
						//todo[] refactor whats inside the map to a component
					*/}
					{answers.map((answer) => (
						<DisplayAnswers key={answer} obj={answer} />
					))}
					{/* 
						//todo[] make more readable this conditional rendering vvv 
					*/}
					{!sended ? (
						<div className="message-container-left">
							{/* <img src={ProjectIcon} alt="" /> */}
							<ChatBubbleLeft
								key={""}
								message={currentQuestion["question"]}
								uid=""
								timestamp={Date.now()}
								name=""
							/>
						</div>
					) : (
						<>
							<p
								style={{
									display: "block",
									width: "100%",
									textAlign: "center",
									paddingBottom: "50px",
									paddingTop: "50px",
									color: "#333",
									fontFamily: "Ulagadi Sans,sans-serif",
									fontWeight: "400",
									fontSize: "13px",
								}}
							>
								Your requirements and answers are saved. Please wait for an
								associate to finalize the quote.
							</p>
							<Loader text={""} />
						</>
					)}

					<input
						type="hidden"
						id="nextQuestion"
						defaultValue={currentQuestion["question-next"]}
						name="nextQuestion"
					/>
					<input
						type="hidden"
						defaultValue={currentQuestion["question"]}
						name="question"
					/>
					<button
						style={{ marginRigth: "0px", display: "none" }}
						className="btn btn-prev"
						id="btnPrev"
						type="button"
						onClick={handelPrevQuestionBtn}
					>
						Previous question
					</button>

					{!sended && (
						<Question question={currentQuestion} handleInput={handleInput} />
					)}
					<div
						className="input-send-message"
						style={{ zIndex: "10000", display: "none" }}
					>
						<Container elevation={0} sx={{ margin: "0px" }}>
							<Stack component={"div"} sx={{ width: "100%" }} direction={"row"}>
								{!sended && (
									<>
										{!currentQuestion["options"] ? (
											<InputBase
												sx={{
													paddingLeft: 1,
													background: "rgba(255, 255, 255, 0.8)",
													border: "1px solid #C0C0C0",
													backdropFilter: "blur(10px)",
													borderRadius: "9px",
													fontFamily: "Ulagadi Sans",
													fontStyle: "normal",
													fontWeight: "400",
													fontSize: "16px",
													lineHeight: "19px",
													color: "#000000",
													padding: "10px 10px 20px 10px",
												}}
												name="respuestaAbierta"
												fullWidth
												onClick={(e) => {
													handleInput(e, currentQuestion["next"]);
												}}
												placeholder={"Write an answer..."}
												defaultValue=""
											/>
										) : (
											<InputBase
												sx={{
													paddingLeft: 1,
													background: "rgba(255, 255, 255, 0.8)",
													border: "1px solid #C0C0C0",
													backdropFilter: "blur(10px)",
													borderRadius: "9px",
													fontFamily: "Ulagadi Sans",
													fontStyle: "normal",
													fontWeight: "400",
													fontSize: "16px",
													lineHeight: "19px",
													color: "#000000",
													padding: "10px 10px 20px 10px",
												}}
												fullWidth
												disabled={true}
												placeholder={"Select an answer..."}
											/>
										)}
										<Divider orientation="vertical" variant="middle" flexItem />
										<IconButton
											disabled={nextQuestionId === currentQuestion.id}
											sx={{
												alignSelf: "flex-end",
												paddingBottom: "0px",
												paddingLeft: 2,
											}}
											disableRipple
											type="submit"
											id="btnSendQuestion"
										>
											<Avatar
												variant="iconPlatforms"
												sx={
													nextQuestionId === currentQuestion.id && {
														filter: "grayscale(100%)",
													}
												}
												src={SendIconImage}
											/>
										</IconButton>
									</>
								)}
							</Stack>
						</Container>
					</div>
				</form>
			)}
		</>
	);
}
