import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { BackdropContext } from "../../Context/BackdropContext";
import { useSelector } from "react-redux";

function LoadingGeneric() {
	const { backdropData } = React.useContext(BackdropContext);

	return (
		<Backdrop
			sx={{
				color: "#fff",
				backgroundColor: "rgba(0, 0, 0, 0.2)",

				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={backdropData.loadingBackdrop}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}

export function LoadingMoreGeneric() {
	const projects = useSelector((state) => state.projectsRedux);

	return (
		<Backdrop
			sx={{
				color: "#fff",
				backgroundColor: "rgba(0, 0, 0, 0.2)",

				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={projects?.isLoading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}

export default LoadingGeneric;
