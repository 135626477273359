import React from "react";

import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LeftArrow from "../../Sources/Icons/LeftArrow.svg";
import RightArrow from "../../Sources/Icons/RightArrow.svg";

import { months } from "./info";

export default function Calendar() {
	// 	{
	// 	scheduledDate,
	// 	setScheduledDate,
	// 	ocupatedDates,
	// }
	let date = new Date();
	const [month, setMonth] = React.useState(date.getMonth());
	const [year, setYear] = React.useState(date.getFullYear());
	const [days, setDays] = React.useState([]);

	// const [daySelected, setDaySelected] = React.useState(false);
	const [lastDateOfMonth, setLastDateOfMonth] = React.useState(
		new Date(date.getFullYear(), date.getMonth() + 1, 1).getDate()
	);

	const theme = useTheme();

	React.useEffect(() => {
		setUpCalendar();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// React.useEffect(() => {
	// 	buildDays();

	// 	//eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [ocupatedDates]);

	React.useEffect(() => {
		setLastDateOfMonth(new Date(year, month + 1, 0).getDate());
		buildDays();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month]);

	React.useEffect(() => {
		setLastDateOfMonth(new Date(year, month + 1, 0).getDate());

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year]);

	React.useEffect(() => {
		buildDays();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastDateOfMonth]);

	function setUpCalendar() {
		setMonth(date.getMonth());
	}

	function buildDays() {
		let preDays = [];

		let lastDateOfLastMonth = new Date(year, month, 0).getDate();
		let dayOfFirstWeek = new Date(year, month, 1).getDay();
		let dayOfLastWeek = new Date(year, month, lastDateOfMonth).getDay();

		for (
			let i = lastDateOfLastMonth - (dayOfFirstWeek - 1);
			i <= lastDateOfLastMonth;
			i++
		) {
			preDays.push({
				day: i,
				current: false,
				disable: true,
				dayOfWeek: new Date(year, month - 1, i).getDay(),
				currentMonth: false,
			});
		}

		for (let i = 1; i <= lastDateOfMonth; i++) {
			let currentDay = false;
			let disable = false;

			if (
				i === date.getDate() &&
				month === date.getMonth() &&
				year === date.getFullYear()
			) {
				currentDay = true;
			}
			if (
				(i < date.getDate() && month === date.getMonth()) ||
				(month < date.getMonth() && year <= date.getFullYear()) ||
				year < date.getFullYear()
			) {
				disable = true;
			}
			if (
				new Date(year, month, i).getDay() === 6 ||
				new Date(year, month, i).getDay() === 0
			) {
				disable = true;
			}
			// if (
			// 	calcHoursAviability({
			// 		day: i,
			// 		month: date.getMonth(),
			// 		year: date.getFullYear(),
			// 	}).filter((item) => item?.available === true).length === 0
			// ) {
			// 	disable = true;
			// }

			preDays.push({
				day: i,
				current: currentDay,
				disable: disable,
				dayOfWeek: new Date(year, month, i).getDay(),
				currentMonth: true,
			});
		}

		for (let i = 1; i < 7 - dayOfLastWeek; i++) {
			preDays.push({
				day: i,
				current: false,
				disable: true,
				dayOfWeek: new Date(year, month + 1, i).getDay(),
				currentMonth: false,
			});
		}
		setDays(preDays);

		// try {
		// 	document.querySelector(".selected").classList.remove("selected");
		// } catch (e) {
		// 	console.error(e);
		// }
	}

	// function calcHoursAviability(dateToCheck) {
	// 	const hours = [
	// 		"09:00",
	// 		"09:30",
	// 		"10:00",
	// 		"10:30",
	// 		"11:00",
	// 		"11:30",
	// 		"12:00",
	// 		"12:30",
	// 		"13:00",
	// 		"13:30",
	// 		"14:00",
	// 		"14:30",
	// 		"15:00",
	// 		"15:30",
	// 		"16:00",
	// 		"16:30",
	// 		"17:00",
	// 		"17:30",
	// 	];
	// 	// const hoursAvailable = hours.map((hour) => {
	// 	// 	const hourOcupated =
	// 	// 		ocupatedDates[0] &&
	// 	// 		ocupatedDates[0].find((date) => {
	// 	// 			return (
	// 	// 				date.day === dateToCheck.day &&
	// 	// 				date.month === dateToCheck.month &&
	// 	// 				date.year === dateToCheck.year &&
	// 	// 				date.hour === parseInt(hour.split(":")[0])
	// 	// 			);
	// 	// 		});
	// 	// 	if (hourOcupated) {
	// 	// 		return {
	// 	// 			available: false,
	// 	// 			textHour: hour,
	// 	// 			...hourOcupated,
	// 	// 		};
	// 	// 	} else {
	// 	// 		return {
	// 	// 			available: true,
	// 	// 			textHour: hour,
	// 	// 			hour: parseInt(hour.split(":")[0]),
	// 	// 		};
	// 	// 	}
	// 	// });
	// 	return hoursAvailable;
	// }

	function changeMonth(cal) {
		if (month + cal === 12) {
			setMonth(0);
			setYear(year + 1);
		} else if (month + cal === -1) {
			setMonth(11);
			setYear(year - 1);
		} else {
			setMonth(month + cal);
		}
	}

	function handleDayClick(day, disable) {
		if (!disable) {
			// const date = {
			// 	day: day,
			// 	month: month,
			// 	year: year,
			// };
			// setScheduledDate(date);
			// return true;
			// try {
			// 	document.querySelector(".selected").classList.remove("selected");
			// } catch (e) {
			// 	console.error(e);
			// }
			// document
			// 	.querySelector("[id='" + day + "dayOfMonth']")
			// 	.classList.add("selected");
		}
	}

	return (
		<Box
			sx={{
				marginTop: "30px",
				width: "100%",
			}}
		>
			<Stack
				direction={"row"}
				sx={{ justifyContent: "space-between", alignItems: "center" }}
			>
				<IconButton
					variant="iconVisibility"
					onClick={() => {
						changeMonth(-1);
					}}
				>
					<Avatar variant="iconArrows" src={LeftArrow} />
				</IconButton>
				<Typography variant="calendarMonth">{months[month]}</Typography>
				<IconButton
					variant="iconVisibility"
					onClick={() => {
						changeMonth(1);
					}}
				>
					<Avatar variant="iconArrows" src={RightArrow} />
				</IconButton>
			</Stack>

			<List variant="weekHeader">
				<ListItem variant="weekHeaderItem">Sun</ListItem>
				<ListItem variant="weekHeaderItem">Mon</ListItem>
				<ListItem variant="weekHeaderItem">Tue</ListItem>
				<ListItem variant="weekHeaderItem">Wed</ListItem>
				<ListItem variant="weekHeaderItem">Thu</ListItem>
				<ListItem variant="weekHeaderItem">Fri</ListItem>
				<ListItem variant="weekHeaderItem">Sat</ListItem>
			</List>
			<List variant="dayList">
				{days.map((day, index) => (
					<ListItemButton
						disableRipple
						variant="dayItem"
						onClick={() => handleDayClick(day.day, day.disable)}
						key={index}
						sx={{
							[theme.breakpoints.between("md", "lg")]: {
								paddingBottom: "5px",
								paddingRight: "5px",
							},
						}}
						disabled={day.disable}
						// selected={day.day === scheduledDate.day}
					>
						{day.currentMonth && (
							<Typography variant={"calendarDay"}>{day.day}</Typography>
						)}
					</ListItemButton>
				))}
			</List>
		</Box>
	);
}
