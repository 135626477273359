import { MainPage } from "../templates/MainPage";
import HourSelector from "./hourSelector";
import Calendar from "./calendar";
import { Stack } from "@mui/material";

export default function Schedule() {
	return (
		<MainPage
			header={true}
			title="Schedule your work"
			subtitle="Choose a date and we will start. We will send you a link to add it to your calendar and joint this page so you can share the credentials with us."
			width="55vw"
			show={true}
		>
			<Stack direction="row" spacing={4}>
				<Stack
					sx={{
						width: "60%",
					}}
				>
					<Calendar
					// setScheduledDate={setScheduledDate} // <- change to context
					// scheduledDate={scheduledDate} // <- change to context
					// ocupatedDates={ocupatedDates} // <- change to context
					/>
				</Stack>
				<Stack
					sx={{
						width: "40%",
						justifyContent: "flex-end",
					}}
				>
					<HourSelector />
				</Stack>
			</Stack>
		</MainPage>
	);
}
