import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { geocodeByAddress } from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

//! MUI IMPORTS
import { Avatar, InputAdornment, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
/* Icons */
import person from "../../Sources/Icons/Person.svg";
import Email from "../../Sources/Icons/Email.svg";
import Company from "../../Sources/Icons/Company.svg";
import Addres from "../../Sources/Icons/adreesBillingIcon.svg";
import City from "../../Sources/Icons/localitationIcon.svg";

import "./Invoice.css";
import { MainPage } from "../templates/MainPage";
import { ChatContext } from "../../Context/ChatContext";
import {
	handleComplete,
	setActiveStep,
	updateProject,
} from "../../features/project/projectSlice";
import { UpdateBillingAddress } from "../../features/auth/authSlice";
// SCHEDULES OPTION
// import { SecondaryButton } from "../templates/inputs/buttons";

function Invoice() {
	//	TODO[] change all api related things to hooks and helper instead of fetch
	//	TODO[] refactor to use material-ui for autocomplete

	const auth = useSelector((state) => state.auth);
	const project = useSelector((state) => state.projectsRedux.project);

	const { dispatchChat } = React.useContext(ChatContext);

	const dispatch = useDispatch();
	const [address, setAddress] = React.useState(
		auth?.user?.billingAddress?.address || ""
	);
	const validationSchema = yup.object({
		firstName: yup
			.string("")
			.min(1, "First name should be of minimum 1 character length")
			.required("First Name is required"),
		lastName: yup
			.string("")
			.min(1, "Last name should be of m minimum 1 character length")
			.required("Last name is required"),
		email: yup
			.string("")
			.email("enter a valid email")
			.required("Email is required"),
		company: yup.string(""),
		city: yup.string("").required("City is required"),
		state: yup.string("").required("State is required"),
		zip: yup.string("").required("Zip is required"),
		office: yup.string("").optional(),
	});

	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			firstName: auth.user.firstName,
			lastName: auth.user.lastName,
			email: auth.user.email,
			company: auth.user?.company,
			city: auth.user?.billingAddress?.city || "",
			state: auth.user?.billingAddress?.state || "",
			zip: auth.user?.billingAddress?.zip || "",
			office: auth.user?.billingAddress?.office || "",
		},
		mode: "onTouched",
	});

	React.useEffect(() => {
		dispatchChat({ type: "HIDE_CHAT" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleSelect(address) {
		try {
			const results = await geocodeByAddress(address);

			const state = results[0].address_components.filter((component) =>
				component.types.includes("administrative_area_level_1")
			);
			const city = results[0].address_components.filter((component) =>
				component.types.includes("locality")
			);
			const zip = results[0].address_components.filter((component) =>
				component.types.includes("postal_code")
			);
			// setValue("address", address, { shouldValidate: true });
			setAddress(address);
			setValue("city", city[0].long_name, { shouldValidate: true });
			setValue("zip", zip[0].long_name, { shouldValidate: true });
			setValue("state", state[0].long_name, { shouldValidate: true });
		} catch (e) {
			console.error(e);
		}
	}
	async function submit(e) {
		try {
			const data = {
				firstname: e.firstName,
				lastname: e.lastName,
				email: e.email,
				address: address,
				company: e.company,
				zip: e.zip,
				office: e.office,
				city: e.city,
				state: e.state,
			};
			dispatch(UpdateBillingAddress(data));
			if (!project.completedSteps[6]) {
				dispatch(handleComplete(6));
				dispatch(handleComplete(7));
			} else {
				dispatch(setActiveStep(8));
			}
			dispatch(updateProject());
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Error updating billing address", { variant: "error" });
		}
	}

	return (
		<MainPage
			header={true}
			title="Payment received, thank you."
			subtitle="Secure payment"
			show={true}
			backFunction={5}
		>
			{/* //! The Back button dont work as intended  */}
			<form
				onSubmit={handleSubmit(submit)}
				className="invoice-form"
				style={{ paddingBottom: "50px" }}
			>
				<TextField
					autoComplete="given-name"
					{...register("firstName")}
					name="firstName"
					placeholder="First Name"
					variant="outlined"
					error={errors.firstName}
					helperText={errors.firstName ? errors.firstName?.message : " "}
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={person} />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					autoComplete="family-name"
					{...register("lastName")}
					name="lastName"
					placeholder="Last name"
					variant="outlined"
					fullWidth
					error={errors.lastName}
					helperText={errors.lastName ? errors.lastName?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={person} />
							</InputAdornment>
						),
					}}
				/>

				<TextField
					autoComplete="email"
					{...register("email")}
					placeholder="Email"
					variant="outlined"
					fullWidth
					error={errors.email}
					helperText={errors.email ? errors.email?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={Email} />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					name="company"
					placeholder="Company name (optional)"
					{...register("company")}
					variant="outlined"
					fullWidth
					error={errors.company}
					helperText={errors.company ? errors.company?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={Company} />
							</InputAdornment>
						),
					}}
				/>

				<PlacesAutocomplete
					value={address}
					onChange={setAddress}
					onSelect={handleSelect}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<>
							<TextField
								{...getInputProps({
									className: "location-search-input",
								})}
								name="Address"
								id="address"
								placeholder="Address"
								variant="outlined"
								fullWidth
								size="small"
								helperText=" "
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Avatar variant="inputAdorment" src={Addres} />
										</InputAdornment>
									),
								}}
							/>
							{/*
								//TODO[] Refactor this
							*/}
							<Stack
								sx={{
									position: "relative",
									width: "100%",
								}}
							></Stack>
							<div className="autocomplete-relative-container">
								<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion, index) => {
										const className = suggestion.active
											? "suggestion-item--active"
											: "suggestion-item";
										// inline style for demonstration purpose
										const style = suggestion.active
											? {
												backgroundColor: "#fafafacc",
												cursor: "pointer",
												width: "-webkit-fill-available",
												bacdropFilter: "blur(10px)",
											  }
											: {
												backgroundColor: "#ffffffcc",
												cursor: "pointer",
												width: "-webkit-fill-available",
												bacdropFilter: "blur(10px)",
											  };
										return (
											<div
												key={index}
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
											>
												<Typography
													variant="descriptiveText"
													sx={{
														color: "#112031",
													}}
												>
													{suggestion.description}
												</Typography>
											</div>
										);
									})}
								</div>
							</div>
						</>
					)}
				</PlacesAutocomplete>
				<TextField
					{...register("zip")}
					name="zip"
					placeholder="Zip code"
					variant="outlined"
					fullWidth
					error={errors.zip}
					helperText={errors.zip ? errors.zip?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={Addres} />
							</InputAdornment>
						),
					}}
				/>

				<TextField
					{...register("office")}
					name="office"
					placeholder="Office, Apt or Suite"
					variant="outlined"
					fullWidth
					error={errors.office}
					helperText={errors.office ? errors.office?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={City} />
							</InputAdornment>
						),
					}}
				/>

				<TextField
					{...register("city")}
					name="city"
					placeholder="City"
					variant="outlined"
					fullWidth
					error={errors.city}
					helperText={errors.city ? errors.city?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={City} />
							</InputAdornment>
						),
					}}
				/>

				<TextField
					{...register("state")}
					name="state"
					placeholder="State"
					variant="outlined"
					fullWidth
					error={errors.state}
					helperText={errors.state ? errors.state?.message : " "}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Avatar variant="inputAdorment" src={City} />
							</InputAdornment>
						),
					}}
				/>
				<Grid item xs={12} md={10}>
					<Stack>
						<Button
							variant="primaryButton"
							type="submit"
							size="large"
							sx={{
								width: "300px",
								marginBottom: "10px",
							}}
						>
							Start the work now
						</Button>
					</Stack>
				</Grid>
			</form>
		</MainPage>
	);
}

export { Invoice };
