import { Typography, useTheme } from "@mui/material";

export function BillingMobileText({ title, value }) {
	const theme = useTheme();
	return (
		<Typography
			sx={{
				display: "flex",
				color: theme.palette.lightBlue.secondary,
				fontFamily: "Ulagadi Sans, sans-serif",
				fontWeight: "500",
				fontSize: "13px",
			}}
		>
			{title}&nbsp;
			<Typography
				sx={{
					color: "#000",
					fontFamily: "inherit",
					fontWeight: "200",
					fontSize: "inherit",
				}}
			>
				{value}
			</Typography>
		</Typography>
	);
}
