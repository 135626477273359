import React from "react";

import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";

import { FileContext } from "../../../Context/FileContext";

export function previewComponent({ file }) {
	const { fileData, dispatchFile } = React.useContext(FileContext);
	function handleCancel(uuid) {
		const newFiles = fileData?.files?.filter((file) => file.id !== uuid);

		const newPrevFiles = fileData?.prevFiles?.filter((file) => {
			return file.id !== uuid;
		});

		dispatchFile({ type: "REMOVE_FILE", newFiles, newPrevFiles });

		if (newFiles.length === 0) {
			document.getElementById("file").value = "";
		}
	}

	return (
		<CardActionArea
			disabled={file.progress > 0 ? true : false}
			disableRipple
			sx={{
				maxHeight: "100px",
				minHeight: "100px",
				minWidth: "100px",
				maxWidth: "100px",
				padding: "5px",
				margin: "5px",
				alignSelf: "center",
			}}
			onClick={() => handleCancel(file.id)}
		>
			<CircularProgress
				variant="determinate"
				sx={{
					position: "absolute",
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					margin: "auto",
				}}
				value={file.progress}
			/>
			<CardMedia component={"img"} src={file.preview} />
		</CardActionArea>
	);
}
