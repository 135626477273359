import React from "react";
import { getMetadataByRef, getUrlByRef } from "../../hooks/utils";

export function PrintEvidence({ storageRef }) {
	const [media, setMedia] = React.useState({
		type: "",
		link: "",
		metadata: {},
	});

	async function getUrlAndMetadata() {
		const url = await getUrlByRef(storageRef);
		const metadata = await getMetadataByRef(storageRef);
		const contentType = metadata.contentType.split("/")[0];

		if (contentType === "video") {
			setMedia({ type: "video", link: url, metadata });
		} else if (contentType === "image") {
			setMedia({ type: "image", link: url, metadata });
		} else {
			setMedia({ type: "link", link: url, metadata });
		}
	}

	React.useEffect(() => {
		getUrlAndMetadata();
	}, []);
	
	switch (media.type) {
	case "video":
		return (
			<div className="col-50">
				<p style={{ marginBottom: "10px" }}>
						See an update of the progress in the following video:
				</p>
				<video className="image-video" controls>
					<source src={media.link} />
						Your browser does not support the video tag.
				</video>
			</div>
		);
	case "image":
		return (
			<div className="col-50">
				<p>See an update of the progress in the following image:</p>
				<a
					className="image-video"
					href={media.link}
					target="_blank"
					rel="noreferrer"
				>
					<img
						className="image-video"
						src={media.link}
						alt={media.metadata.name}
					/>
				</a>
			</div>
		);
	case "link":
		return (
			<div className="col-50">
				<p style={{ marginBottom: "10px" }}>
						See an update of the progress in the following link:
				</p>
				<a
					href={media.link}
					target="_blank"
					rel="noreferrer"
					style={{ margin: "10px 0px 50px 0px" }}
				>
						Visit link
				</a>
			</div>
		);
	default:
		return <a>{media.link}</a>;
	}
}
