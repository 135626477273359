import { CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { getById } from "../../../backend/services";
import { MyAccountTabs } from "../../templates/MyAcountTabs";

function PrivacyPolicy() {
	const [loading, setLoading] = useState(false);
	const [agreement, setAgreement] = useState({ content: "" });

	const getPrivacy = async () => {
		try {
			await getById({
				route: "agreement/getAgreementType",
				id: "PRIVACY",
			}).then((response) => {
				setAgreement({ content: response.document.content });
				setLoading(true);
			});
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getPrivacy();
	}, []);
	return (
		<>
			{loading ? (
				<MyAccountTabs className="privacy-policy-container">
					<Stack
						sx={{ paddingBottom: 2 }}
						dangerouslySetInnerHTML={{ __html: agreement.content }}
					/>
				</MyAccountTabs>
			) : (
				<MyAccountTabs>
					<Stack
						style={{ height: "50vh" }}
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						<CircularProgress />
					</Stack>
				</MyAccountTabs>
			)}
		</>
	);
}

export { PrivacyPolicy };
