// import { StepperContext } from "../../../Context/StepperContext";

// import { useNavigate } from "react-router-dom";
import React from "react";
import { Avatar, Link, Stack, Typography } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

//! MUI IMPORTS
import Paper from "@mui/material/Paper";
import { StepperComponent } from "./stepper";
// import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
import ProjectIcon from "../../Sources/Icons/ProjectIcon.svg";

//! MUI ICONS

//! ICONS IMPORTS
// import BannerIcon from "../../Sources/images/AdvanteBannerImg-2.png";
import { useDispatch, useSelector } from "react-redux";
import InactiveProject from "./inactiveProject";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../hooks/projects";
import { updateClientDb } from "../../hooks/auth";
import { setUser } from "../../features/auth/authSlice";
import {
	setActiveProjectId,
	setProject,
	setProjects,
} from "../../features/project/projectSlice";
import useTheme from "@mui/material/styles/useTheme";
import { BackdropContext } from "../../Context/BackdropContext";
export default function NewSteps() {
	// const { AuthData } = UserAuth();
	const { projects, project } = useSelector((state) => state.projectsRedux);

	const navigate = useNavigate();
	const someProjectHasPayed = projects?.some(
		(project) => project.completedSteps[5] === true
	);

	const dispatch = useDispatch();

	const { user, token } = useSelector((state) => state.auth);
	const { changeLoadingBackdrop } = React.useContext(BackdropContext);

	// const theme = useTheme();

	async function CreateNewProject() {
		const newProject = {
			clientUid: user.uid,
			clientName: user.displayName,
			activeStep: 0,
			projectName: "My new project",
			email: user.email,
			requireNda: false,
			completedSteps: [
				false, // <- this step is exclusive to google clients, so we set it to true
				true,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			],
			created: true,
			lastCompletedStep: 0,
		};
		const createdProject = await createProject(newProject, token);
		const updatedUser = {
			...user,
			activeProject: createdProject.uid,
		};
		await updateClientDb(updatedUser, token);
		dispatch(setUser(updatedUser));
		// then set project in redux store
		dispatch(setProject(createdProject));
		dispatch(setActiveProjectId(createdProject.uid));
		dispatch(setProjects([...projects, createdProject]));

		changeLoadingBackdrop(false);
		navigate("/newProject");
	}

	return (
		<Stack
			sx={{
				background: "transparent",
				position: "sticky",
				top: 0,
				left: 0,
				minHeight: "100vh",
				maxHeight: "100vh",
				zIndex: 3,
				transition: "width 0.5s ease-in-out",
				maxWidth: "203px",
				minWidth: "203px",
				paddingTop: "5%",
				overflow: "visible",
				overflowY: "clip",
				// width: "18vw",
			}}
		>
			<Paper
				sx={{
					width: "100%",
					minHeight: "100%",
					maxHeight: "100%",
					display: "flex",
					flexDirection: "column",
					// backgroundColor: theme.palette.electricBlue.secondary,
					background: "transparent",
					overflow: "visible",
					// paddingTop: "11%",
				}}
				square
				elevation={0}
			>
				{Object.keys(user).length === 0 && (
					<>
						<Stack
							alignItems="center"
							direction="row"
							sx={{
								minHeight: "1.2rem",
								backgroundColor: useTheme().palette.electricBlue.secondary,
								boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.10)",
								borderRadius: "0px 50px 50px 0px",
								width: "100%",
								paddingLeft: "2.9rem",
								paddingY: "1.5rem",
								overflow: "visible",
							}}
						>
							<Avatar
								sx={{ "&.MuiAvatar-root": { marginRight: 1 } }}
								variant="sidebarProjectIcon"
								src={ProjectIcon}
							/>
							<Typography variant="sideBarTextActive">
								My First Tune Up
							</Typography>
						</Stack>
					</>
				)}

				{Object.keys(projects).length > 0 && (
					<>
						<StepperComponent
							key={project?.uid}
							name={project?.projectName}
							project={project}
						/>
						<Stack
							sx={{
								overflowX: "clip",
								overflowY: "auto",
								"::-webkit-scrollbar": {
									width: "5px",
								},
							}}
						>
							{projects?.map((project) => (
								<InactiveProject
									key={project?.uid}
									project={project}
									color="initial"
								/>
							))}
						</Stack>
					</>
				)}

				<Stack
					sx={{
						// change this to a boolean in context, instead a comprobation
						marginTop: "auto",
						marginBottom: "30px",
						marginLeft: 2,
					}}
					spacing={1}
				>
					<Link
						variant="newProjectLink"
						sx={{
							marginBottom: "1rem",
							visibility: someProjectHasPayed ? "visible" : "hidden",
							cursor: !project?.exclusiveChat ? "not-allowed" : "pointer",
						}}
						onClick={() => CreateNewProject()}
					>
						Create a new project
					</Link>
					<Stack
						direction="row"
						sx={{
							width: "204px",
							// change this to a boolean in context, instead a comprobation
						}}
						spacing={1}
					>
						<Link
							variant="newProjectLink"
							href="https://digital-tuneup.com/terms.html"
							target="_blank"
							sx={{
								fontSize: "1rem",
							}}
						>
							Terms of service
						</Link>
						<Link
							variant="newProjectLink"
							href="https://digital-tuneup.com/privacy.html"
							target="_blank"
							sx={{
								fontSize: "1rem",
							}}
						>
							Privacy Policy
						</Link>
					</Stack>
				</Stack>
			</Paper>
		</Stack>
	);
}
