import { useSelector } from "react-redux";
import { WorkInProgress } from "../../components/workInProgressRefactor";
import LoadingGeneric from "../../components/BackdropLoading";

export default function WorkInProgressPage() {
	const projects = useSelector((state) => state.projectsRedux);
	if (projects?.project?.uid === undefined) {
		return <LoadingGeneric />;
	} else {
		return (
			<>{Object.keys(projects?.project).length > 0 && <WorkInProgress />}</>
		);
	}
}
