export function DesktopTable({ surveyQuestions }) {
	return (
		<table className="evaluation-pc">
			{surveyQuestions.length > 0 && (
				<>
					<tr>
						<th className="table-col-question"></th>
						<th style={{ width: "15%" }}>
							Extremely
							<br /> disagree
						</th>
						<th style={{ width: "15%" }}>Disagree</th>
						<th style={{ width: "15%" }}>Neutral</th>
						<th style={{ width: "15%" }}>Agree</th>
						<th style={{ width: "15%" }}>
							Strongly
							<br /> Agree
						</th>
					</tr>
					<>
						{surveyQuestions.map((question, index) => (
							<tr
								key={question}
								className={index !== surveyQuestions.length - 1 ? "border" : ""}
							>
								<td className="question">{question}</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Strongly disagree"
										id={`que1${index}`}
										type="radio"
									/>
									<label htmlFor={`que1${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Disagree"
										id={`que2${index}`}
										type="radio"
									/>
									<label htmlFor={`que2${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Neutral"
										id={`que3${index}`}
										type="radio"
									/>
									<label htmlFor={`que3${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Agree"
										id={`que4${index}`}
										type="radio"
									/>
									<label htmlFor={`que4${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Strongly agree"
										id={`que5${index}`}
										type="radio"
										defaultChecked={true}
									/>
									<label htmlFor={`que5${index}`} className="questionLabel" />
								</td>
							</tr>
						))}
					</>
				</>
			)}
		</table>
	);
}

export function MobileTable({ surveyQuestions }) {
	return (
		<div className="evaluation-movil">
			{surveyQuestions.map((question, index) => (
				<div key={index} className="que-box">
					<p>{question}</p>
					<div className="col-100">
						<table>
							<tr>
								<th>
									Strongly
									<br /> disagree
								</th>
								<th>Disagree</th>
								<th>Neutral</th>
								<th>Agree</th>
								<th>
									Strongly
									<br /> Agree
								</th>
							</tr>
							<tr>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Strongly disagree"
										id={`que1${index}`}
										type="radio"
									/>
									<label htmlFor={`que1${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Disagree"
										id={`que2${index}`}
										type="radio"
									/>
									<label htmlFor={`que2${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Neutral"
										id={`que3${index}`}
										type="radio"
									/>
									<label htmlFor={`que3${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Agree"
										id={`que4${index}`}
										type="radio"
									/>
									<label htmlFor={`que4${index}`} className="questionLabel" />
								</td>
								<td className="radio">
									<input
										name={`que${index}`}
										value="Strongly agree"
										id={`que5${index}`}
										type="radio"
										defaultChecked={true}
									/>
									<label htmlFor={`que5${index}`} className="questionLabel" />
								</td>
							</tr>
						</table>
					</div>
				</div>
			))}
		</div>
	);
}
