import { useSelector } from "react-redux";
import { Quote } from "../../components/QuoteRefactorized";
import LoadingGeneric from "../../components/BackdropLoading";

export default function QuotePage() {
	const projects = useSelector((state) => state.projectsRedux);

	if (projects?.project?.uid === undefined) {
		return <LoadingGeneric />;
	} else {
		return <>{Object.keys(projects?.project).length > 0 && <Quote />}</>;
	}
}
