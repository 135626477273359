import { AccordionDetails } from "../../templates/chatComponents";

import ChatInput from "./chatInput";
import DisplayMessages from "./messageDisplay";

export default function ChatBody({ messages, questionnarie }) {
	return (
		<AccordionDetails
			//TODO[] Change the height of the chat body here, to make it a constant height (px) instead of a percentage of viewport height (vh)
			sx={{
				minHeight: "40vh",
				maxHeight: "40vh",
				display: "flex",
				flexDirection: "column",
				height: "inherit",
				width: "100%",
				padding: "0px 10px 10px 10px",
				borderRadius: "0 0 30px 30px",
				overflowY: "clip",
			}}
		>
			<DisplayMessages messages={messages} questionnarie={questionnarie} />
			<ChatInput />
		</AccordionDetails>
	);
}
