import React from "react";

// import { StepperContext } from "../../Context/StepperContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";

import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
	addPlatform,
	delPlatform,
} from "../../../features/project/projectSlice";
import { isMobile } from "../../../hooks/utils";
function PlatformCard({ platform }) {
	const theme = useTheme();

	const [isSelected, setIsSelected] = React.useState(false);

	const check = isMobile();

	const projects = useSelector((state) => state.projectsRedux);

	React.useEffect(() => {
		checkSelected();
	}, []);

	function checkSelected() {
		if (projects.project?.uid) {
			projects.project.platforms.forEach((projectPlatforms) => {
				if (projectPlatforms.id === platform.id) {
					setIsSelected(true);
				}
			});
		} else if (projects.platforms.length > 0) {
			projects.platforms.forEach((projectPlatforms) => {
				if (projectPlatforms.id === platform.id) {
					setIsSelected(true);
				}
			});
		} else {
			setIsSelected(false);
		}
	}

	const dispatch = useDispatch();
	function Checked() {
		return (
			<>
				<Box
					sx={{
						position: "absolute",
						zIndex: 100,
						top: 0,
						right: 0,
						marginTop: 1,
						marginRight: 1,
					}}
				>
					<Paper variant="platformsUncheckedButton">
						<Paper variant="platformsCheckedButton" />
					</Paper>
				</Box>
				<Box
					sx={{
						position: "absolute",
						zIndex: 100,
						bottom: 0,
						right: 0,
						marginBottom: 1,
						marginRight: 1,
					}}
				>
					<Avatar variant="iconPlatforms" src={platform.icons.active} />
				</Box>
			</>
		);
	}
	function NoChecked() {
		return (
			<>
				<Box
					sx={{
						position: "absolute",
						zIndex: 100,
						top: 0,
						right: 0,
						marginTop: 1,
						marginRight: 1,
					}}
				>
					<Paper variant="platformsUncheckedButton" />
				</Box>
				<Box
					sx={{
						position: "absolute",
						zIndex: 100,
						bottom: 0,
						right: 0,
						marginBottom: 1,
						marginRight: 1,
					}}
				>
					<Avatar variant="iconPlatforms" src={platform.icons.inactive} />
				</Box>
			</>
		);
	}

	const toggleSelected = () => {
		if (!isSelected) {
			dispatch(addPlatform(platform));
		} else {
			dispatch(delPlatform(platform));
		}
		setIsSelected(!isSelected);
	};

	return (
		<Grid
			item
			xs={6}
			sm={3}
			md={3}
			lg={3}
			sx={{
				aspectRatio: "1 / 1",
				"& 	.MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
					backgroundColor: "transparent",
				},
			}}
		>
			<ToggleButton
				value="check"
				selected={isSelected}
				onChange={toggleSelected}
				sx={{
					color: "#000",
					width: "100%",
					height: "100%",
					padding: 0,
					border: "none",
					borderRadius: "9px",
				}}
			>
				{isSelected ? <Checked /> : <NoChecked />}

				<Card
					variant={"platforms"}
					sx={{
						display: "grid",
						placeItems: "center",
						width: "100%",
						height: "100%",
					}}
				>
					<CardContent
						sx={{
							backgroundColor: "transparent",
						}}
					>
						<Typography
							style={{ textTransform: "capitalize" }}
							variant={check ? "subtitle2" : "caption"}
							component="div"
							sx={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								height: "100%",
								wordBreak: "break-word",
								[theme.breakpoints.down("md")]: {
									fontSize: "10px",
								},
							}}
						>
							{platform.name}
						</Typography>
					</CardContent>
				</Card>
			</ToggleButton>
		</Grid>
	);
}

export { PlatformCard };
